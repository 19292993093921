import { useEffect, useState } from "react";
import { InventoryItemIdentity } from "@labarchives/inventory-shared/build/inventory";
import { RelationshipItemIds } from "@labarchives/inventory-shared/build/inventory";
import { RelationshipType } from "../types/RelationshipType";
import { InventoryItemView } from "../types/views";
import { InventoryApi } from "../../api/InventoryApi";

export interface InventoryItemRelationshipEditDisplayHooks {
  childItems: InventoryItemIdentity[];
  parentItems: InventoryItemIdentity[];
  isChildModalOpen: boolean;
  isParentModalOpen: boolean;
  isRemovalWarningModalOpen: boolean;
  handleChildrenSubmit: (items: InventoryItemIdentity[]) => void;
  handleParentsSubmit: (items: InventoryItemIdentity[]) => void;
  onChildModalToggled: () => void;
  onParentModalToggled: () => void;
  onRemovalWarningModalToggled: () => void;
  handleRemoveChild: (childItem: InventoryItemIdentity) => void;
  handleRemoveParent: (parentItem: InventoryItemIdentity) => void;
  onConfirmRemove: () => void;
}

export function useInventoryItemRelationshipEditDisplay(
  api: InventoryApi,
  currentItemView: InventoryItemView,
  handleRelationshipsAdded: (id: number | string, { childIds, parentIds }: RelationshipItemIds) => void,
  handleChildRelationshipDeleted: (id: number, name: string, childItem: InventoryItemIdentity) => void,
  handleParentRelationshipDeleted: (id: number, name: string, parentItem: InventoryItemIdentity) => void,
): InventoryItemRelationshipEditDisplayHooks {
  const { childIds, parentIds, id } = currentItemView;
  const inventoryId = id;

  const [childItems, setChildItems] = useState<InventoryItemIdentity[]>([]);
  const [parentItems, setParentItems] = useState<InventoryItemIdentity[]>([]);

  const [isParentModalOpen, setIsParentModalOpen] = useState(false);
  const [isChildModalOpen, setIsChildModalOpen] = useState(false);

  const [isRemovalWarningModalOpen, setIsRemovalWarningModalOpen] = useState(false);

  const [itemToRemove, setItemToRemove] = useState<{
    item: InventoryItemIdentity;
    relationshipType: RelationshipType;
  }>();

  const getRelationships = async (): Promise<void> => {
    const result = await api.getInventoryRelationshipItems(inventoryId, {
      childIds,
      parentIds,
    });

    setParentItems(result.parentItems);
    setChildItems(result.childItems);
  };

  const onParentModalToggled = (): void => {
    setIsParentModalOpen(!isParentModalOpen);
  };

  const onChildModalToggled = (): void => {
    setIsChildModalOpen(!isChildModalOpen);
  };

  const onRemovalWarningModalToggled = (): void => {
    setIsRemovalWarningModalOpen(!isRemovalWarningModalOpen);
  };

  const handleRelationshipSubmit = (items: InventoryItemIdentity[], relationshipType: RelationshipType): void => {
    const itemIds: number[] = items.map((item) => item.itemId);
    const body: RelationshipItemIds = { childIds: [], parentIds: [] };
    if (relationshipType === RelationshipType.parent) {
      body.parentIds = itemIds;
    } else if (relationshipType === RelationshipType.child) {
      body.childIds = itemIds;
    }
    handleRelationshipsAdded(inventoryId, body);
  };

  const handleParentsSubmit = (items: InventoryItemIdentity[]): void => {
    void handleRelationshipSubmit(items, RelationshipType.parent);
  };

  const handleChildrenSubmit = (items: InventoryItemIdentity[]): void => {
    void handleRelationshipSubmit(items, RelationshipType.child);
  };

  const handleRemoveChild = (childItem: InventoryItemIdentity): void => {
    onRemovalWarningModalToggled();
    setItemToRemove({ item: childItem, relationshipType: RelationshipType.child });
  };

  const handleRemoveParent = (parentItem: InventoryItemIdentity): void => {
    onRemovalWarningModalToggled();
    setItemToRemove({ item: parentItem, relationshipType: RelationshipType.parent });
  };

  const removeItem = (removedItem: InventoryItemIdentity, relationshipType: RelationshipType): void => {
    if (relationshipType === RelationshipType.child) {
      handleChildRelationshipDeleted(inventoryId, currentItemView.name, removedItem);
    } else {
      handleParentRelationshipDeleted(inventoryId, currentItemView.name, removedItem);
    }
  };

  const onConfirmRemove = (): void => {
    if (itemToRemove) {
      const { item, relationshipType } = itemToRemove;
      void removeItem(item, relationshipType);
    }

    setItemToRemove(undefined);
    onRemovalWarningModalToggled();
  };

  useEffect(() => {
    void getRelationships();
  }, [currentItemView]);

  return {
    childItems: [...childItems],
    parentItems: [...parentItems],
    isParentModalOpen,
    isChildModalOpen,
    isRemovalWarningModalOpen,
    handleChildrenSubmit,
    handleParentsSubmit,
    onChildModalToggled,
    onParentModalToggled,
    onRemovalWarningModalToggled,
    handleRemoveChild,
    handleRemoveParent,
    onConfirmRemove,
  };
}

export enum CustomAttributeType {
  Textbox = 1,
  TextArea = 2,
  Dropdown = 3,
  Checkbox = 4,
  Number = 5,
  Date = 6,
  Radio = 7,
  CASNumber = 8,
  ChemicalSafety = 9,
  ItemAutoSuggest = 10,
}

import { useEffect } from "react";
import { Col, FormGroup, Input, Label, Row, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink, faPrint } from "@fortawesome/pro-light-svg-icons";
import moment from "moment";
import * as clock from "@labarchives/inventory-shared/build/util/clock";
import { AlertWithIcon, Button } from "@labarchives/ui-design";
import React, { Fragment, ReactElement, useState } from "react";
import { CustomAttributeType, InventoryStandardField } from "@labarchives/inventory-shared/build/inventory";
import { InventoryItemIdentity } from "@labarchives/inventory-shared/build/inventory";
import { RelationshipItemIds } from "@labarchives/inventory-shared/build/inventory";
import { useSearchParams } from "react-router";
import { Loading } from "../components";
import { EditableStorage } from "../storage/EditableStorage";
import { StorageLocationView } from "../storage/types/views";
import { ReadOnlyStorage } from "../storage/ReadOnlyStorage";
import { InventoryStorageChangedFunction } from "../storage/EditableStorageHooks";
import { InventoryApi } from "../api/InventoryApi";
import { EditableQuantity } from "./editable-quantity/EditableQuantity";
import { useLabelPrintingHooks } from "./InventoryLabelPrintingHooks";
import { InventoryAttachmentView, InventoryItemLinkView, InventoryItemView } from "./types/views";
import { isStandardFieldHidden } from "./selectors";
import { InventoryItemRelationshipEditDisplay } from "./relationships/InventoryItemRelationshipEditDisplay";

export interface DisplayItemDetailsProps {
  currentItemView: InventoryItemView;
  units: string[];
  storageLocations: StorageLocationView;
  currency: string;
  api: InventoryApi;
  isStorageLocationRequired: boolean;
  onInventoryStorageChanged?: InventoryStorageChangedFunction;

  onDownloadAttachment(attachmentId: number): void;
  onQuantityChanged?(id: number, amount: number, unitOfMeasure: string): void;
  onRelationshipsAdded?(id: number | string, { parentIds, childIds }: RelationshipItemIds): void;
  onChildRelationshipDeleted?(id: number, name: string, childItem: InventoryItemIdentity): void;
  onParentRelationshipDeleted?(id: number, name: string, parentItem: InventoryItemIdentity): void;
}

export enum InventoryDisplayTabs {
  About = "1",
  Details = "2",
  Relationships = "3",
  History = "4",
  Labels = "5",
  Attachments = "6",
}

export function DisplayItemDetails(props: DisplayItemDetailsProps): ReactElement {
  const intl = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();

  const [activeTab, setActiveTab] = useState(getDefaultTab());

  const {
    currency,
    currentItemView,
    storageLocations,
    units,
    isStorageLocationRequired,
    onDownloadAttachment,
    onInventoryStorageChanged,
    onQuantityChanged,
    onRelationshipsAdded,
    onChildRelationshipDeleted,
    onParentRelationshipDeleted,
    api,
  } = props;

  function getDefaultTab(): InventoryDisplayTabs {
    const defaultTab = searchParams.get("defaultTab") || InventoryDisplayTabs.About;

    if (
      [
        InventoryDisplayTabs.About,
        InventoryDisplayTabs.Details,
        InventoryDisplayTabs.Relationships,
        InventoryDisplayTabs.History,
        InventoryDisplayTabs.Labels,
        InventoryDisplayTabs.Attachments,
      ].includes(defaultTab as InventoryDisplayTabs)
    ) {
      return defaultTab as InventoryDisplayTabs;
    }

    return InventoryDisplayTabs.About;
  }

  const onToggle = (tab: InventoryDisplayTabs): void => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const { generateLabels, printLabel, loadPrinters, onQRDownloaded, dymoError, showDymoDirections, showDymoLabels, arePrintersLoading } =
    useLabelPrintingHooks(currentItemView, api.logError);

  function loadLabelTypes(): ReactElement {
    // @ts-ignore
    const labels = window.getLabels();
    const keys = Object.keys(labels.data);

    return (
      <FormGroup>
        <Label for="labelsSelect">
          <FormattedMessage id="label.type" />
        </Label>
        <Input id="labelsSelect" type="select" defaultValue={labels.defaultValue}>
          {keys.map((key) => {
            return (
              <option key={key} value={key}>
                {labels.data[key].desciption}
              </option>
            );
          })}
        </Input>
      </FormGroup>
    );
  }

  function getAttachments(attachments: InventoryAttachmentView[]): ReactElement {
    if (attachments.length === 0) {
      return (
        <div>
          <FormattedMessage id="no.attachments" />
        </div>
      );
    }

    return (
      <div>
        {attachments.map((a) => {
          return (
            <div key={a.id}>
              <Button color="link" className="inventory-attachment" onClick={() => onDownloadAttachment(a.id)}>
                {a.name}
              </Button>{" "}
              ({a.size})
            </div>
          );
        })}
      </div>
    );
  }

  function getLinks(links: InventoryItemLinkView[]): ReactElement {
    if (links.length === 0) {
      return (
        <div>
          <FormattedMessage id="no.links" />
        </div>
      );
    }

    return (
      <div>
        {links.map((a) => {
          return (
            <div className={"inventory-link-item"} key={`${a.href}${a.dateAdded.getTime()}`}>
              <span className="inventory-link-date">
                <FormattedDate
                  value={a.dateAdded}
                  year="numeric"
                  month="numeric"
                  day="numeric"
                  hour="numeric"
                  minute="numeric"
                  timeZoneName="short"
                />
              </span>

              <span className="inventory-link-details">{a.userName}</span>

              <span className="inventory-link">
                <a href={a.href} target={"_blank"} rel={"noreferrer"}>
                  {a.title} <FontAwesomeIcon icon={faExternalLink} />
                </a>
              </span>
            </div>
          );
        })}
      </div>
    );
  }

  function getHiddenClass(field: InventoryStandardField): string {
    return isStandardFieldHidden(field, currentItemView, true) ? "d-none" : "";
  }

  const xsSize = 6;
  const mdSize = 2;
  const xlSize = 2;

  return (
    <Col lg={12} xl={{ size: 10, offset: 1 }}>
      <Row>
        <Col>
          <nav role={"navigation"}>
            <ul className={"nav nav-tabs"} role={"tablist"}>
              <li role={"presentation"}>
                <a
                  id="inventory-item-display-tab-about"
                  className={classnames("nav-link", {
                    active: activeTab === InventoryDisplayTabs.About,
                  })}
                  onClick={(e) => {
                    e.preventDefault();
                    onToggle(InventoryDisplayTabs.About);
                  }}
                  role={"tab"}
                  aria-controls={"inventory-item-display-tab-pane-about"}
                  aria-selected={activeTab === InventoryDisplayTabs.About}
                  href={"#inventory-item-display-tab-pane-about"}
                >
                  <FormattedMessage id="about" />
                </a>
              </li>

              <li role={"presentation"}>
                <a
                  id="inventory-item-display-tab-details"
                  className={classnames("nav-link", {
                    active: activeTab === InventoryDisplayTabs.Details,
                  })}
                  onClick={(e) => {
                    e.preventDefault();
                    onToggle(InventoryDisplayTabs.Details);
                  }}
                  role={"tab"}
                  aria-controls={"inventory-item-display-tab-pane-details"}
                  aria-selected={activeTab === InventoryDisplayTabs.Details}
                  href={"#inventory-item-display-tab-pane-details"}
                >
                  <FormattedMessage id="details" />
                </a>
              </li>

              <li role={"presentation"}>
                <a
                  id="inventory-item-display-tab-relationships"
                  className={classnames("nav-link", {
                    active: activeTab === InventoryDisplayTabs.Relationships,
                  })}
                  onClick={(e) => {
                    e.preventDefault();
                    onToggle(InventoryDisplayTabs.Relationships);
                  }}
                  role={"tab"}
                  aria-controls={"inventory-item-display-tab-pane-relationships"}
                  aria-selected={activeTab === InventoryDisplayTabs.Relationships}
                  href={"#inventory-item-display-tab-pane-relationships"}
                >
                  <FormattedMessage id="relationships" />{" "}
                  <span
                    className="badge bg-secondary"
                    role="status"
                    aria-label={`${currentItemView.childIds.length + currentItemView.parentIds.length} relationships`}
                  >
                    {currentItemView.childIds.length + currentItemView.parentIds.length}
                  </span>
                </a>
              </li>

              <li role={"presentation"}>
                <a
                  id="inventory-item-display-tab-labels"
                  className={classnames("nav-link", {
                    active: activeTab === InventoryDisplayTabs.Labels,
                  })}
                  onClick={(e) => {
                    e.preventDefault();
                    onToggle(InventoryDisplayTabs.Labels);
                    generateLabels(intl.formatDate);
                  }}
                  role={"tab"}
                  aria-controls={"inventory-item-display-tab-pane-labels"}
                  aria-selected={activeTab === InventoryDisplayTabs.Labels}
                  href={"#inventory-item-display-tab-pane-labels"}
                >
                  <FormattedMessage id="labels" />
                </a>
              </li>

              <li role={"presentation"}>
                <a
                  id="inventory-item-display-tab-attachments"
                  className={classnames("nav-link", {
                    active: activeTab === InventoryDisplayTabs.Attachments,
                  })}
                  onClick={(e) => {
                    e.preventDefault();
                    onToggle(InventoryDisplayTabs.Attachments);
                  }}
                  role={"tab"}
                  aria-controls={"inventory-item-display-tab-pane-attachments"}
                  aria-selected={activeTab === InventoryDisplayTabs.Attachments}
                  href={"#inventory-item-display-tab-pane-attachments"}
                >
                  <FormattedMessage id="attachments" />{" "}
                  <span className="badge bg-secondary" role="status" aria-label={`${currentItemView.attachments.length} attachments`}>
                    {currentItemView.attachments.length}
                  </span>
                </a>
              </li>

              <li role={"presentation"}>
                <a
                  id="inventory-item-display-tab-history"
                  className={classnames("nav-link", {
                    active: activeTab === InventoryDisplayTabs.History,
                  })}
                  onClick={() => {
                    onToggle(InventoryDisplayTabs.History);
                  }}
                  role={"tab"}
                  aria-controls={"inventory-item-display-tab-pane-history"}
                  aria-selected={activeTab === InventoryDisplayTabs.History}
                  href={"#inventory-item-display-tab-pane-history"}
                >
                  <FormattedMessage id="history" />{" "}
                  <span className="badge bg-secondary" role="status" aria-label={`${currentItemView.history.length} history entries`}>
                    {currentItemView.history.length}
                  </span>
                </a>
              </li>
            </ul>
          </nav>

          <TabContent activeTab={activeTab}>
            <TabPane
              tabId={InventoryDisplayTabs.About}
              id="inventory-item-display-tab-pane-about"
              aria-labelledby={"inventory-item-display-tab-about"}
            >
              <Row id="inventory-item-display-type">
                <Col xs={xsSize} md={mdSize} xl={xlSize} className="label">
                  <FormattedMessage id="type" />
                </Col>
                <Col className="value">
                  {currentItemView.typeColor && (
                    <div className={"inventory-type-with-color"}>
                      <div
                        className={"inventory-type-color"}
                        data-color={currentItemView.typeColor}
                        style={{ backgroundColor: currentItemView.typeColor }}
                      >
                        &nbsp;
                      </div>
                      <span>{currentItemView.typeName}</span>
                    </div>
                  )}
                  {!currentItemView.typeColor && <span>{currentItemView.typeName}</span>}
                </Col>
              </Row>

              <Row id="inventory-item-id">
                <Col xs={xsSize} md={mdSize} xl={xlSize} className="label">
                  <FormattedMessage id="id" />
                </Col>
                <Col className="value">{currentItemView.itemId}</Col>
              </Row>

              <Row id="inventory-item-display-quantity">
                <Col xs={xsSize} md={mdSize} xl={xlSize} className="label">
                  <FormattedMessage id="amount" />
                </Col>
                <Col className="value">
                  {onQuantityChanged && <EditableQuantity current={currentItemView} onQuantityChanged={onQuantityChanged} units={units} />}
                  {!onQuantityChanged && (
                    <span>
                      {currentItemView.quantityAvailable} {currentItemView.unitOfMeasure}
                    </span>
                  )}
                </Col>
              </Row>

              <Row id="inventory-item-display-storage" className={getHiddenClass(InventoryStandardField.Location)}>
                <Col xs={xsSize} md={mdSize} xl={xlSize} className="label">
                  <FormattedMessage id="location" />
                </Col>
                <Col className="value">
                  {onInventoryStorageChanged && (
                    <EditableStorage
                      storageLocations={storageLocations}
                      onInventoryStorageChanged={onInventoryStorageChanged}
                      selectedStorageCells={currentItemView.storageCells}
                      selectedStorageLocationId={currentItemView.storageLocationId}
                      storageNotes={currentItemView.storageLocationNotes}
                      api={api}
                      isRequired={isStorageLocationRequired}
                    />
                  )}
                  {!onInventoryStorageChanged && (
                    <ReadOnlyStorage
                      storageLocations={storageLocations}
                      selectedStorageCells={currentItemView.storageCells}
                      selectedStorageLocationId={currentItemView.storageLocationId}
                      storageNotes={currentItemView.storageLocationNotes}
                    />
                  )}
                </Col>
              </Row>

              <Row id="inventory-item-display-description" className={getHiddenClass(InventoryStandardField.Description)}>
                <Col xs={xsSize} md={mdSize} xl={xlSize} className="label">
                  <FormattedMessage id="description" />
                </Col>
                <Col className="value">
                  {currentItemView.description.split("\n").map((i, k) => (
                    <Fragment key={k}>
                      {i}
                      <br />
                    </Fragment>
                  ))}
                </Col>
              </Row>

              <Row id="inventory-item-display-vendor" className={getHiddenClass(InventoryStandardField.Vendor)}>
                <Col xs={xsSize} md={mdSize} xl={xlSize} className="label">
                  <FormattedMessage id="vendor" />
                </Col>
                <Col className="value">{currentItemView.vendorName}</Col>
              </Row>

              <Row id="inventory-item-display-catalog-number" className={getHiddenClass(InventoryStandardField.CatalogNumber)}>
                <Col xs={xsSize} md={mdSize} xl={xlSize} className="label">
                  <FormattedMessage id="catalog.number" />
                </Col>
                <Col className="value">{currentItemView.catalogNumber}</Col>
              </Row>

              <Row id="inventory-item-display-lot-number" className={getHiddenClass(InventoryStandardField.LotNumber)}>
                <Col xs={xsSize} md={mdSize} xl={xlSize} className="label">
                  <FormattedMessage id="lot.number" />
                </Col>
                <Col className="value">{currentItemView.lotNumber}</Col>
              </Row>

              <Row id="inventory-item-display-price" className={getHiddenClass(InventoryStandardField.Price)}>
                <Col xs={xsSize} md={mdSize} xl={xlSize} className="label">
                  <FormattedMessage id="price" />
                </Col>
                <Col className="value">
                  {currentItemView.price && <FormattedNumber value={currentItemView.price} style="currency" currency={currency} />}
                </Col>
              </Row>

              <Row id="inventory-item-display-date-received" className={getHiddenClass(InventoryStandardField.DateReceived)}>
                <Col xs={xsSize} md={mdSize} xl={xlSize} className="label">
                  <FormattedMessage id="date.received" />
                </Col>
                <Col className="value">
                  {currentItemView.dateReceived && <FormattedDate value={currentItemView.dateReceived} localeMatcher={"lookup"} />}
                </Col>
              </Row>

              <Row id="inventory-item-display-grant-number" className={getHiddenClass(InventoryStandardField.GrantNumber)}>
                <Col xs={xsSize} md={mdSize} xl={xlSize} className="label">
                  <FormattedMessage id="grant.number" />
                </Col>
                <Col className="value">{currentItemView.grantNumber}</Col>
              </Row>

              <Row id="inventory-item-display-po-number" className={getHiddenClass(InventoryStandardField.PONumber)}>
                <Col xs={xsSize} md={mdSize} xl={xlSize} className="label">
                  <FormattedMessage id="po.number" />
                </Col>
                <Col className="value">{currentItemView.poNumber}</Col>
              </Row>

              <Row id="inventory-item-display-expiration-date" className={getHiddenClass(InventoryStandardField.Expiration)}>
                <Col xs={xsSize} md={mdSize} xl={xlSize} className="label">
                  <FormattedMessage id="expiration" />
                </Col>
                <Col className="value">{currentItemView.expirationDate && <FormattedDate value={currentItemView.expirationDate} />}</Col>
              </Row>

              <Row id="inventory-item-display-safety-sheet" className={getHiddenClass(InventoryStandardField.SafetySheet)}>
                <Col xs={xsSize} md={mdSize} xl={xlSize} className="label">
                  <FormattedMessage id="safety.sheet" />
                </Col>
                <Col className="value">
                  {currentItemView.safetySheetUrl !== "" && (
                    <a href={currentItemView.safetySheetUrl} target="_new">
                      {currentItemView.safetySheetUrl} <FontAwesomeIcon icon={faExternalLink} />
                    </a>
                  )}
                </Col>
              </Row>

              <Row id="inventory-item-display-notes" className={getHiddenClass(InventoryStandardField.Notes)}>
                <Col xs={xsSize} md={mdSize} xl={xlSize} className="label">
                  <FormattedMessage id="notes" />
                </Col>
                <Col className="value">
                  {currentItemView.notes.split("\n").map((i, k) => (
                    <Fragment key={k}>
                      {i}
                      <br />
                    </Fragment>
                  ))}
                </Col>
              </Row>

              {currentItemView.attributes
                .filter((i) => i.type === CustomAttributeType.ChemicalSafety)
                .map((i) => (
                  <Row key={`row${i.id}`} id={`inventory-item-display-custom${i.id}`}>
                    <Col key={`col-label${i.id}`} className="label" data-attribute-id={i.id} xs={xsSize} md={mdSize} xl={xlSize}>
                      {i.label}
                    </Col>
                    <Col key={`col-value${i.id}`} className="value">
                      {i.values.map((safetyClass) => (
                        <div key={safetyClass} className={"chemical-safety-image"}>
                          <img alt={safetyClass} src={`/images/safety/${safetyClass.toLowerCase().replace(" ", "-")}.svg`} key={safetyClass} />
                          <span>{safetyClass}</span>
                        </div>
                      ))}
                    </Col>
                  </Row>
                ))}

              {currentItemView.links.length > 0 && (
                <Row id="inventory-item-display-eln-links">
                  <Col xs={xsSize} md={mdSize} xl={xlSize} className="label">
                    <FormattedMessage id="links" />
                  </Col>
                  <Col className="value">{getLinks(currentItemView.links)}</Col>
                </Row>
              )}
            </TabPane>

            <TabPane
              tabId={InventoryDisplayTabs.Details}
              id="inventory-item-display-tab-pane-details"
              aria-labelledby={"inventory-item-display-tab-details"}
            >
              {currentItemView.attributes.length === 0 && (
                <div>
                  <em>
                    <FormattedMessage id="no.custom.fields.note" />
                  </em>
                </div>
              )}
              {currentItemView.attributes
                .filter((i) => i.type !== CustomAttributeType.ChemicalSafety)
                .map((i) => (
                  <Row key={`row${i.id}`} id={`inventory-item-display-custom${i.id}`}>
                    <Col key={`col-label${i.id}`} className="label" data-attribute-id={i.id} xs={xsSize} md={mdSize} xl={xlSize}>
                      {i.label}
                    </Col>
                    <Col key={`col-value${i.id}`} className="value" data-attribute-id={i.id}>
                      {i.type === CustomAttributeType.Date && i.values.length === 1 && (
                        <FormattedDate value={moment(i.values[0], clock.STANDARD_MOMENT_DATE_FORMAT).toDate()} />
                      )}
                      {i.type !== CustomAttributeType.Date && (
                        <>
                          {i.values
                            .join(", ")
                            .split("\n")
                            .map((s, k) => (
                              <Fragment key={k}>
                                {s}
                                <br />
                              </Fragment>
                            ))}
                        </>
                      )}
                    </Col>
                  </Row>
                ))}
            </TabPane>

            <TabPane
              tabId={InventoryDisplayTabs.Relationships}
              id="inventory-item-display-tab-pane-relationships"
              aria-labelledby={"inventory-item-display-tab-relationships"}
            >
              {
                <InventoryItemRelationshipEditDisplay
                  currentItemView={currentItemView}
                  api={api}
                  handleRelationshipsAdded={onRelationshipsAdded}
                  handleChildRelationshipDeleted={onChildRelationshipDeleted}
                  handleParentRelationshipDeleted={onParentRelationshipDeleted}
                />
              }
            </TabPane>

            <TabPane
              tabId={InventoryDisplayTabs.History}
              id="inventory-item-display-tab-pane-history"
              aria-labelledby={"inventory-item-display-tab-history"}
            >
              {currentItemView.history.map((i) => (
                <div key={i.id} className="history-item">
                  <span className="history-item-date">
                    <FormattedDate
                      value={i.activityDate}
                      year="numeric"
                      month="numeric"
                      day="numeric"
                      hour="numeric"
                      minute="numeric"
                      timeZoneName="short"
                    />
                  </span>
                  <span className="history-item-user">{i.userName}</span>
                  <span className="history-item-description">{i.description}</span>
                </div>
              ))}
            </TabPane>

            <TabPane
              tabId={InventoryDisplayTabs.Labels}
              id="inventory-item-display-tab-pane-labels"
              aria-labelledby={"inventory-item-display-tab-labels"}
            >
              <Row className="padding-top-10">
                <Col>
                  <div>
                    <Button color={"link"} id="info-copy-canvas" aria-label="Copy Label Information for Inventory Item">
                      <canvas id="info-canvas" aria-label={"Label Information for Inventory Item"} />
                    </Button>
                  </div>
                  <div>
                    <Button id="info-copy" color={"link"}>
                      Copy Label Information
                    </Button>
                  </div>
                </Col>
                <Col>
                  <div>
                    <Button
                      color={"link"}
                      id="qrcode-download-canvas"
                      aria-label="Download QR Code Image for Inventory Item"
                      onClick={onQRDownloaded}
                    >
                      <canvas id="qrcode-canvas" aria-label={"QR Code for Inventory Item"} />
                    </Button>
                  </div>
                  <div>
                    <Button color={"link"} id="qrcode-download" onClick={onQRDownloaded}>
                      Download QR Code Image
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row className="padding-top-10">
                <Col>
                  <Row>
                    <Button
                      size="sm"
                      outline
                      onClick={() => {
                        loadPrinters();
                      }}
                    >
                      <FormattedMessage id="load.printers" />
                    </Button>
                  </Row>
                </Col>
              </Row>
              {arePrintersLoading && (
                <div>
                  <Loading messageId="searching.for.dymo" />
                </div>
              )}
              <div style={{ display: showDymoLabels && !arePrintersLoading ? "block" : "none" }}>
                <Row className="padding-top-10">
                  <Col>
                    <FormGroup>
                      <Label for="printersSelect">
                        <FormattedMessage id="printer" />
                      </Label>
                      <Input id="printersSelect" type="select" />
                    </FormGroup>
                  </Col>
                  <Col>{loadLabelTypes()}</Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      outline
                      onClick={() => {
                        printLabel();
                      }}
                    >
                      <FontAwesomeIcon icon={faPrint} /> <FormattedMessage id="print.label" />
                    </Button>
                  </Col>
                </Row>
              </div>
              {showDymoDirections && (
                <div className="padding-top-10">
                  <Row>
                    <AlertWithIcon color="error">
                      <div>
                        <strong>We cannot connect to your DYMO printer.</strong>
                      </div>
                      <div>
                        DYMO says: <em>{dymoError && dymoError.message}</em>
                      </div>
                      <div>Please follow the troubleshooting steps below.</div>
                    </AlertWithIcon>
                  </Row>
                  <Row>
                    <Col>
                      <div>
                        <ol>
                          <li>Go to your program installations and uninstall all of DYMO related programs</li>
                          <li>
                            Install one of following DYMO Frameworks based on your machine
                            <ul>
                              <li>
                                <a
                                  href="https://evetpractice.blob.core.windows.net/installs/DLS8Setup.8.7.3.exe"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Windows
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://evetpractice.blob.core.windows.net/installs/DLS8Setup.8.7.3.dmg"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Mac
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li>Make sure to properly connect DYMO LabelWriter 450 and refresh this page</li>
                        </ol>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </TabPane>

            <TabPane
              tabId={InventoryDisplayTabs.Attachments}
              id="inventory-item-display-tab-pane-attachments"
              aria-labelledby={"inventory-item-display-tab-attachments"}
            >
              <Row className="padding-top-10">
                <Col>{getAttachments(currentItemView.attachments)}</Col>
              </Row>
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </Col>
  );
}

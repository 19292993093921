import { InventoryItem, User } from "@labarchives/inventory-shared/build/inventory";
import { getUnits } from "@labarchives/inventory-shared/build/util/units";
import { ReactElement } from "react";
import { InventoryTypesState } from "../inventorytypes/types/state";
import { StorageState } from "../storage/types/state";
import { VendorState } from "../vendors/types/state";
import { userCanEditItemCheck } from "../components/Authentication/permissionChecks/userCanEditItemCheck";
import { InventoryApi } from "../api/InventoryApi";
import { InventoryApiClient } from "../api";
import { InventoryItemView } from "./types/views";
import { getDefaultInventoryItemView, getInventoryItemView } from "./selectors";
import { InventoryItemEditableComponent } from "./InventoryItemEditableComponent";
import { EditableInventoryProps, SelectedStorage } from "./InventoryItemEditableHooks";

async function loadInventoryItemView(
  inventoryId: string | undefined,
  inventoryTypesState: InventoryTypesState,
  vendorState: VendorState,
  storageState: StorageState,
  api: InventoryApi,
): Promise<InventoryItemView> {
  if (inventoryId) {
    return api.getInventoryItem(inventoryId).then((inventory) => {
      return getInventoryItemView(inventory, inventoryTypesState, storageState, vendorState);
    });
  }
  return getDefaultInventoryItemView(inventoryTypesState);
}

async function onSaveItem(item: InventoryItem, attachments: File[], user: User | undefined, api: InventoryApiClient): Promise<InventoryItem> {
  return api.updateInventoryItem(item, attachments);
}

const defaultProps: EditableInventoryProps = {
  units: getUnits(),
  buttonTextId: "update.item",
  allowDelete: true,
  allowRelationships: false,
  titleTextId: "edit.inventory.item",
  loadInventoryItemView,
  onSaveItem,
  authorizationCheck: userCanEditItemCheck,
  getSelectedStorage: (): SelectedStorage | undefined => {
    return undefined;
  },
};

export function UpdateInventory(): ReactElement {
  return InventoryItemEditableComponent({ ...defaultProps });
}

import { FormattedMessage, useIntl } from "react-intl";
import React, { ReactElement, useContext, useEffect } from "react";
import { getUnits } from "@labarchives/inventory-shared/build/util/units";
import { useParams } from "react-router";
import { Button } from "@labarchives/ui-design";
import { DisplayItemDetails } from "../../inventory/DisplayItemDetails";
import { AuthenticationContext } from "../../components/Authentication/AuthenticationContext";
import { Loading } from "../../components";
import { onPageLoad } from "../../utils/onPageLoad";
import { InventoryApiClient } from "../../api";
import { useAdminViewInventory } from "./AdminViewInventoryHooks";

type RouteParams = {
  id: string;
  accountId: string;
};

export function AdminViewInventory(): ReactElement {
  const { id, accountId: actId } = useParams<RouteParams>();
  const authState = useContext(AuthenticationContext);
  const inventoryId = id || "";
  const accountId = Number.parseInt(actId || "");
  const intl = useIntl();
  const api = new InventoryApiClient();

  const {
    isLoading,
    isAllowedToView,
    currentItemView,
    storageLocationView,
    onDownloadAttachment,
  } = useAdminViewInventory({
    inventoryId,
    accountId,
    authState,
    api,
  });

  useEffect(() => {
    onPageLoad(intl.formatMessage({ id: "page.title.inventory.view" }));
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (!isAllowedToView) {
    return <div></div>;
  }

  if (!currentItemView || !storageLocationView) {
    return (
      <div className="item-not-found">
        <FormattedMessage id="item.not.found" />
      </div>
    );
  }

  const onClose = (): void => {
    history.back();
  };

  return (
    <div id="inventory-item-display-container">
      <div className="faux-modal-header">
        <h1 className="faux-modal-title" id="inventory-item-display-name">
          {currentItemView.name}
        </h1>

        <div className="faux-modal-close">
          <Button type="button" onClick={onClose} aria-label={intl.formatMessage({ id: "back" })}>
            ×
          </Button>
        </div>
      </div>
      <div className="faux-modal-body">
        <DisplayItemDetails
          currency={authState.getCurrency()}
          units={getUnits()}
          storageLocations={storageLocationView}
          onDownloadAttachment={onDownloadAttachment}
          currentItemView={currentItemView}
          api={api}
          isStorageLocationRequired={false}
          onRelationshipsAdded={undefined}
          onChildRelationshipDeleted={undefined}
          onParentRelationshipDeleted={undefined}
          onQuantityChanged={undefined}
        />
      </div>
      <div className="faux-modal-footer">
        <Button className="me-1" onClick={onClose} color="primary">
          <FormattedMessage id="back" />
        </Button>
      </div>
    </div>
  );
}

import { FormattedMessage, useIntl } from "react-intl";
import React, { ReactElement, useContext } from "react";
import { Button, Modal } from "@labarchives/ui-design";
import { DragDropContext, Draggable, DraggingStyle, Droppable, DropResult, NotDraggingStyle } from "react-beautiful-dnd";
import "./DisplayComponent.scss";
import { InventorySortableColumn } from "@labarchives/inventory-shared/build/inventory/InventorySortableColumn";
import { faPlus, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { InventoryApiClient } from "../../api";
import { StorageContext } from "../../storage/StorageContext";
import { InventorySearchResults } from "../../inventory/search/results/InventorySearchResults";
import { AuthenticationContext } from "../../components/Authentication/AuthenticationContext";
import { CancelButton } from "../../components/CancelButton/CancelButton";
import { FlashAlertMessage } from "../../components/FlashAlertMessage/FlashAlertMessage";
import { ApplicationPaths } from "../../app/ApplicationPaths";
import { useDisplaySettings } from "./DisplaySettingsHooks";

class ColumnDefinition {
  public translationId: string;

  public constructor(translationId: string) {
    this.translationId = translationId;
  }
}

export function DisplayComponent(): ReactElement {
  const api = new InventoryApiClient();

  const {
    sortedColumns,
    availableColumns,
    onDropColumn,
    selectedId,
    availableId,
    sampleItems,
    isSavePromptOpen,
    isUpdatedMessageShown,
    isUpdateFailedMessageShown,
    onAddSelected,
    onRemoveSelected,
    storageLocations,
    currency,
    onMoveColumnLeft,
    onMoveColumnRight,
    onResetToDefault,
    onCancelSave,
    onSave,
    onSavePrompt,
    onUpdateMessageDismissed,
    onFailedMessageDismissed,
  } = useDisplaySettings({
    storageState: useContext(StorageContext),
    authState: useContext(AuthenticationContext),
    api: api,
  });
  const intl = useIntl();

  const columnDefinitions: { [id: number]: ColumnDefinition } = {
    [InventorySortableColumn.Location]: new ColumnDefinition("location"),
    [InventorySortableColumn.Type]: new ColumnDefinition("type"),
    [InventorySortableColumn.Price]: new ColumnDefinition("price"),
    [InventorySortableColumn.Quantity]: new ColumnDefinition("quantity"),
    [InventorySortableColumn.DateReceived]: new ColumnDefinition("date.received"),
    [InventorySortableColumn.Reorder]: new ColumnDefinition("request.reorder"),
    [InventorySortableColumn.Description]: new ColumnDefinition("description"),
    [InventorySortableColumn.Vendor]: new ColumnDefinition("vendor"),
    [InventorySortableColumn.CatalogNumber]: new ColumnDefinition("catalog.number"),
    [InventorySortableColumn.LotNumber]: new ColumnDefinition("lot.number"),
    [InventorySortableColumn.GrantNumber]: new ColumnDefinition("grant.number"),
    [InventorySortableColumn.PONumber]: new ColumnDefinition("po.number"),
    [InventorySortableColumn.Expiration]: new ColumnDefinition("expiration"),
    [InventorySortableColumn.UseNow]: new ColumnDefinition("use.now"),
    [InventorySortableColumn.Relationships]: new ColumnDefinition("relationships"),
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined): React.CSSProperties => ({
    userSelect: "none",
    background: isDragging ? "#F9F9F9" : "",
    ...draggableStyle,
  });

  const getListStyleSelected = (isDraggingOver: boolean): React.CSSProperties => ({
    background: isDraggingOver ? "#F6EEFA" : "",
    display: "flex",
    overflow: "auto",
  });

  const onDragEnd = (result: DropResult): void => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    onDropColumn(source.droppableId, destination.droppableId, source.index, destination.index);
  };

  return (
    <div>
      <div className="management-header">
        <h1 className="inventory-heading">
          <FormattedMessage id={"display.settings.title"} />
        </h1>
      </div>
      <FlashAlertMessage isOpen={isUpdatedMessageShown} onDismiss={onUpdateMessageDismissed} />
      <FlashAlertMessage
        isOpen={isUpdateFailedMessageShown}
        alertColor={"error"}
        messageId={"update.display.error"}
        onDismiss={onFailedMessageDismissed}
      />

      <DragDropContext onDragEnd={onDragEnd}>
        <div>
          <div>
            <FormattedMessage id={"available.columns"} />
          </div>
          <Droppable droppableId={availableId} direction={"horizontal"}>
            {(provided, snapshot) => (
              <div className={"available-columns"} ref={provided.innerRef} style={getListStyleSelected(snapshot.isDraggingOver)}>
                {availableColumns.map((item, index) => (
                  <Draggable key={item} draggableId={item.toString()} index={index}>
                    {(dragProvided, dragSnapshot) => (
                      <div
                        ref={dragProvided.innerRef}
                        {...dragProvided.draggableProps}
                        {...dragProvided.dragHandleProps}
                        className={"table-column-item"}
                        style={getItemStyle(dragSnapshot.isDragging, dragProvided.draggableProps.style)}
                      >
                        {intl.formatMessage({ id: columnDefinitions[item].translationId })}
                        <button className={"btn btn-link"} onClick={() => onAddSelected(item)} title={intl.formatMessage({ id: "add.column" })}>
                          <FontAwesomeIcon icon={faPlus} />
                        </button>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>

        <div>
          <FormattedMessage id={"selected.columns"} />
          <Droppable droppableId={selectedId} direction={"horizontal"}>
            {(provided, snapshot) => (
              <div className={"selected-columns"} ref={provided.innerRef} style={getListStyleSelected(snapshot.isDraggingOver)}>
                <div className={"table-column-item"}>{intl.formatMessage({ id: "name" })}</div>
                {sortedColumns.map((item, index) => (
                  <Draggable key={item} draggableId={item.toString()} index={index}>
                    {(dragProvided, dragSnapshot) => (
                      <div
                        ref={dragProvided.innerRef}
                        {...dragProvided.draggableProps}
                        {...dragProvided.dragHandleProps}
                        className={"table-column-item"}
                        style={getItemStyle(dragSnapshot.isDragging, dragProvided.draggableProps.style)}
                      >
                        {intl.formatMessage({ id: columnDefinitions[item].translationId })}
                        <button className={"btn btn-link"} onClick={() => onRemoveSelected(item)} title={intl.formatMessage({ id: "remove.column" })}>
                          <FontAwesomeIcon icon={faTimes} />
                        </button>
                        <button className={"visually-hidden"} onClick={() => onMoveColumnLeft(index)}>
                          <FormattedMessage id={"move.column.left"} />
                        </button>
                        <button className={"visually-hidden"} onClick={() => onMoveColumnRight(index)}>
                          <FormattedMessage id={"move.column.right"} />
                        </button>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>

      <div className={"display-settings-preview"}>
        <InventorySearchResults
          isLoading={false}
          searchResults={sampleItems}
          storageLocations={storageLocations}
          currency={currency}
          columnOrder={sortedColumns}
          onResultsOptionsChanged={() => {
            // no-op
          }}
          itemLink={ApplicationPaths.Inventory.Item}
          onUseNow={() => {
            // no-op
          }}
          isUsed={() => false}
          onInventoryStorageChanged={() => {
            // no-op
          }}
          api={api}
        />
        <div className={"display-settings-overlay"}>
          <div>
            <FormattedMessage id={"preview"} />
          </div>
        </div>
      </div>

      <div className="management-edit-footer">
        <Button color={"none"} outline onClick={() => onResetToDefault()}>
          <FormattedMessage id={"restore.default.display"} />
        </Button>
        <Button className={"ms-1"} type="submit" onClick={onSavePrompt} color="primary">
          <FormattedMessage id="update.display" />
        </Button>
      </div>

      <Modal isOpen={isSavePromptOpen} toggle={onCancelSave}>
        <ModalHeader toggle={onCancelSave}>
          <FormattedMessage id="update.display.header" />
        </ModalHeader>
        <ModalBody>
          <FormattedMessage id="update.display.confirmation" />
        </ModalBody>
        <ModalFooter>
          <div className="">
            <Button color="primary" onClick={onSave} className="me-1">
              <FormattedMessage id="update.display" />
            </Button>
            <CancelButton onClick={onCancelSave} />
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}

/* eslint-disable promise/no-nesting */
import { useEffect, useState } from "react";
import * as clock from "@labarchives/inventory-shared/build/util/clock";
import { getUnits } from "@labarchives/inventory-shared/build/util/units";
import { StorageLocationView } from "../storage/types/views";
import { UserState } from "../user/types/state";
import { InventoryTypesState } from "../inventorytypes/types/state";
import { StorageState } from "../storage/types/state";
import { VendorState } from "../vendors/types/state";
import { AuthenticationState } from "../components/Authentication/AuthenticationState";
import { getStorageLocationView } from "../storage/selectors";
import * as utils from "../utils";
import { history } from "../app/history";
import { isNotFoundError } from "../utils/errorHelpers";
import { InventoryStorageChangedFunction } from "../storage/EditableStorageHooks";
import { InventoryApi } from "../api/InventoryApi";
import { isInventoryStorageLocationRequired } from "../inventorytypes/selectors";
import { RouterNavigationHooks } from "../utils/useRouterNavigation";
import { InventoryItemIdentity } from "../../../shared/inventory/InventoryItemIdentity";
import { RelationshipItemIds } from "../../../shared/inventory/RelationshipItemIds";
import { getDefaultInventoryItemView, getInventoryItemView } from "./selectors";
import { InventoryItemView } from "./types/views";

export interface InventoryItemDisplayHooks {
  currentItemView: InventoryItemView;
  isLoading: boolean;
  units: string[];
  storageLocations: StorageLocationView;
  isStorageLocationRequired: boolean;
  onInventoryStorageChanged: InventoryStorageChangedFunction;

  onInventoryUsed(id: number, amount: number, unitOfMeasure: string, storageCells: string[]): void;

  onDownloadAttachment(attachmentId: number): void;

  onQuantityChanged(id: number, amount: number, unitOfMeasure: string): void;

  onRelationshipsAdded(id: number | string, { parentIds, childIds }: RelationshipItemIds): void;

  onChildRelationshipDeleted(id: number, name: string, childItem: InventoryItemIdentity): void;

  onParentRelationshipDeleted(id: number, name: string, parentItem: InventoryItemIdentity): void;

  onClose(): void;
}

export function useInventoryItemDisplay(
  inventoryId: string,
  userState: UserState,
  inventoryTypesState: InventoryTypesState,
  storageState: StorageState,
  vendorState: VendorState,
  authState: AuthenticationState,
  api: InventoryApi,
  routerNavigation: RouterNavigationHooks,
): InventoryItemDisplayHooks {
  const [isLoading, setIsLoading] = useState(true);
  const [currentItemView, setCurrentItemView] = useState(getDefaultInventoryItemView(inventoryTypesState));

  let storageLocationView = getStorageLocationView(storageState);

  const onInventoryStorageChanged = (locationId?: number | null, storageCells?: string[] | null, notes?: string | null): void => {
    api.updateInventoryStorageLocation(inventoryId, locationId, storageCells, notes).then((inventory) => {
      setCurrentItemView(getInventoryItemView(inventory, inventoryTypesState, storageState, vendorState));
      return inventory;
    });
  };

  const onDownloadAttachment = async (attachmentId: number): Promise<void> => {
    await api.getAttachment(inventoryId, attachmentId).then((attachment) => utils.saveAs(attachment.data, attachment.filename));
  };

  const onInventoryUsed = (id: number, amount: number, unitOfMeasure: string, storageCells: string[]): void => {
    api.updateInventoryUsage(id, amount, unitOfMeasure, storageCells).then((inventory) => {
      userState.updateRecentlyUsedInventory(id);
      setCurrentItemView(getInventoryItemView(inventory, inventoryTypesState, storageState, vendorState));
      return inventory;
    });
  };

  const onQuantityChanged = (id: number, quantity: number, unitOfMeasure: string): void => {
    api.updateInventoryQuantity(id, quantity, unitOfMeasure).then((inventory) => {
      setCurrentItemView(getInventoryItemView(inventory, inventoryTypesState, storageState, vendorState));
      return inventory;
    });
  };

  const onRelationshipsAdded = (id: number | string, { parentIds, childIds }: RelationshipItemIds): void => {
    void api.addInventoryRelationshipItems(id, { parentIds, childIds }).then((inventory) => {
      setCurrentItemView(getInventoryItemView(inventory, inventoryTypesState, storageState, vendorState));
      return inventory;
    });
  };

  const onChildRelationshipDeleted = (id: number, name: string, childItem: InventoryItemIdentity): void => {
    void api.deleteChildRelationship(id, name, childItem).then((inventory) => {
      setCurrentItemView(getInventoryItemView(inventory, inventoryTypesState, storageState, vendorState));
      return inventory;
    });
  };

  const onParentRelationshipDeleted = (id: number, name: string, parentItem: InventoryItemIdentity): void => {
    void api.deleteParentRelationship(id, name, parentItem).then((inventory) => {
      setCurrentItemView(getInventoryItemView(inventory, inventoryTypesState, storageState, vendorState));
      return inventory;
    });
  };

  const onClose = (): void => {
    const prevLocation = routerNavigation.getPreviousLocation();
    if (prevLocation) {
      routerNavigation.navigateTo(prevLocation.pathname);
    } else {
      history.back();
    }
  };

  const refreshDependencies = async (): Promise<void[]> => {
    const promises: Promise<void>[] = [storageState.refresh(), inventoryTypesState.refresh(), vendorState.refresh()];
    return Promise.all(promises);
  };

  useEffect(() => {
    refreshDependencies().then(() => {
      // eslint-disable-next-line promise/always-return
      if (!inventoryTypesState.isLoading && !storageState.isLoading && !vendorState.isLoading) {
        storageLocationView = getStorageLocationView(storageState);
        api
          .getInventoryItem(inventoryId)
          .then((inventory) => {
            setCurrentItemView(getInventoryItemView(inventory, inventoryTypesState, storageState, vendorState));
            setIsLoading(false);
            return inventory;
          })
          .then((inventory) => {
            const user = authState.getUser();
            if (user) {
              void api.addInventoryHistory(inventory.id, {
                activityDate: clock.getNow(),
                userId: user.id,
                userName: user.fullName,
                description: "Item viewed",
                id: 0,
              });
            }
            return inventory;
          })
          .catch((error) => {
            if (isNotFoundError(error)) {
              setIsLoading(false);
            } else {
              throw error;
            }
          });
      }
    });
  }, [inventoryId, inventoryTypesState.isLoading, storageState.isLoading, vendorState.isLoading]);

  return {
    isLoading: isLoading || inventoryTypesState.isLoading || storageState.isLoading || vendorState.isLoading,
    currentItemView,
    units: getUnits(),
    storageLocations: storageLocationView,
    isStorageLocationRequired: isInventoryStorageLocationRequired(inventoryTypesState, currentItemView.typeId),
    onInventoryUsed,
    onInventoryStorageChanged,
    onDownloadAttachment,
    onQuantityChanged,
    onRelationshipsAdded,
    onChildRelationshipDeleted,
    onParentRelationshipDeleted,
    onClose,
  };
}

import {
  AdvancedSearchCriteria,
  InventorySearchCriteria,
  OrderSearchCriteria,
  SiteAdminInventorySearchCriteria,
} from "@labarchives/inventory-shared/build/inventory";
import { UserAlertsStatus } from "../user/UserAlertsStatus";
import { ACCOUNT_ID_COOKIE_NAME, getCookie } from "../utils/cookies";
import { InventoryDisplayMode } from "../app/InventoryDisplayMode";
import { InventoryDisplayTabs } from "../inventory/DisplayItemDetails";

export const dateParser = (key: unknown, value: string | Date | unknown): Date | unknown => {
  const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}\.\d*)(?:Z|([+-])([\d:|]*))?$/;
  const reMsAjax = /^\/Date\((d|-|.*)\)[/\\|]$/;

  if (typeof value === "string") {
    let a = reISO.exec(value);
    if (a) {
      return new Date(value);
    }
    a = reMsAjax.exec(value);
    if (a) {
      const b = a[1].split(/[+,.-]/);
      return new Date(b[0] ? +b[0] : 0 - +b[1]);
    }
  }
  return value;
};

function getAccountId(): number {
  const accountId = getCookie(ACCOUNT_ID_COOKIE_NAME);
  if (accountId) {
    return Number.parseInt(accountId);
  }

  return 0;
}

interface LocalStorageContainer {
  perAccount: LocalStorageData[];
  userAlerts: UserAlertsStatus;
  dismissedNotices: number[];
  displayMode: InventoryDisplayMode | undefined;
  siteAdminInventorySearchCriteria: SiteAdminInventorySearchCriteria;
}

interface LocalStorageData {
  accountId: number;
  inventorySearchCriteria: InventorySearchCriteria;
  inventoryAdvancedSearchCriteria: AdvancedSearchCriteria[];
  orderSearchCriteria: OrderSearchCriteria;
  itemDisplayTab: InventoryDisplayTabs | undefined;
}

function getDefaultSearchCriteria(): InventorySearchCriteria {
  return { term: "", typeIds: [], locationIds: [] };
}

function getDefaultOrderCriteria(): OrderSearchCriteria {
  return { typeIds: [], statusIds: [], term: "" };
}

function getDefaultSiteAdminCriteria(): SiteAdminInventorySearchCriteria {
  return { accountIds: [], term: "", safetyCriteria: { hazards: [] } };
}

function getDefaultUserAlerts(): UserAlertsStatus {
  return { hiddenIds: [] };
}

function getDefaultLocalStorageData(): LocalStorageData {
  return {
    accountId: getAccountId(),
    inventoryAdvancedSearchCriteria: [],
    inventorySearchCriteria: getDefaultSearchCriteria(),
    orderSearchCriteria: getDefaultOrderCriteria(),
    itemDisplayTab: undefined,
  };
}

function getDefaultLocalStorageContainer(): LocalStorageContainer {
  return {
    perAccount: [getDefaultLocalStorageData()],
    userAlerts: getDefaultUserAlerts(),
    dismissedNotices: [],
    siteAdminInventorySearchCriteria: getDefaultSiteAdminCriteria(),
    displayMode: undefined,
  };
}

function getLocalStorageContainer(): LocalStorageContainer {
  const containerString = window.localStorage.getItem("inventoryContainer");
  if (!containerString) {
    return getDefaultLocalStorageContainer();
  }

  const containerJson: LocalStorageContainer = JSON.parse(containerString, dateParser);
  if (!containerJson) {
    return getDefaultLocalStorageContainer();
  }

  return containerJson;
}

function saveLocalStorageContainer(container: LocalStorageContainer): void {
  window.localStorage.setItem("inventoryContainer", JSON.stringify(container));
}

function saveLocalStorageData(dataToSave: LocalStorageData): void {
  const container = getLocalStorageContainer();
  const perAccount = container.perAccount.filter((a) => a.accountId !== getAccountId());
  perAccount.push(dataToSave);
  container.perAccount = perAccount;

  saveLocalStorageContainer(container);
}

function getLocalStorageData(): LocalStorageData {
  const containerJson = getLocalStorageContainer();

  const data = containerJson.perAccount.find((a) => a.accountId === getAccountId());
  if (!data) {
    return getDefaultLocalStorageData();
  }

  return data;
}

export const getInventorySearchCriteria = (): InventorySearchCriteria => {
  const storage = getLocalStorageData();
  return storage.inventorySearchCriteria || getDefaultSearchCriteria();
};

export const setInventorySearchCriteria = (criteria: InventorySearchCriteria): void => {
  const storage = getLocalStorageData();
  saveLocalStorageData({ ...storage, inventorySearchCriteria: criteria });
};

export const getInventoryAdvancedSearchCriteria = (): AdvancedSearchCriteria[] => {
  const storage = getLocalStorageData();
  return storage.inventoryAdvancedSearchCriteria || [];
};

export const setInventoryAdvancedSearchCriteria = (criteria: AdvancedSearchCriteria[]): void => {
  const storage = getLocalStorageData();
  saveLocalStorageData({ ...storage, inventoryAdvancedSearchCriteria: criteria });
};

export const getOrderSearchCriteria = (): OrderSearchCriteria => {
  const storage = getLocalStorageData();
  return storage.orderSearchCriteria || getDefaultOrderCriteria();
};

export const setOrderSearchCriteria = (criteria: OrderSearchCriteria): void => {
  const storage = getLocalStorageData();
  saveLocalStorageData({ ...storage, orderSearchCriteria: criteria });
};

export const getInventoryAdminSearchCriteria = (): SiteAdminInventorySearchCriteria => {
  const storage = getLocalStorageContainer();
  return storage.siteAdminInventorySearchCriteria || getDefaultSiteAdminCriteria();
};

export const setInventoryAdminSearchCriteria = (criteria: SiteAdminInventorySearchCriteria): void => {
  const storage = getLocalStorageContainer();
  saveLocalStorageContainer({ ...storage, siteAdminInventorySearchCriteria: criteria });
};

export const getUserAlerts = (): UserAlertsStatus => {
  const storage = getLocalStorageContainer();
  return storage.userAlerts || getDefaultUserAlerts();
};

export const setUserAlerts = (alerts: UserAlertsStatus): void => {
  const storage = getLocalStorageContainer();
  saveLocalStorageContainer({ ...storage, userAlerts: alerts });
};

export const isUserNoticeDismissed = (id: number): boolean => {
  const storage = getLocalStorageContainer();
  if (!storage.dismissedNotices) {
    return false;
  }

  const notices = storage.dismissedNotices;
  return notices.includes(id);
};

export const dismissUserNotice = (id: number): void => {
  const storage = getLocalStorageContainer();
  let notices: number[] = [];
  if (storage.dismissedNotices) {
    notices = [...storage.dismissedNotices];
  }

  notices.push(id);
  saveLocalStorageContainer({ ...storage, dismissedNotices: notices });
};

export const getDisplayMode = (): InventoryDisplayMode | undefined => {
  return getLocalStorageContainer().displayMode;
};

export const setDisplayMode = (displayMode: InventoryDisplayMode): void => {
  const storage = getLocalStorageContainer();
  saveLocalStorageContainer({ ...storage, displayMode });
};

export const setItemDisplayTab = (tab: InventoryDisplayTabs): void => {
  const storage = getLocalStorageData();

  saveLocalStorageData({ ...storage, itemDisplayTab: tab });
};

export const getItemDisplayTab = (): InventoryDisplayTabs | undefined => {
  const storage = getLocalStorageData();
  return storage.itemDisplayTab;
};

export const clear = (): void => {
  window.localStorage.clear();
};

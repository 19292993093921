import { ButtonProps } from "@labarchives/ui-design/dist/components/Button";
import React, { ReactElement, useContext, useState } from "react";
import { SubscriptionType } from "@labarchives/inventory-shared/build/inventory";
import { Button, Modal } from "@labarchives/ui-design";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";
import { AuthenticationContext } from "../Authentication/AuthenticationContext";
import { AuthenticationState } from "../Authentication/AuthenticationState";
import { CancelButton } from "../CancelButton/CancelButton";
import { ApplicationPaths } from "../../app/ApplicationPaths";

export interface PaidFeatureButtonHooks {
  isSubscribeModalShown: boolean;

  onClick(): void;
  onSubscribeModalHidden(): void;
}

interface PaidFeatureButtonProps {
  authState: AuthenticationState;
  action: () => void;
}

export function usePaidFeatureButton(props: PaidFeatureButtonProps): PaidFeatureButtonHooks {
  const user = props.authState.getUser();
  const [subscribeModalShown, setSubscribeModalShown] = useState(false);

  function onClick(): void {
    if (user && (user.isSite || user.activeAccount.subscriptionType === SubscriptionType.Paid)) {
      props.action();
      setSubscribeModalShown(false);
    } else {
      setSubscribeModalShown(true);
    }
  }

  function onSubscribeModalHidden(): void {
    setSubscribeModalShown(false);
  }

  return {
    onClick,
    onSubscribeModalHidden,
    isSubscribeModalShown: subscribeModalShown,
  };
}

export function PaidFeatureButton({
  color,
  dropdownToggleColor,
  disabled,
  onAllowedClick,
  ...passThroughProps
}: ButtonProps & { onAllowedClick: () => void }): ReactElement {
  const { onClick, isSubscribeModalShown, onSubscribeModalHidden } = usePaidFeatureButton({
    authState: useContext(AuthenticationContext),
    action: onAllowedClick,
  });

  const navigate = useNavigate();

  return (
    <>
      <Button color={color} dropdownToggleColor={dropdownToggleColor} disabled={disabled} onClick={() => onClick()} {...passThroughProps}></Button>
      <Modal isOpen={isSubscribeModalShown} className="paid-feature-modal" toggle={onSubscribeModalHidden}>
        <ModalHeader toggle={onSubscribeModalHidden}>
          <FormattedMessage id="upgrade.your.account" />
        </ModalHeader>
        <ModalBody>
          <FormattedMessage id={"paid.feature.notice"} />
        </ModalBody>
        <ModalFooter>
          <div>
            <Button color={"primary"} type="button" onClick={() => navigate(ApplicationPaths.Subscription.Home)} className={"me-1"}>
              <FormattedMessage id={"upgrade.now"} />
            </Button>
            <CancelButton onClick={onSubscribeModalHidden} />
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}

import { InventoryItem, RolePermissions, User } from "@labarchives/inventory-shared/build/inventory";
import { getUnits } from "@labarchives/inventory-shared/build/util/units";
import { ReactElement } from "react";
import { InventoryTypesState } from "../inventorytypes/types/state";
import { AuthenticationState } from "../components/Authentication/AuthenticationState";
import { InventoryApi } from "../api/InventoryApi";
import { InventoryItemEditableComponent } from "./InventoryItemEditableComponent";
import { InventoryItemView } from "./types/views";
import { getDefaultInventoryItemView } from "./selectors";
import { EditableInventoryProps, SelectedStorage } from "./InventoryItemEditableHooks";

async function loadInventoryItemView(inventoryId: string | undefined, inventoryTypesState: InventoryTypesState): Promise<InventoryItemView> {
  return getDefaultInventoryItemView(inventoryTypesState);
}

async function onSaveItem(item: InventoryItem, attachments: File[], user: User | undefined, api: InventoryApi): Promise<InventoryItem> {
  return api.addInventoryItem(item, attachments);
}

const defaultProps: EditableInventoryProps = {
  units: getUnits(),
  buttonTextId: "add.item",
  allowDelete: false,
  allowRelationships: true,
  titleTextId: "add.inventory.item",
  loadInventoryItemView,
  onSaveItem,
  authorizationCheck(): (authenticationState: AuthenticationState) => boolean {
    return (auth) => auth.hasPermissions([RolePermissions.InventoryAddCreatedItem]);
  },
  getSelectedStorage: (qs: URLSearchParams): SelectedStorage | undefined => {
    const queryStringLocationId = qs.get("location");
    const queryStringStorageCell = qs.get("cell");

    if (queryStringLocationId && queryStringStorageCell && !Number.isNaN(Number.parseInt(queryStringLocationId))) {
      return { locationId: Number.parseInt(queryStringLocationId), cell: queryStringStorageCell };
    }
    return undefined;
  },
};

export function AddInventory(): ReactElement {
  return InventoryItemEditableComponent({ ...defaultProps });
}

import React, { ChangeEvent, Fragment, ReactElement, useContext, useEffect } from "react";
import { Col, Form, FormFeedback, FormGroup, Input, InputGroup, InputGroupText, Label, Row } from "reactstrap";
import { AlertWithIcon, Button, CheckboxInput, CheckboxLabel } from "@labarchives/ui-design/dist";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useLocation, useParams } from "react-router";
import { CustomAttributeType, RolePermissions } from "@labarchives/inventory-shared/build/inventory";
import * as clock from "@labarchives/inventory-shared/build/util/clock";
import { getConvertibleUnits } from "@labarchives/inventory-shared/build/util/units";
import { Loading } from "../components";
import { VendorsContext } from "../vendors/VendorsContext";
import { InventoryTypesContext } from "../inventorytypes/InventoryTypesContext";
import "./InventoryItemAdd.css";
import { StorageContext } from "../storage/StorageContext";
import { getStorageLocationView } from "../storage/selectors";
import { RequiredLabelIndicator } from "../components/RequiredLabelIndicator/RequiredLabelIndicator";
import { AuthorizedComponent } from "../components/Authentication/AuthorizedComponent";
import { EditableStorage } from "../storage/EditableStorage";
import { CreatableVendorsDropdown } from "../vendors/dropdown/CreatableVendorsDropdown";
import { InventoryDatePicker } from "../components/DatePicker/InventoryDatePicker";
import { CancelButton } from "../components/CancelButton/CancelButton";
import { AuthenticationContext } from "../components/Authentication/AuthenticationContext";
import { ApplicationPaths } from "../app/ApplicationPaths";
import { FlashAlertMessage } from "../components/FlashAlertMessage/FlashAlertMessage";
import { onPageLoad } from "../utils/onPageLoad";
import { InventoryApiClient } from "../api";
import { useRouterNavigation } from "../utils/useRouterNavigation";
import { InventoryAttachmentUpload } from "./attachment-upload/InventoryAttachmentUpload";
import { EditableInventoryProps, useInventoryItemEditable } from "./InventoryItemEditableHooks";
import { CasSearchInput } from "./custom-attribute-input/CasSearchInput";
import { CustomAttributeInput } from "./custom-attribute-input/CustomAttributeInput";
import { InventoryItemEditableFormInputBuilder } from "./InventoryItemEditableFormInputBuilder";
import { AddInventoryItemRelationships } from "./AddInventoryItemRelationships";

type RouteParams = {
  id: string;
};

export function InventoryItemEditableComponent(props: EditableInventoryProps): ReactElement {
  const { id } = useParams<RouteParams>();
  const vendorState = useContext(VendorsContext);
  const inventoryTypesState = useContext(InventoryTypesContext);
  const storageState = useContext(StorageContext);
  const authState = useContext(AuthenticationContext);
  const intl = useIntl();

  const storageLocations = getStorageLocationView(storageState);
  const inventoryId = id || "";
  const expirationNoticeRef = React.createRef<HTMLInputElement>();
  const expirationNoticeDaysRef = React.createRef<HTMLInputElement>();
  const quantityNoticeRef = React.createRef<HTMLInputElement>();
  const quantityNoticeAmountRef = React.createRef<HTMLInputElement>();
  const quantityNoticeUnitRef = React.createRef<HTMLInputElement>();
  const searchParams = new URLSearchParams(useLocation().search);
  const api = new InventoryApiClient();

  const {
    formInputs,
    storageCells,
    storageLocationId,
    storageLocationNotes,
    filesAttached,
    filesToAttach,
    customAttributes,
    notifications,
    isCasSearchEnabled,
    getValues,
    onCheckboxChange,
    onElementChange,
    onInventoryStorageChanged,
    onChangeInventoryType,
    onClose,
    onSubmitAddForm,
    onFilesDropped,
    onRemoveAttachment,
    onRemoveUpload,
    onExpirationNotificationChanged,
    onQuantityNotificationChanged,
    onCasSearched,
    isLoading,
    currentItemView,
    inventoryTypeViews,
    isNotFound,
    showSaveError,
    showSaveAttachmentError,
    setShowSaveError,
    setShowSaveAttachmentError,
    onParentsChanged,
    onChildrenChanged,
    parentItems,
    childItems,
  } = useInventoryItemEditable(
    inventoryId,
    props,
    props.getSelectedStorage(searchParams),
    inventoryTypesState,
    storageState,
    vendorState,
    authState,
    new InventoryItemEditableFormInputBuilder(),
    api,
    useRouterNavigation(),
  );

  useEffect(() => {
    onPageLoad(intl.formatMessage({ id: props.titleTextId }, { item: currentItemView.name }));
  }, []);

  function submitForm(e: React.FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    onSubmitAddForm();
  }

  function changeValue(fieldName: string, e: ChangeEvent<HTMLInputElement>): void {
    onElementChange(fieldName, e.target.value);
  }

  function changeDate(fieldName: string, date: Date | null): void {
    onElementChange(fieldName, date ? moment(date).format(clock.STANDARD_MOMENT_DATE_FORMAT) : "");
  }

  function changeVendor(vendorId: number | null): void {
    onElementChange(formInputs.vendor.fieldName, vendorId ? vendorId.toString() : "");
  }

  function changeInventoryType(e: ChangeEvent<HTMLInputElement>): void {
    onChangeInventoryType(Number.parseInt(e.target.value));
  }

  function onDynamicCheckboxChange(fieldName: string, e: ChangeEvent<HTMLInputElement>): void {
    onCheckboxChange(fieldName, e.target.value.trim());
  }

  function changeExpirationNotice(): void {
    let enabled = false;
    if (expirationNoticeRef.current) {
      enabled = expirationNoticeRef.current.checked;
    }

    const turningOn = !notifications.sendExpirationNotification && enabled;
    let numberOfDays: number | null = turningOn && notifications.expirationNotificationDays === null ? 30 : notifications.expirationNotificationDays;
    if (enabled && expirationNoticeDaysRef.current) {
      numberOfDays = Number.parseInt(expirationNoticeDaysRef.current.value) || 0;
    }

    onExpirationNotificationChanged(enabled, numberOfDays);
  }

  function changeQuantityNotice(): void {
    let enabled = false;
    if (quantityNoticeRef.current) {
      enabled = quantityNoticeRef.current.checked;
    }

    const turningOn = !notifications.sendReorderNotification && enabled;
    let amount: number | null = turningOn && notifications.reorderNotificationQuantity === null ? 1 : notifications.reorderNotificationQuantity;
    let unit: string | null =
      turningOn && !notifications.reorderNotificationUnit ? formInputs.unitOfMeasure.value || null : notifications.reorderNotificationUnit;

    if (enabled && quantityNoticeAmountRef.current) {
      amount = Number.parseInt(quantityNoticeAmountRef.current.value) || 0;
    }

    if (enabled && quantityNoticeUnitRef.current) {
      unit = quantityNoticeUnitRef.current.value;
    }

    onQuantityNotificationChanged(enabled, amount, unit);
  }

  function getExpirationNotificationMessage(): ReactElement {
    if (!notifications.sendExpirationNotification) {
      return <></>;
    }

    if (!formInputs.expirationDate.value) {
      return (
        <span className="error-note">
          <FormattedMessage id="expiration.notification.will.never.be.sent" />
        </span>
      );
    }

    return <></>;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showSaveError, showSaveAttachmentError]);

  if (isLoading) {
    return <Loading />;
  }

  if (isNotFound) {
    return (
      <div className="item-not-found">
        <FormattedMessage id="item.not.found" />
      </div>
    );
  }

  return (
    <AuthorizedComponent requiredPermissions={[]} additionalChecks={props.authorizationCheck(currentItemView.ownerId)}>
      <Form onSubmit={submitForm} noValidate>
        <div>
          <div className="faux-modal-header">
            <h1 className="faux-modal-title">
              <FormattedMessage id={props.titleTextId} />
            </h1>
            <div className="faux-modal-close">
              <Button type="button" onClick={onClose} aria-label={intl.formatMessage({ id: "cancel" })}>
                ×
              </Button>
            </div>
          </div>

          <div className="faux-modal-body" id="inventory-body">
            <FlashAlertMessage
              isOpen={showSaveError}
              onDismiss={() => setShowSaveError(false)}
              alertColor={"error"}
              messageId={"error.saving.inventory"}
            />

            <FlashAlertMessage
              isOpen={showSaveAttachmentError}
              onDismiss={() => setShowSaveAttachmentError(false)}
              alertColor={"error"}
              messageId={"error.saving.inventory.attachment"}
              isHtml={true}
            />

            <Col lg={12} xl={{ size: 10, offset: 1 }}>
              <Row>
                <Col xs={12} xl={6}>
                  <Row>
                    <Col>
                      <h2 className="inventory-heading">
                        <FormattedMessage id="about" />
                      </h2>
                    </Col>
                  </Row>
                  <Row id="inventory-about">
                    <Col>
                      <FormGroup>
                        <Label for="item-type">
                          <FormattedMessage id="type" />
                          <RequiredLabelIndicator required={formInputs.typeId.required} />
                        </Label>
                        <Input
                          id="item-type"
                          type="select"
                          value={formInputs.typeId.value}
                          name={formInputs.typeId.fieldName}
                          required={formInputs.typeId.required}
                          onChange={(e) => changeInventoryType(e)}
                          invalid={!formInputs.typeId.isValid}
                          innerRef={formInputs.typeId.ref}
                        >
                          {inventoryTypeViews.map((type) => (
                            <option key={`item-type${type.id}`} value={type.id}>
                              {type.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="item-name">
                          <FormattedMessage id="name" />
                          <RequiredLabelIndicator required={formInputs.name.required} />
                        </Label>
                        <Input
                          id="item-name"
                          type="text"
                          value={formInputs.name.value}
                          name={formInputs.name.fieldName}
                          required={formInputs.name.required}
                          onChange={(e) => changeValue(formInputs.name.fieldName, e)}
                          invalid={!formInputs.name.isValid}
                          innerRef={formInputs.name.ref}
                          maxLength={500}
                          autoComplete="off"
                        />
                        <FormFeedback>
                          <FormattedMessage id="name.is.required" />
                        </FormFeedback>
                      </FormGroup>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label for="item-quantity">
                              <FormattedMessage id="amount" />
                              <RequiredLabelIndicator required={formInputs.quantityAvailable.required} />
                            </Label>
                            <Input
                              id="item-quantity"
                              className="quantity"
                              type="number"
                              defaultValue={formInputs.quantityAvailable.value}
                              name={formInputs.quantityAvailable.fieldName}
                              required={formInputs.quantityAvailable.required}
                              onChange={(e) => changeValue(formInputs.quantityAvailable.fieldName, e)}
                              invalid={!formInputs.quantityAvailable.isValid}
                              innerRef={formInputs.quantityAvailable.ref}
                              step="any"
                              min={0}
                            />
                            <FormFeedback>
                              <FormattedMessage id="amount.is.required" />
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label for="quantity-unit-of-measure">
                              <FormattedMessage id="units" />
                              <RequiredLabelIndicator required={formInputs.unitOfMeasure.required} />
                            </Label>
                            <Input
                              id="quantity-unit-of-measure"
                              type="select"
                              defaultValue={formInputs.unitOfMeasure.value}
                              name={formInputs.unitOfMeasure.fieldName}
                              required={formInputs.unitOfMeasure.required}
                              onChange={(e) => changeValue(formInputs.unitOfMeasure.fieldName, e)}
                              invalid={!formInputs.unitOfMeasure.isValid}
                              innerRef={formInputs.unitOfMeasure.ref}
                            >
                              {props.units.map((u) => (
                                <option key={`unit${u}`} value={u}>
                                  {u}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      {formInputs.storageLocation.enabled && (
                        <FormGroup>
                          <Label for="storage-location-hidden">
                            <FormattedMessage id="location" />
                            <RequiredLabelIndicator required={formInputs.storageLocation.required} />
                          </Label>
                          <Input
                            id="storage-location-hidden"
                            type={"hidden"}
                            value={formInputs.storageLocation.value}
                            invalid={!formInputs.storageLocation.isValid}
                            innerRef={formInputs.storageLocation.ref}
                            name={formInputs.storageLocation.fieldName}
                            required={formInputs.storageLocation.required}
                          />
                          {storageLocations.locationList.length === 0 && (
                            <AuthorizedComponent requiredPermissions={[RolePermissions.LabManagementAllowed]}>
                              <AlertWithIcon isOpen color="info">
                                <em>
                                  <FormattedMessage id="no.storage.locations.defined" />
                                </em>
                                <br />
                                <Link to={ApplicationPaths.Management.StorageAdd}>
                                  <FormattedMessage id="add.storage.location" />
                                </Link>
                              </AlertWithIcon>
                            </AuthorizedComponent>
                          )}
                          <EditableStorage
                            storageLocations={storageLocations}
                            onInventoryStorageChanged={onInventoryStorageChanged}
                            selectedStorageCells={storageCells}
                            selectedStorageLocationId={storageLocationId}
                            storageNotes={storageLocationNotes}
                            api={api}
                            isRequired={formInputs.storageLocation.required}
                          />
                          <FormFeedback>
                            <FormattedMessage id="generic.is.required" values={{ subject: intl.formatMessage({ id: "location" }) }} />
                          </FormFeedback>
                        </FormGroup>
                      )}
                      {formInputs.description.enabled && (
                        <FormGroup>
                          <Label for="item-description">
                            <FormattedMessage id="description" />
                            <RequiredLabelIndicator required={formInputs.description.required} />
                          </Label>
                          <Input
                            id="item-description"
                            type="textarea"
                            defaultValue={formInputs.description.value}
                            name={formInputs.description.fieldName}
                            required={formInputs.description.required}
                            onChange={(e) => changeValue(formInputs.description.fieldName, e)}
                            invalid={!formInputs.description.isValid}
                            innerRef={formInputs.description.ref}
                          />
                          <FormFeedback>
                            <FormattedMessage id="generic.is.required" values={{ subject: intl.formatMessage({ id: "description" }) }} />
                          </FormFeedback>
                        </FormGroup>
                      )}
                      {formInputs.vendor.enabled && (
                        <FormGroup>
                          <Label for="item-vendor">
                            <FormattedMessage id="vendor" />
                            <RequiredLabelIndicator required={formInputs.vendor.required} />
                          </Label>
                          <CreatableVendorsDropdown
                            isValid={formInputs.vendor.isValid}
                            selectedVendorId={formInputs.vendor.value}
                            innerRef={formInputs.vendor.ref}
                            onChange={changeVendor}
                            id="item-vendor"
                          />
                          <FormFeedback>
                            <FormattedMessage id="generic.is.required" values={{ subject: intl.formatMessage({ id: "vendor" }) }} />
                          </FormFeedback>
                        </FormGroup>
                      )}
                      {formInputs.catalogNumber.enabled && (
                        <FormGroup>
                          <Label for="item-catalog-number">
                            <FormattedMessage id="catalog.number" />
                            <RequiredLabelIndicator required={formInputs.catalogNumber.required} />
                          </Label>
                          <Input
                            id="item-catalog-number"
                            type="text"
                            defaultValue={formInputs.catalogNumber.value}
                            name={formInputs.catalogNumber.fieldName}
                            required={formInputs.catalogNumber.required}
                            onChange={(e) => changeValue(formInputs.catalogNumber.fieldName, e)}
                            invalid={!formInputs.catalogNumber.isValid}
                            innerRef={formInputs.catalogNumber.ref}
                            maxLength={255}
                          />
                          <FormFeedback>
                            <FormattedMessage id="generic.is.required" values={{ subject: intl.formatMessage({ id: "catalog.number" }) }} />
                          </FormFeedback>
                        </FormGroup>
                      )}
                      {formInputs.lotNumber.enabled && (
                        <FormGroup>
                          <Label for="item-lot-number">
                            <FormattedMessage id="lot.number" />
                            <RequiredLabelIndicator required={formInputs.lotNumber.required} />
                          </Label>
                          <Input
                            id="item-lot-number"
                            type="text"
                            defaultValue={formInputs.lotNumber.value}
                            name={formInputs.lotNumber.fieldName}
                            required={formInputs.lotNumber.required}
                            onChange={(e) => changeValue(formInputs.lotNumber.fieldName, e)}
                            invalid={!formInputs.lotNumber.isValid}
                            innerRef={formInputs.lotNumber.ref}
                            maxLength={255}
                          />
                          <FormFeedback>
                            <FormattedMessage id="generic.is.required" values={{ subject: intl.formatMessage({ id: "lot.number" }) }} />
                          </FormFeedback>
                        </FormGroup>
                      )}
                      {formInputs.price.enabled && (
                        <FormGroup>
                          <Label for="item-price">
                            <FormattedMessage id="price" />
                            <RequiredLabelIndicator required={formInputs.price.required} />
                          </Label>
                          <Input
                            id="item-price"
                            type="number"
                            min="0"
                            defaultValue={formInputs.price.value}
                            name={formInputs.price.fieldName}
                            required={formInputs.price.required}
                            onChange={(e) => changeValue(formInputs.price.fieldName, e)}
                            invalid={!formInputs.price.isValid}
                            innerRef={formInputs.price.ref}
                          />
                          <FormFeedback>
                            <FormattedMessage id="generic.is.required" values={{ subject: intl.formatMessage({ id: "price" }) }} />
                          </FormFeedback>
                        </FormGroup>
                      )}
                      {formInputs.receivedDate.enabled && (
                        <FormGroup>
                          <Label for="item-date-received">
                            <FormattedMessage id="date.received" />
                            <RequiredLabelIndicator required={formInputs.receivedDate.required} />
                          </Label>
                          <InventoryDatePicker
                            id="item-date-received"
                            selected={formInputs.receivedDate.value}
                            name={formInputs.receivedDate.fieldName}
                            required={formInputs.receivedDate.required}
                            onChange={(date) => changeDate(formInputs.receivedDate.fieldName, date)}
                            innerRef={formInputs.receivedDate.ref}
                            invalid={!formInputs.receivedDate.isValid}
                          />
                          <FormFeedback>
                            <FormattedMessage id="generic.is.required" values={{ subject: intl.formatMessage({ id: "date.received" }) }} />
                          </FormFeedback>
                        </FormGroup>
                      )}
                      {formInputs.grantNumber.enabled && (
                        <FormGroup>
                          <Label for="item-grant-number">
                            <FormattedMessage id="grant.number" />
                            <RequiredLabelIndicator required={formInputs.grantNumber.required} />
                          </Label>
                          <Input
                            id="item-grant-number"
                            type="text"
                            defaultValue={formInputs.grantNumber.value}
                            name={formInputs.grantNumber.fieldName}
                            required={formInputs.grantNumber.required}
                            onChange={(e) => changeValue(formInputs.grantNumber.fieldName, e)}
                            invalid={!formInputs.grantNumber.isValid}
                            innerRef={formInputs.grantNumber.ref}
                            maxLength={255}
                          />
                          <FormFeedback>
                            <FormattedMessage id="generic.is.required" values={{ subject: intl.formatMessage({ id: "grant.number" }) }} />
                          </FormFeedback>
                        </FormGroup>
                      )}
                      {formInputs.poNumber.enabled && (
                        <FormGroup>
                          <Label for="item-po-number">
                            <FormattedMessage id="po.number" />
                            <RequiredLabelIndicator required={formInputs.poNumber.required} />
                          </Label>
                          <Input
                            id="item-po-number"
                            type="text"
                            defaultValue={formInputs.poNumber.value}
                            name={formInputs.poNumber.fieldName}
                            required={formInputs.poNumber.required}
                            onChange={(e) => changeValue(formInputs.poNumber.fieldName, e)}
                            invalid={!formInputs.poNumber.isValid}
                            innerRef={formInputs.poNumber.ref}
                            maxLength={255}
                          />
                          <FormFeedback>
                            <FormattedMessage id="generic.is.required" values={{ subject: intl.formatMessage({ id: "po.number" }) }} />
                          </FormFeedback>
                        </FormGroup>
                      )}
                      {formInputs.expirationDate.enabled && (
                        <FormGroup>
                          <Label for="item-expiration">
                            <FormattedMessage id="expiration" />
                            <RequiredLabelIndicator required={formInputs.expirationDate.required} />
                          </Label>
                          <InventoryDatePicker
                            id="item-expiration"
                            selected={formInputs.expirationDate.value}
                            name={formInputs.expirationDate.fieldName}
                            required={formInputs.expirationDate.required}
                            onChange={(date) => changeDate(formInputs.expirationDate.fieldName, date)}
                            invalid={!formInputs.expirationDate.isValid}
                            innerRef={formInputs.expirationDate.ref}
                          />
                          <FormFeedback>
                            <FormattedMessage id="expiration.is.required" />
                          </FormFeedback>
                        </FormGroup>
                      )}
                      {formInputs.safetySheet.enabled && (
                        <FormGroup>
                          <Label for="item-safety-sheet">
                            <FormattedMessage id="safety.sheet" />
                            <RequiredLabelIndicator required={formInputs.safetySheet.required} />
                          </Label>
                          <Input
                            id="item-safety-sheet"
                            type="url"
                            defaultValue={formInputs.safetySheet.value}
                            name={formInputs.safetySheet.fieldName}
                            required={formInputs.safetySheet.required}
                            onChange={(e) => changeValue(formInputs.safetySheet.fieldName, e)}
                            invalid={!formInputs.safetySheet.isValid}
                            innerRef={formInputs.safetySheet.ref}
                          />
                          <FormFeedback>
                            <FormattedMessage id="safety.sheet.must.be.url" />
                          </FormFeedback>
                        </FormGroup>
                      )}
                      {formInputs.notes.enabled && (
                        <FormGroup>
                          <Label for="item-notes">
                            <FormattedMessage id="notes" />
                            <RequiredLabelIndicator required={formInputs.notes.required} />
                          </Label>
                          <Input
                            id="item-notes"
                            type="textarea"
                            defaultValue={formInputs.notes.value}
                            name={formInputs.notes.fieldName}
                            required={formInputs.notes.required}
                            onChange={(e) => changeValue(formInputs.notes.fieldName, e)}
                            invalid={!formInputs.notes.isValid}
                            innerRef={formInputs.notes.ref}
                          />
                        </FormGroup>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row className={"item-details-section-break-sm"}>
                    <Col>
                      <h2 className="inventory-heading">
                        <FormattedMessage id="notifications" />
                      </h2>
                    </Col>
                  </Row>
                  <Row id="inventory-notifications">
                    <Col>
                      {formInputs.expirationDate.enabled && (
                        <div>
                          <FormGroup>
                            <CheckboxLabel>
                              <CheckboxInput
                                id="inventory-item-edit-expiration-notification-checkbox"
                                name="notifyMe"
                                onChange={changeExpirationNotice}
                                uncheckedValue="0"
                                checked={notifications.sendExpirationNotification}
                                innerRef={expirationNoticeRef}
                              />

                              <span>
                                <FormattedMessage id="notify.expiration.label" />
                              </span>
                            </CheckboxLabel>
                          </FormGroup>
                        </div>
                      )}
                      <div>
                        {notifications.sendExpirationNotification && formInputs.expirationDate.enabled && (
                          <div>
                            <FormGroup>
                              <Label className="visually-hidden" for="inventory-item-edit-expiration-notification-days">
                                <FormattedMessage id="number.of.days" />
                              </Label>
                              <InputGroup>
                                <Input
                                  id="inventory-item-edit-expiration-notification-days"
                                  type="number"
                                  min={1}
                                  step={1}
                                  value={notifications.expirationNotificationDays === null ? "" : notifications.expirationNotificationDays}
                                  innerRef={expirationNoticeDaysRef}
                                  onChange={changeExpirationNotice}
                                />

                                <InputGroupText>
                                  <FormattedMessage id="notify.expiration.addon" />
                                </InputGroupText>
                              </InputGroup>
                            </FormGroup>
                            {getExpirationNotificationMessage()}
                          </div>
                        )}
                      </div>
                      <div>
                        <FormGroup>
                          <CheckboxLabel>
                            <CheckboxInput
                              id="inventory-item-edit-reorder-notification-checkbox"
                              onChange={changeQuantityNotice}
                              uncheckedValue="0"
                              checked={notifications.sendReorderNotification}
                              innerRef={quantityNoticeRef}
                            />
                            <span>
                              <FormattedMessage id="notify.amount.label" />
                            </span>
                          </CheckboxLabel>
                        </FormGroup>
                      </div>
                      <div>
                        {notifications.sendReorderNotification && (
                          <FormGroup>
                            <Label className="visually-hidden" for="inventory-item-edit-reorder-notification-amount">
                              <FormattedMessage id="amount" />
                            </Label>
                            <Label className="visually-hidden" for="inventory-item-edit-reorder-notification-unit">
                              <FormattedMessage id="units" />
                            </Label>
                            <InputGroup>
                              <Input
                                id="inventory-item-edit-reorder-notification-amount"
                                type="number"
                                min={1}
                                step={1}
                                innerRef={quantityNoticeAmountRef}
                                value={notifications.reorderNotificationQuantity === null ? "" : notifications.reorderNotificationQuantity}
                                onChange={changeQuantityNotice}
                              />
                              <Input
                                id="inventory-item-edit-reorder-notification-unit"
                                type="select"
                                innerRef={quantityNoticeUnitRef}
                                value={notifications.reorderNotificationUnit || ""}
                                onChange={changeQuantityNotice}
                              >
                                {getConvertibleUnits(`${formInputs.unitOfMeasure.value}`, true).map((u) => (
                                  <option key={`reorder-unit${u}`} value={u}>
                                    {u}
                                  </option>
                                ))}
                              </Input>
                            </InputGroup>
                          </FormGroup>
                        )}
                      </div>
                    </Col>
                  </Row>
                  {props.allowRelationships && (
                    <>
                      <Row className={"item-details-section-break"}>
                        <Col>
                          <h2 className="inventory-heading">
                            <FormattedMessage id="relationships" />
                          </h2>
                        </Col>
                      </Row>
                      <Row id="inventory-relationships">
                        <Col>
                          <AddInventoryItemRelationships
                            childItems={childItems}
                            parentItems={parentItems}
                            onParentsChanged={onParentsChanged}
                            onChildrenChanged={onChildrenChanged}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row className={"item-details-section-break"}>
                    <Col>
                      <h2 className="inventory-heading">
                        <FormattedMessage id="details" />
                      </h2>
                    </Col>
                  </Row>
                  <Row id="inventory-details">
                    <Col>
                      {customAttributes.length === 0 && (
                        <div>
                          <em>
                            <FormattedMessage id="no.custom.fields.note" />
                          </em>
                        </div>
                      )}
                      {customAttributes
                        .filter((i) => i.type === CustomAttributeType.CASNumber)
                        .map((i) => {
                          const currentInput = formInputs.dynamic[i.inputId];
                          return (
                            <CasSearchInput
                              key={`row${i.id}`}
                              id={i.id}
                              fieldName={currentInput.fieldName}
                              inputId={i.inputId}
                              values={getValues(currentInput)}
                              definition={i}
                              isValid={currentInput.isValid}
                              onChange={changeValue}
                              innerRef={currentInput.ref}
                              onCasSearched={onCasSearched}
                              isSearchEnabled={isCasSearchEnabled}
                              api={api}
                            />
                          );
                        })}
                      {customAttributes
                        .filter((i) => i.type !== CustomAttributeType.CASNumber)
                        .map((i) => {
                          const currentInput = formInputs.dynamic[i.inputId];
                          if (!currentInput) {
                            return <Fragment key={`row${i.id}`} />;
                          }
                          return (
                            <CustomAttributeInput
                              key={`row${i.id}`}
                              id={i.id}
                              fieldName={currentInput.fieldName}
                              inputId={i.inputId}
                              values={getValues(currentInput)}
                              type={i.type}
                              definition={i}
                              isValid={currentInput.isValid}
                              onChange={changeValue}
                              onCheckboxChange={onDynamicCheckboxChange}
                              onDateChange={changeDate}
                              innerRef={currentInput.ref}
                            />
                          );
                        })}
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="item-attachments-row item-details-section-break-sm" id="inventory-attachments">
                <Col>
                  <InventoryAttachmentUpload
                    filesAttached={filesAttached}
                    filesToAttach={filesToAttach}
                    onFilesDropped={onFilesDropped}
                    onRemoveAttachment={onRemoveAttachment}
                    onRemoveUpload={onRemoveUpload}
                  />
                </Col>
              </Row>
            </Col>
          </div>
          <div className="faux-modal-footer">
            <Button className="me-1" type="submit" id="add-inventory-trigger" color="primary">
              <FormattedMessage id={props.buttonTextId} />
            </Button>
            <CancelButton onClick={onClose} />
          </div>
        </div>
      </Form>
    </AuthorizedComponent>
  );
}

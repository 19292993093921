import React, { ReactElement } from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { Button, Modal } from "@labarchives/ui-design";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { CancelButton } from "../../components/CancelButton/CancelButton";

interface RelationshipRemovalWarningModalProps {
  isOpen: boolean;
  bodyTextId: string;
  buttonTextId: string;
  headerTextId: string;
  onToggle(): void;
  onRemove(): void;
}

export function RelationshipRemovalWarningModal(props: RelationshipRemovalWarningModalProps): ReactElement {
  const { bodyTextId, buttonTextId, headerTextId, isOpen, onToggle, onRemove } = props;

  function getBody(): ReactElement {
    return <FormattedHTMLMessage id={bodyTextId} />;
  }

  function handleRemove(): void {
    onRemove();
    onToggle();
  }

  return (
    <Modal isOpen={isOpen} toggle={onToggle} id={"inventory-relationship-remove-relationship-modal"}>
      <ModalHeader toggle={onToggle}>
        <FormattedMessage id={headerTextId} />
      </ModalHeader>
      <ModalBody>{getBody()}</ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={handleRemove} id={"inventory-relationship-remove-relationship-confirm-button"}>
          <FormattedMessage id={buttonTextId} />
        </Button>
        <CancelButton onClick={onToggle} />
      </ModalFooter>
    </Modal>
  );
}

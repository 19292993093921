/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from "react";
import { Tooltip } from "reactstrap";
import { Button } from "@labarchives/ui-design";
import { Link } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import { RolePermissions } from "@labarchives/inventory-shared/build/inventory";
import { AuthorizedComponent } from "../../components/Authentication/AuthorizedComponent";
import { userCanEditItemCheck } from "../../components/Authentication/permissionChecks/userCanEditItemCheck";
import { ApplicationPaths } from "../../app/ApplicationPaths";
import { UseNowModal } from "../UseNowModal/UseNowModal";
import copyToClipboard from "../../utils/copyToClipboard";
import { getItemAsText } from "../selectors";
import { ActionRowProps, useActionRow } from "./ActionRowHooks";

export function ActionRow(props: ActionRowProps): ReactElement {
  const {
    selectedCells,
    occupiedCells,
    isUpdatingUse,
    wasCopied,
    matrixRows,
    matrixCols,
    displayFormat,
    onUsageSaved,
    onSelectedCellsChanged,
    onHideUsage,
    onCopyTriggered,
    onShowUsage,
  } = useActionRow(props);

  const intl = useIntl();

  return (
    <>
      <div className="action-row">
        <div className="me-sm-2 me-1">
          <Button size="sm" outline onClick={() => onShowUsage()} id="action-row-use-now-button">
            <span className="d-none d-xl-block">
              <FormattedMessage id="use.now" />
            </span>
            <span className="d-xl-none">Use</span>
          </Button>
        </div>
        <div className="me-sm-2 me-1">
          <Button
            size="sm"
            outline
            id="action-row-copy-button"
            onClick={() => {
              copyToClipboard(getItemAsText(props.currentItemView, intl, props.currency));
              onCopyTriggered();
            }}
          >
            <span className="d-none d-xl-block">
              <FormattedMessage id="copy.to.clipboard" />
            </span>
            <span className="d-xl-none">Copy</span>
          </Button>
          <Tooltip placement="bottom" isOpen={wasCopied} target="action-row-copy-button">
            <FormattedMessage id="inventory.item.copied.to.clipboard" />
          </Tooltip>
        </div>
        <AuthorizedComponent requiredPermissions={[RolePermissions.InventoryAddCreatedItem]}>
          <div className="me-sm-2 me-1">
            <Button
              size="sm"
              outline
              tag={Link as any}
              {...{ to: ApplicationPaths.Inventory.Duplicate(props.currentItemView.itemId) }}
              id="action-row-duplicate-button"
            >
              <span className="d-none d-xl-block">
                <FormattedMessage id="duplicate.item" />
              </span>
              <span className="d-xl-none">Duplicate</span>
            </Button>
          </div>
        </AuthorizedComponent>
        <AuthorizedComponent requiredPermissions={[RolePermissions.OrdersRequestItem]}>
          <div className="me-sm-2 me-1">
            <Button
              size="sm"
              outline
              tag={Link as any}
              {...{ to: ApplicationPaths.Orders.ReorderItem(props.currentItemView.itemId) }}
              id="action-row-reorder-button"
            >
              <FormattedMessage id="request.reorder" />
            </Button>
          </div>
        </AuthorizedComponent>
        <AuthorizedComponent requiredPermissions={[]} additionalChecks={userCanEditItemCheck(props.currentItemView.ownerId)}>
          <div className="me-sm-2 me-0">
            <Button
              size="sm"
              outline
              tag={Link as any}
              {...{ to: ApplicationPaths.Inventory.Edit(props.currentItemView.itemId) }}
              id="action-row-edit-button"
            >
              <FormattedMessage id="edit" />
            </Button>
          </div>
        </AuthorizedComponent>
      </div>
      <UseNowModal
        isOpen={isUpdatingUse}
        onToggle={onHideUsage}
        defaultUsageAmount={props.defaultUsageAmount}
        defaultUsageUnit={props.defaultUsageUnit}
        displayFormat={displayFormat}
        matrixCols={matrixCols}
        matrixRows={matrixRows}
        selectedCells={selectedCells}
        occupiedCells={occupiedCells}
        onUsageSaved={onUsageSaved}
        onSelectedCellsChanged={onSelectedCellsChanged}
      />
    </>
  );
}

import {
  InventoryItem,
  InventorySearchResultSetItem,
  RolePermissions,
  SortDirection,
  StorageLocation,
} from "@labarchives/inventory-shared/build/inventory";
import { useEffect, useMemo, useState } from "react";
import { AuthenticationState } from "../components/Authentication/AuthenticationState";
import { InventoryApi } from "../api/InventoryApi";
import { StorageLocationView } from "./types/views";
import { getStorageLocationViewFromList } from "./selectors";
import { StorageState } from "./types/state";

export interface StorageBrowserHooks {
  isLocationFound: boolean;
  isInitialLoading: boolean;
  isLoadingFreezerBoxDetails: boolean;
  selectedBox: StorageLocation | undefined;
  locations: StorageLocationView;
  items: InventorySearchResultSetItem[];
  canAddItems: boolean;
  includesItemsNotVisible: boolean;
  defaultExpandedNodes: number[];

  onFreezerBoxSelected(freezerBoxId: number): void;

  onQuantityUsed(id: number, quantityUsed: number, unitUsed: string, storageCells: string[]): Promise<void>;

  onItemMoved(id: number, sourceCell: string, destinationCell: string): Promise<void>;

  onItemCopied(id: number, destinationCell: string): Promise<void>;
}

export function useStorageBrowser(
  locationId: number | undefined,
  storageState: StorageState,
  authState: AuthenticationState,
  api: InventoryApi,
): StorageBrowserHooks {
  const [selectedBox, setSelectedBox] = useState<StorageLocation | undefined>();
  const [isLoadingFreezerBoxDetails, setIsLoadingFreezerBoxDetails] = useState<boolean>(false);
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(locationId !== undefined);
  const [items, setItems] = useState<InventorySearchResultSetItem[]>([]);
  const [defaultExpandedNodes, setDefaultExpandedNodes] = useState<number[]>([]);
  const locationsWithFreezerBox = useMemo(() => getLocationsWithFreezerBox(), [storageState.isLoading]);

  function getLocationsWithFreezerBox(): StorageLocationView {
    const withFreezerBox: Set<number> = new Set<number>();
    const storageLocations = storageState.allIds.map((id) => storageState.byId[id]);
    const sortedLocations = [...storageLocations].sort((a, b) => a.name.localeCompare(b.name));

    const isFreezerBox = (location: StorageLocation): boolean => {
      return location.numberOfRows !== null && location.numberOfColumns !== null;
    };

    const addTree = (location: StorageLocation): void => {
      if (!withFreezerBox.has(location.id)) {
        withFreezerBox.add(location.id);
      }
      sortedLocations.filter((l) => location.parentId === l.id).forEach((l) => addTree(l));
    };

    const filterFreezerBoxes = (location: StorageLocation): void => {
      if (isFreezerBox(location)) {
        addTree(location);
      }

      const children = sortedLocations.filter((c) => c.parentId !== null && c.parentId === location.id);
      children.forEach((c) => filterFreezerBoxes(c));
    };

    sortedLocations.forEach((l) => filterFreezerBoxes(l));

    const locationsWithFreezerBoxes = sortedLocations.filter((s) => withFreezerBox.has(s.id));
    return getStorageLocationViewFromList(locationsWithFreezerBoxes);
  }

  function onFreezerBoxSelected(freezerBoxId: number): void {
    if (!locationsWithFreezerBox.locationList.some((l) => l.id === locationId && l.numberOfColumns !== null && l.numberOfRows !== null)) {
      return;
    }

    setIsLoadingFreezerBoxDetails(true);
    api
      .searchInventory({
        term: "",
        typeIds: [],
        includeOutOfStock: false,
        receivedStartDate: undefined,
        receivedEndDate: undefined,
        locationIds: [freezerBoxId],
        resultSetOptions: { pageNumber: 1, pageSize: 1000, sortField: "", sortDirection: SortDirection.DEFAULT },
      })
      .then((r) => {
        const selectedLocation = locationsWithFreezerBox.getLocation(freezerBoxId);
        if (isInitialLoading) {
          setDefaultExpandedNodes(
            selectedLocation ? locationsWithFreezerBox.getKeysInTree(selectedLocation.id).map((id) => Number.parseInt(id)) : [],
          );
        }
        setSelectedBox(selectedLocation);
        setItems(r.items);
        setIsLoadingFreezerBoxDetails(false);
        setIsInitialLoading(false);
        return r;
      })
      .catch((error) => {
        api.logError(error);
        setIsInitialLoading(false);
        setIsLoadingFreezerBoxDetails(false);
      });
  }

  async function onQuantityUsed(id: number, quantityUsed: number, unitUsed: string, storageCells: string[]): Promise<void> {
    if (locationId) {
      const updatedItem = await api.updateInventoryUsage(id, quantityUsed, unitUsed, storageCells);
      const found = items.find((i) => i.id === updatedItem.id)!;
      const copy: InventorySearchResultSetItem = {
        ...found,
        storageCells: updatedItem.storageCells,
        quantityAvailable: updatedItem.quantityAvailable,
        unit: updatedItem.unit,
      };
      const updatedItems = items.filter((i) => i.id !== found.id);
      updatedItems.push(copy);
      setItems(updatedItems);
    }
  }

  async function onItemMoved(id: number, sourceCell: string, destinationCell: string): Promise<void> {
    const existingItem = items.find((i) => i.id === id);
    if (locationId && existingItem) {
      const newCells = existingItem.storageCells.filter((c) => c !== sourceCell);
      newCells.push(destinationCell);
      const updatedItem = await api.updateInventoryStorageLocation(existingItem.id, locationId, newCells, existingItem.storageLocationNotes);

      const found = items.find((i) => i.id === updatedItem.id)!;
      const copy: InventorySearchResultSetItem = {
        ...found,
        storageCells: updatedItem.storageCells,
      };
      const updatedItems = items.filter((i) => i.id !== found.id);
      updatedItems.push(copy);
      setItems(updatedItems);
    }
  }

  async function onItemCopied(id: number, destinationCell: string): Promise<void> {
    const getItemAsSearchResult = (item: InventoryItem, copyFrom: InventorySearchResultSetItem): InventorySearchResultSetItem => {
      return {
        id: item.id,
        name: item.name,
        unit: item.unit,
        notes: item.notes,
        locationId: item.storageLocationId,
        storageCells: item.storageCells,
        quantityAvailable: item.quantityAvailable,
        storageLocationNotes: item.storageLocationNotes,
        typeId: item.typeId,
        description: item.description,
        vendorId: item.vendorId,
        safetySheetUrl: item.safetySheetUrl,
        price: item.price,
        poNumber: item.poNumber,
        ownerId: item.ownerId,
        lotNumber: item.lotNumber,
        grantNumber: item.grantNumber,
        expiration: item.expiration,
        dateReceived: item.dateReceived,
        customAttributes: item.customAttributes,
        catalogNumber: item.catalogNumber,
        accountId: copyFrom.accountId,
        typeColor: copyFrom.typeColor,
        vendorName: copyFrom.vendorName,
        typeName: copyFrom.typeName,
        storageCellRows: selectedBox!.numberOfRows,
        storageCellCols: selectedBox!.numberOfColumns,
        storageCellFormat: selectedBox!.freezerBoxDisplayFormat,
        itemId: item.itemId,
        reorderNotificationQuantity: item.reorderNotificationQuantity,
        reorderNotificationUnit: item.reorderNotificationUnit,
        childItemIds: item.childIds,
        parentItemIds: item.parentIds,
      };
    };

    const existingItem = items.find((i) => i.id === id);
    if (locationId && existingItem) {
      const originalItem = await api.getInventoryItem(id);
      const copy: InventoryItem = {
        ...originalItem,
        id: 0,
        history: [],
        attachments: [],
        name: `${originalItem.name} (copy)`,
        storageCells: [destinationCell],
      };

      const added = await api.addInventoryItem(copy, []);
      const updatedItems = [...items, getItemAsSearchResult(added, existingItem)];
      setItems(updatedItems);
    }
  }

  useEffect(() => {
    setIsInitialLoading(locationId !== undefined);
  }, []);

  useEffect(() => {
    if (locationId && !storageState.isLoading) {
      onFreezerBoxSelected(locationId);
    }
  }, [locationId, storageState.isLoading]);

  return {
    locations: locationsWithFreezerBox,
    defaultExpandedNodes,
    isLocationFound: locationId === undefined || selectedBox !== undefined,
    selectedBox,
    isInitialLoading: isInitialLoading || storageState.isLoading,
    isLoadingFreezerBoxDetails,
    items,
    canAddItems: authState.hasPermissions([RolePermissions.InventoryAddCreatedItem]),
    includesItemsNotVisible: items.some((i) => i.storageCells.length === 0),
    onFreezerBoxSelected,
    onQuantityUsed,
    onItemMoved,
    onItemCopied,
  };
}

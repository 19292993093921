import React, { ReactElement } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { InventorySearchResultSetItem } from "@labarchives/inventory-shared/build/inventory";
import { Link } from "react-router";
import { SiteAccountView } from "../../types/views";
import { ApplicationPaths } from "../../../app/ApplicationPaths";

export interface InventoryAdminSearchResultsRowProps {
  item: InventorySearchResultSetItem;
  accounts: Map<number, SiteAccountView>;
}

export function InventoryAdminSearchResultsRow(props: InventoryAdminSearchResultsRowProps): ReactElement {
  const intl = useIntl();
  const { item, accounts } = props;

  function displayAccountDetails(accountId: number): ReactElement {
    if (accounts.has(accountId)) {
      const account = accounts.get(accountId)!;
      return (
        <>
          <span>{account.name}</span> <br />
          <span>
            <div>
              <span className={"admin-search-results-column-lab-details-label"}>{intl.formatMessage({ id: "lab.building" })}</span>
              <span className={"admin-search-results-column-lab-details-value"}>{account.building || "N/A"}</span>
            </div>
            <div>
              <span className={"admin-search-results-column-lab-details-label"}>{intl.formatMessage({ id: "lab.floor" })}</span>
              <span className={"admin-search-results-column-lab-details-value"}>{account.floor || "N/A"}</span>
            </div>
            <div>
              <span className={"admin-search-results-column-lab-details-label"}>{intl.formatMessage({ id: "lab.room" })}</span>
              <span className={"admin-search-results-column-lab-details-value"}>{account.room || "N/A"}</span>
            </div>
          </span>
        </>
      );
    }

    return <span>&nbsp;</span>;
  }

  return (
    <tr className="inventory-search-result-item">
      <td className="inventory-search-result-name" data-label={intl.formatMessage({ id: "name" })}>
        <div className="table-to-card-label">
          <FormattedMessage id="name" />
        </div>
        <div className="table-to-card-content">
          <Link to={ApplicationPaths.Admin.Item(item.itemId, item.accountId)}>{item.name}</Link>
        </div>
      </td>
      <td className="inventory-search-result-lab" data-label={intl.formatMessage({ id: "lab.name" })}>
        <div className="table-to-card-label">
          <FormattedMessage id="lab.name" />
        </div>
        <div className="table-to-card-content search-result-lab-name">{displayAccountDetails(item.accountId)}</div>
      </td>
      <td className="inventory-search-result-type" data-label={intl.formatMessage({ id: "type" })}>
        <div className="table-to-card-label">
          <FormattedMessage id="type" />
        </div>
        <div className="table-to-card-content">{item.typeName}</div>
      </td>
      <td className="inventory-search-result-quantity" data-label={intl.formatMessage({ id: "quantity" })}>
        <div className="table-to-card-label">
          <FormattedMessage id="quantity" />
        </div>
        <div className="table-to-card-content">
          <FormattedNumber value={item.quantityAvailable} />
          <span className="inventory-search-result-unit">{item.unit}</span>
        </div>
      </td>
    </tr>
  );
}

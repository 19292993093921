import React, { ReactElement } from "react";
import { useIntl } from "react-intl";
import { Button } from "@labarchives/ui-design";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { InventoryItemIdentity } from "@labarchives/inventory-shared/build/inventory";
import { ApplicationPaths } from "../../app/ApplicationPaths";
import "./InventoryRelationshipItemList.scss";

interface InventoryRelationshipItemListProps {
  items: InventoryItemIdentity[];
  isEnabled: boolean;

  removeItem(item: InventoryItemIdentity): void;
}

export function InventoryRelationshipItemList(props: InventoryRelationshipItemListProps): ReactElement {
  const { items, isEnabled, removeItem } = props;
  const intl = useIntl();

  return (
    <ul className={"inventory-relationship-list"}>
      {items.map((item) => {
        const { itemId, itemName } = item;
        return (
          <li key={itemName} className={"inventory-relationship-item"} data-itemname={itemName}>
            <div>
              <a href={ApplicationPaths.Inventory.Item(itemId)} target={"_blank"} rel="noreferrer" className={"inventory-relationship-item-link"}>
                {itemName}
              </a>
            </div>
            {isEnabled && (
              <Button
                type={"button"}
                color={"link"}
                onClick={() => removeItem(item)}
                aria-label={intl.formatMessage({ id: "delete" })}
                id={`remove-relationship-${itemId}`}
                className={"inventory-relationship-list-remove-button"}
              >
                <FontAwesomeIcon icon={faTrash} className="button-icon" />
              </Button>
            )}
          </li>
        );
      })}
    </ul>
  );
}

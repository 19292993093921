import React, { ReactElement } from "react";
import { FormGroup, Label } from "reactstrap";
import { CustomAttributeType } from "@labarchives/inventory-shared/build/inventory";
import { InventorySearchAutoSuggest } from "../autosuggest/InventorySearchAutoSuggest";
import { InventoryApi } from "../../../api/InventoryApi";
import { InventoryAdvancedSearchTypeComponentProps } from "./InventoryAdvancedSearchTypeComponentProps";
import { InventoryAdvanceSearchMatchDropdown } from "./InventoryAdvanceSearchMatchDropdown";

interface InventoryAdvancedSearchItemAutoSuggestProps extends InventoryAdvancedSearchTypeComponentProps {
  api: InventoryApi;
}

export function InventoryAdvancedSearchItemAutoSuggest(props: InventoryAdvancedSearchItemAutoSuggestProps): ReactElement {
  return (
    <>
      <InventoryAdvanceSearchMatchDropdown
        index={props.index}
        matchType={props.matchType}
        onMatchTypeChanged={props.onMatchTypeChanged}
        attributeType={CustomAttributeType.ItemAutoSuggest}
      />
      <div className={"criteria-value"}>
        <FormGroup>
          <Label className="visually-hidden" for={`value${props.index}`}>
            Search Value
          </Label>
          <InventorySearchAutoSuggest
            id={`value${props.index}`}
            onSearchTermChange={(t) => props.onValueChanged(t)}
            api={props.api}
            searchTerm={props.defaultValue}
            onClear={() => props.onValueChanged("")}
            resultThreshold={1}
            inputControlClassName={"form-control-sm"}
          />
        </FormGroup>
      </div>
    </>
  );
}

import React, { ReactElement, useContext, useEffect, useState } from "react";
import { Col, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Card, CardBody, Modal } from "@labarchives/ui-design";
import { faPlusCircle, faTrash } from "@fortawesome/pro-light-svg-icons";
import { faMinusCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InventoryItemIdentity } from "@labarchives/inventory-shared/build/inventory";
import { CancelButton } from "../../components/CancelButton/CancelButton";
import { InventoryApiClient } from "../../api";
import { ApplicationPaths } from "../../app/ApplicationPaths";
import { Loading } from "../../components";
import { AuthenticationContext } from "../../components/Authentication/AuthenticationContext";
import { InventorySearchBox } from "../search/InventorySearchBox";
import { useInventoryItemRelationshipModalHooks } from "./InventoryItemRelationshipModalHooks";
import "./InventoryItemRelationshipModal.scss";
import { RelationshipRemovalWarningModal } from "./RelationshipRemovalWarningModal";

interface InventoryItemRelationshipModalProps {
  isOpen: boolean;
  saveTextId: string;
  headerTextId: string;
  existingRelationships: InventoryItemIdentity[];
  itemId: number | undefined;

  onToggle(): void;

  submitRelationship(items: InventoryItemIdentity[]): void;
}

export function InventoryItemRelationshipModal(props: InventoryItemRelationshipModalProps): ReactElement {
  const api = new InventoryApiClient();
  const authState = useContext(AuthenticationContext);
  const intl = useIntl();
  const { isOpen, onToggle, headerTextId, saveTextId, existingRelationships, itemId, submitRelationship } = props;
  const {
    accountName,
    searchTerm,
    isSearching,
    isAdvancedSearchBoxShown,
    searchResults,
    advancedSearchCriteria,
    selectedItems,
    onAdvancedSearchToggle,
    onSearchTermChange,
    onModalOpened,
    onClearSearchTerm,
    onSearchOnNewTerm,
    onAdvancedSearch,
    onItemToggled,
    isItemSelected,
    isExistingItem,
    onAllItemsAdded,
    onAllItemsRemoved,
    onRelationshipSubmit,
  } = useInventoryItemRelationshipModalHooks({ api, authState, existingRelationships, itemId, submitRelationship });

  function getItemIcon(isSelected: boolean): ReactElement {
    if (isSelected) {
      return <FontAwesomeIcon icon={faMinusCircle} />;
    }
    return <FontAwesomeIcon icon={faPlusCircle} />;
  }

  function getItemTitle(isSelected: boolean): string {
    if (isSelected) {
      return intl.formatMessage({ id: "remove" });
    }
    return intl.formatMessage({ id: "add.item" });
  }

  useEffect(() => {
    onModalOpened();
  }, [isOpen]);

  const [isRemoveAllModalOpen, setIsRemoveAllModalOpen] = useState(false);

  function toggleRemoveAllModal(): void {
    setIsRemoveAllModalOpen(!isRemoveAllModalOpen);
  }

  return (
    <Modal size={"xl"} isOpen={isOpen} className="add-relationship-modal" toggle={onToggle} scrollable={true}>
      <ModalHeader toggle={onToggle}>
        <FormattedMessage id={headerTextId} />
      </ModalHeader>
      <ModalBody className={"add-relationship-modal-body"}>
        {accountName && (
          <div className={"inventory-search-lab-name"} title={accountName}>
            {accountName}
          </div>
        )}
        <Row>
          <Col lg={8}>
            <Card>
              <CardBody>
                <InventorySearchBox
                  accountName={undefined}
                  isAdvancedSearchBoxShown={isAdvancedSearchBoxShown}
                  advancedSearchCriteria={advancedSearchCriteria}
                  inventoryTypeViews={[]}
                  api={api}
                  filters={undefined}
                  searchTerm={searchTerm}
                  onAdvancedSearchToggle={onAdvancedSearchToggle}
                  onAdvancedSearch={onAdvancedSearch}
                  onSearchOnNewTerm={onSearchOnNewTerm}
                  onSearchTermChange={onSearchTermChange}
                  onClearSearchTerm={onClearSearchTerm}
                  id={"inventory-relationship-item-search"}
                />
                <div className={"mt-3"}>
                  {isSearching && <Loading messageId={"searching"} />}
                  {searchResults && (
                    <>
                      <Table striped={true}>
                        <thead>
                          <tr>
                            <th className={"add-relationship-search-results-name-column"}>
                              <FormattedMessage id={"name"} />
                            </th>
                            <th className={"add-relationship-search-results-checkbox-column"}>
                              <Button type={"button"} color={"link"} onClick={() => onAllItemsAdded()}>
                                <FormattedMessage id={"add.all"} />
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {searchResults.items.map((item) => (
                            <tr key={item.id}>
                              <td>
                                <a href={ApplicationPaths.Inventory.Item(item.id)} target={"_blank"} rel="noreferrer">
                                  {item.name}
                                </a>
                              </td>
                              <td className={"add-relationship-search-results-checkbox-column"}>
                                {isExistingItem(item.id) && (
                                  <>
                                    <span className={"add-relationship-search-results-already-added"}>
                                      <FormattedMessage id={"relationships.already.added"} />
                                    </span>
                                  </>
                                )}
                                {!isExistingItem(item.id) && (
                                  <Button
                                    type={"button"}
                                    color={"link"}
                                    onClick={() => onItemToggled(item.id, item.name)}
                                    title={getItemTitle(isItemSelected(item.id))}
                                    id={`add-related-item-${item.itemId}`}
                                  >
                                    {getItemIcon(isItemSelected(item.id))}
                                  </Button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4} className={"mt-3 mt-lg-0"}>
            <div className={"add-relationship-selected-items-header"}>
              <FormattedMessage id="items.selected" values={{ itemCount: selectedItems.length }} />
              {selectedItems.length > 0 && (
                <Button type={"button"} color={"link"} onClick={() => toggleRemoveAllModal()} title={intl.formatMessage({ id: "remove.all" })}>
                  {intl.formatMessage({ id: "remove.all" })}
                </Button>
              )}
            </div>
            <div className={"add-relationship-selected-item-list"}>
              {selectedItems.map((item) => (
                <div key={item.itemId} className={"add-relationship-selected-item"}>
                  <a href={ApplicationPaths.Inventory.Item(item.itemId)} target={"_blank"} rel="noreferrer">
                    {item.itemName}
                  </a>
                  <Button
                    type={"button"}
                    color={"link"}
                    onClick={() => onItemToggled(item.itemId, item.itemName)}
                    title={intl.formatMessage({ id: "delete" })}
                  >
                    <FontAwesomeIcon icon={faTrash} className="button-icon" />
                  </Button>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <div>
          <Button
            disabled={selectedItems.length === 0}
            color={"secondary"}
            type="button"
            className={"me-1"}
            onClick={() => {
              onRelationshipSubmit();
              onToggle();
            }}
            id={"confirm-add-relationships"}
          >
            <FormattedMessage id={saveTextId} />
          </Button>
          <CancelButton onClick={onToggle} />
        </div>
      </ModalFooter>
      <RelationshipRemovalWarningModal
        isOpen={isRemoveAllModalOpen}
        onToggle={toggleRemoveAllModal}
        onRemove={onAllItemsRemoved}
        bodyTextId={"remove.all.selected.items.warning"}
        buttonTextId={"remove.all"}
        headerTextId={"remove.all"}
      />
    </Modal>
  );
}

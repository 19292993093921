import { useEffect, useState } from "react";
import { AuthenticationState } from "../../components/Authentication/AuthenticationState";
import { isNotFoundError } from "../../utils/errorHelpers";
import { InventoryItemView } from "../../inventory/types/views";
import { getInventoryItemView } from "../../inventory/selectors";
import { StorageLocationView } from "../../storage/types/views";
import { getStorageLocationViewFromList } from "../../storage/selectors";
import { getAdminInventoryTypesState, getAdminStorageState, getAdminVendorsState } from "../types/selectors";
import * as utils from "../../utils";
import { InventoryApi } from "../../api/InventoryApi";
import { InventoryItemRelationshipItem } from "../../inventory/relationships/InventoryItemRelationshipModalHooks";

export interface AdminViewInventoryHooks {
  storageLocationView: StorageLocationView | undefined;
  isAllowedToView: boolean;
  isLoading: boolean;
  currentItemView: InventoryItemView | undefined;
  onDownloadAttachment(attachmentId: number): void;
  onRelationshipsAdded(id: number | string, { parentIds, childIds }: { parentIds: number[]; childIds: [] }): void;
  onChildRelationshipDeleted(id: number, name: string, childItem: InventoryItemRelationshipItem): void;
  onParentRelationshipDeleted(id: number, name: string, parentItem: InventoryItemRelationshipItem): void;
}

export interface AdminViewInventoryHooksProps {
  inventoryId: number | string;
  accountId: number;
  authState: AuthenticationState;
  api: InventoryApi;
}

export function useAdminViewInventory(props: AdminViewInventoryHooksProps): AdminViewInventoryHooks {
  const [isLoading, setIsLoading] = useState(true);
  const [currentItemView, setCurrentItemView] = useState<InventoryItemView | undefined>();
  const [storageLocationsView, setStorageLocationsView] = useState<StorageLocationView | undefined>();
  const { api, inventoryId, accountId, authState } = props;

  const onDownloadAttachment = async (attachmentId: number): Promise<void> => {
    await api.adminGetAttachment(inventoryId, attachmentId, accountId).then((attachment) => utils.saveAs(attachment.data, attachment.filename));
  };

  const onRelationshipsAdded = (id: number | string, { parentIds, childIds }: { parentIds: number[]; childIds: number[] }): void => {
    void api.addInventoryRelationshipItems(id, { parentIds, childIds }).then((inventory) => {
      return inventory;
    });
  };

  const onChildRelationshipDeleted = (id: number, name: string, childItem: InventoryItemRelationshipItem): void => {
    void api.deleteChildRelationship(id, name, childItem).then((inventory) => {
      return inventory;
    });
  };

  const onParentRelationshipDeleted = (id: number, name: string, parentItem: InventoryItemRelationshipItem): void => {
    void api.deleteParentRelationship(id, name, parentItem).then((inventory) => {
      return inventory;
    });
  };

  async function loadItem(): Promise<InventoryItemView | undefined> {
    try {
      const { item, storageLocations, type, vendor } = await api.adminGetInventoryItem(inventoryId, accountId);
      setStorageLocationsView(getStorageLocationViewFromList(storageLocations));
      return getInventoryItemView(item, getAdminInventoryTypesState(type), getAdminStorageState(storageLocations), getAdminVendorsState(vendor));
    } catch (error) {
      if (isNotFoundError(error)) {
        setIsLoading(false);
        return undefined;
      }
      throw error;
    }
  }

  useEffect(() => {
    if (!authState.getUser()?.isSiteAdmin) {
      return;
    }
    loadItem().then((item) => {
      setCurrentItemView(item);
      setIsLoading(false);
      return item;
    });
  }, [inventoryId]);

  return {
    isAllowedToView: authState.getUser()?.isSiteAdmin || false,
    isLoading,
    currentItemView,
    storageLocationView: storageLocationsView,
    onDownloadAttachment,
    onRelationshipsAdded,
    onChildRelationshipDeleted,
    onParentRelationshipDeleted,
  };
}

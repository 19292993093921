import { useState } from "react";
import { AdvancedSearchCriteria, InventorySearchResultSet, SortDirection } from "@labarchives/inventory-shared/build/inventory";
import { InventoryItemIdentity } from "@labarchives/inventory-shared/build/inventory";
import { InventoryApi } from "../../api/InventoryApi";
import { AuthenticationState } from "../../components/Authentication/AuthenticationState";

export interface InventoryItemRelationshipItem {
  itemId: number;
  itemName: string;
}

export interface InventoryItemRelationshipModalHooks {
  existingRelationships: InventoryItemIdentity[];
  selectedItems: InventoryItemIdentity[];
  searchResults: InventorySearchResultSet | undefined;
  isAdvancedSearchBoxShown: boolean;
  searchTerm: string;
  accountName: string | undefined;
  isSearching: boolean;
  advancedSearchCriteria: AdvancedSearchCriteria[];

  onSearchTermChange(term: string): void;

  onModalOpened(): void;

  onClearSearchTerm(): void;

  onAdvancedSearchToggle(): void;

  onSearchOnNewTerm(): void;

  onAdvancedSearch(criteria: AdvancedSearchCriteria[]): void;

  onItemToggled(itemId: number, itemName: string): void;

  isItemSelected(itemId: number): boolean;

  isExistingItem(itemId: number): boolean;

  onAllItemsAdded(): void;

  onAllItemsRemoved(): void;

  onRelationshipSubmit(): void;
}

export interface InventoryItemRelationshipModalProps {
  api: InventoryApi;
  authState: AuthenticationState;
  existingRelationships: InventoryItemIdentity[];
  itemId: number | undefined;
  submitRelationship: (items: InventoryItemIdentity[]) => void;
}

export function useInventoryItemRelationshipModalHooks(props: InventoryItemRelationshipModalProps): InventoryItemRelationshipModalHooks {
  const { api, authState, submitRelationship, existingRelationships } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [isAdvancedSearchBoxShown, setIsAdvancedSearchBoxShown] = useState(false);
  const [searchResults, setSearchResults] = useState<InventorySearchResultSet>();
  const [isSearching, setIsSearching] = useState(false);
  const [advancedSearchCriteria, setAdvancedSearchCriteria] = useState<AdvancedSearchCriteria[]>([]);
  const [selectedItems, setSelectedItems] = useState<InventoryItemIdentity[]>([]);

  function onSearchTermChange(term: string): void {
    setSearchTerm(term);
  }

  function onModalOpened(): void {
    onClearSearchTerm();
    setSelectedItems([]);
  }

  function onClearSearchTerm(): void {
    setSearchTerm("");
    setSearchResults(undefined);
  }

  function onAdvancedSearchToggle(): void {
    setIsAdvancedSearchBoxShown(!isAdvancedSearchBoxShown);
  }

  function onItemToggled(itemId: number, itemName: string): void {
    if (isItemSelected(itemId)) {
      setSelectedItems(selectedItems.filter((item) => item.itemId !== itemId));
    } else {
      setSelectedItems([...selectedItems, { itemId, itemName }]);
    }
  }

  function onAllItemsAdded(): void {
    if (!searchResults) {
      return;
    }

    const allSelectedItems: InventoryItemIdentity[] = searchResults.items.map((item) => {
      return { itemId: item.id, itemName: item.name };
    });

    setSelectedItems(allSelectedItems);
  }

  function onAllItemsRemoved(): void {
    setSelectedItems([]);
  }

  function isItemSelected(itemId: number): boolean {
    return selectedItems.some((item) => item.itemId === itemId);
  }

  function isExistingItem(itemId: number): boolean {
    return props.itemId === itemId || existingRelationships.some((item) => item.itemId === itemId);
  }

  function onAdvancedSearch(criteria: AdvancedSearchCriteria[]): void {
    if (criteria.length === 0) {
      setSearchResults(undefined);
      setIsAdvancedSearchBoxShown(false);
      setAdvancedSearchCriteria(criteria);
      return;
    }

    setIsSearching(true);
    setAdvancedSearchCriteria(criteria);
    api
      .searchInventoryAdvanced({
        criteria,
        resultSetOptions: {
          sortDirection: SortDirection.ASCENDING,
          pageSize: 1000,
          sortField: "name",
          pageNumber: 1,
        },
        receivedStartDate: undefined,
        receivedEndDate: undefined,
        typeIds: [],
        includeMissingLocationId: undefined,
        locationIds: [],
        includeOutOfStock: true,
      })
      .then((results) => {
        setIsSearching(false);
        setSearchResults(results);
        setIsAdvancedSearchBoxShown(false);
        return results;
      });
  }

  function onSearchOnNewTerm(): void {
    if (searchTerm.length === 0) {
      setSearchResults(undefined);
      return;
    }

    setIsSearching(true);
    api
      .searchInventory({
        term: searchTerm,
        resultSetOptions: {
          sortDirection: SortDirection.ASCENDING,
          pageSize: 1000,
          sortField: "name",
          pageNumber: 1,
        },
        receivedStartDate: undefined,
        receivedEndDate: undefined,
        typeIds: [],
        includeMissingLocationId: undefined,
        locationIds: [],
        includeOutOfStock: true,
      })
      .then((results) => {
        setIsSearching(false);
        setSearchResults(results);
        return results;
      });
  }

  function getAccountName(): string | undefined {
    const user = authState.getUser();
    if (!user || user.accounts.length === 1) {
      return undefined;
    }

    return user.activeAccount.name;
  }

  function onRelationshipSubmit(): void {
    submitRelationship(selectedItems);
  }

  return {
    onModalOpened,
    onClearSearchTerm,
    onSearchTermChange,
    onAdvancedSearchToggle,
    onSearchOnNewTerm,
    onAdvancedSearch,
    isAdvancedSearchBoxShown,
    searchResults,
    isSearching,
    advancedSearchCriteria,
    selectedItems,
    onItemToggled,
    isItemSelected,
    onAllItemsAdded,
    onAllItemsRemoved,
    onRelationshipSubmit,
    isExistingItem,
    searchTerm,
    accountName: getAccountName(),
    existingRelationships: existingRelationships,
  };
}

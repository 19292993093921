import {
  CustomAttributeType,
  FreezerBoxDisplayFormat,
  InventoryCustomAttributeValue,
  InventoryHistory,
  InventoryItem,
  InventorySearchCriteria,
  InventorySearchResultSetItem,
  InventoryType,
  InventoryStandardField,
  InventoryStandardFieldConfiguration,
} from "@labarchives/inventory-shared/build/inventory";
import moment from "moment";
import { IntlShape } from "react-intl";
import * as clock from "@labarchives/inventory-shared/build/util/clock";
import { bytesToString } from "../utils";
import { StorageState } from "../storage/types/state";
import { InventoryTypesState } from "../inventorytypes/types/state";
import { VendorState } from "../vendors/types/state";
import { getFormattedFreezerBoxCells } from "../storage/selectors";
import { ApplicationPaths } from "../app/ApplicationPaths";
import { InventoryFilterView, InventoryItemDetailsView, InventoryItemHistoryView, InventoryItemView } from "./types/views";

function getStorageLocationName(state: StorageState, storageLocationId: number | null): string {
  if (!storageLocationId) {
    return "";
  }

  if (!(storageLocationId in state.byId)) {
    return "";
  }

  const storageLocation = state.byId[storageLocationId];
  let locationName = storageLocation.name;
  if (storageLocation.parentId) {
    locationName = `${getStorageLocationName(state, storageLocation.parentId)} > ${locationName}`;
  }

  return locationName;
}

function buildItemDetailViews(type?: InventoryType, attributes?: { customAttributeId: number; values: string[] }[]): InventoryItemDetailsView[] {
  if (!type) {
    return [];
  }
  return [...type.customAttributes]
    .sort((a1, a2) => a1.label.localeCompare(a2.label))
    .map((typeAttribute) => {
      let inventoryCustomAttributeValues: InventoryCustomAttributeValue[] = [];
      if (attributes) {
        inventoryCustomAttributeValues = attributes.filter((itemAttribute) => itemAttribute.customAttributeId === typeAttribute.id);
      }

      return {
        id: typeAttribute.id,
        label: typeAttribute.label,
        values: inventoryCustomAttributeValues.length === 1 ? inventoryCustomAttributeValues[0].values : [],
        type: typeAttribute.type,
      };
    });
}

function buildItemHistoryViews(item: InventoryItem): InventoryItemHistoryView[] {
  return [...item.history]
    .sort((item1: InventoryHistory, item2: InventoryHistory) => item2.activityDate.getTime() - item1.activityDate.getTime())
    .map((i) => {
      return {
        activityDate: i.activityDate,
        userName: i.userName,
        description: i.description,
        userId: i.userId,
        id: i.id,
      };
    });
}

export function getInventoryItemView(
  item: InventoryItem | undefined,
  inventoryTypesState: InventoryTypesState,
  storageState: StorageState,
  vendorState: VendorState,
): InventoryItemView {
  if (!item) {
    return getDefaultInventoryItemView(inventoryTypesState);
  }

  const type = inventoryTypesState.byId[item.typeId];
  const vendor = item.vendorId ? vendorState.byId[item.vendorId] : undefined;
  const storageLocation = item.storageLocationId ? storageState.byId[item.storageLocationId] : undefined;

  return {
    id: item.id,
    itemId: item.itemId,
    name: item.name,
    quantityAvailable: item.quantityAvailable,
    unitOfMeasure: item.unit,
    typeName: type ? type.name : "",
    typeColor: type ? type.color : null,
    typeId: item.typeId,
    expirationDate: item.expiration,
    description: item.description,
    storageLocationId: item.storageLocationId,
    storageLocationDescription: getStorageLocationName(storageState, item.storageLocationId),
    storageLocationNotes: item.storageLocationNotes,
    storageLocationCols: storageLocation?.numberOfColumns || null,
    storageLocationRows: storageLocation?.numberOfRows || null,
    storageLocationFreezerBoxFormat: storageLocation?.freezerBoxDisplayFormat || null,
    notes: item.notes,
    storageCells: item.storageCells,
    attributes: buildItemDetailViews(type, item.customAttributes),
    catalogNumber: item.catalogNumber,
    history: buildItemHistoryViews(item),
    dateReceived: item.dateReceived,
    grantNumber: item.grantNumber,
    lotNumber: item.lotNumber,
    poNumber: item.poNumber,
    vendorId: item.vendorId,
    vendorName: vendor ? vendor.name : "",
    price: item.price,
    safetySheetUrl: item.safetySheetUrl,
    attachments: [...item.attachments]
      .sort((a1, a2) => a1.id - a2.id)
      .map((a) => {
        return {
          name: a.name,
          url: `/inventory/${item.id}/attachments/${a.id}`,
          size: bytesToString(a.sizeInBytes),
          sizeInBytes: a.sizeInBytes,
          id: a.id,
        };
      }),
    reorderNotificationQuantity: item.reorderNotificationQuantity,
    expirationNotificationDays: item.expirationNotificationDays,
    reorderNotificationUnit: item.reorderNotificationUnit,
    sendExpirationNotification: item.sendExpirationNotification,
    sendReorderNotification: item.sendReorderNotification,
    ownerId: item.ownerId,
    links: ([...item.links] || [])
      .sort((l1, l2) => l1.dateAdded.getTime() - l2.dateAdded.getTime())
      .map((l) => {
        return {
          href: l.href,
          title: l.title,
          userName: l.userName,
          dateAdded: l.dateAdded,
        };
      }),
    inventoryStandardFieldConfiguration: type.standardFieldConfiguration ?? [],
    parentIds: item.parentIds,
    childIds: item.childIds,
  };
}

export function getInventoryItemViewCopy(
  inventoryItem: InventoryItem,
  inventoryTypesState: InventoryTypesState,
  storageState: StorageState,
  vendorState: VendorState,
): InventoryItemView {
  const item = getInventoryItemView(inventoryItem, inventoryTypesState, storageState, vendorState);
  if (!item) {
    return getDefaultInventoryItemView(inventoryTypesState);
  }

  item.name = `${item.name} (copy)`;
  item.id = 0;
  item.storageCells = [];
  item.history = [];
  item.attachments = [];

  return item;
}

export function getDefaultInventoryItemView(inventoryTypesState: InventoryTypesState): InventoryItemView {
  let defaultInventoryType: InventoryType = {
    id: 0,
    name: "",
    customAttributes: [],
    isDefault: false,
    isLocked: false,
    color: null,
    standardFieldConfiguration: [],
  };
  inventoryTypesState.allIds.forEach((id) => {
    if (inventoryTypesState.byId[id].isDefault) {
      defaultInventoryType = inventoryTypesState.byId[id];
    }
  });

  return {
    id: 0,
    name: "",
    quantityAvailable: 1,
    unitOfMeasure: "",
    typeName: defaultInventoryType.name,
    typeColor: null,
    typeId: defaultInventoryType.id,
    expirationDate: null,
    description: "",
    storageLocationId: null,
    storageLocationDescription: "",
    storageLocationNotes: "",
    storageLocationFreezerBoxFormat: null,
    storageLocationRows: null,
    storageLocationCols: null,
    notes: "",
    storageCells: [],
    attributes: buildItemDetailViews(defaultInventoryType),
    catalogNumber: "",
    history: [],
    poNumber: "",
    lotNumber: "",
    grantNumber: "",
    dateReceived: null,
    vendorId: null,
    vendorName: "",
    attachments: [],
    safetySheetUrl: "",
    sendReorderNotification: false,
    sendExpirationNotification: false,
    reorderNotificationUnit: null,
    expirationNotificationDays: null,
    reorderNotificationQuantity: null,
    price: null,
    ownerId: null,
    links: [],
    itemId: "",
    inventoryStandardFieldConfiguration: defaultInventoryType.standardFieldConfiguration ?? [],
    parentIds: [],
    childIds: [],
  };
}

export function getInventoryItemViewForNewType(
  itemView: InventoryItemView,
  typeId: number,
  inventoryTypesState: InventoryTypesState,
): InventoryItemView {
  const type = inventoryTypesState.byId[typeId];
  return {
    ...itemView,
    typeId: typeId,
    attributes: buildItemDetailViews(
      type,
      itemView.attributes.map((a) => {
        return { customAttributeId: a.id, values: a.values };
      }),
    ),
    inventoryStandardFieldConfiguration: type.standardFieldConfiguration || [],
  };
}

export const getInventoryFilterView = (criteria: InventorySearchCriteria): InventoryFilterView => {
  return {
    resultSetOptions: criteria.resultSetOptions,
    typeIds: criteria.typeIds,
    term: criteria.term,
    locationIds: criteria.locationIds,
    receivedEndDate: criteria.receivedEndDate,
    receivedStartDate: criteria.receivedStartDate,
    includeOutOfStock: criteria.includeOutOfStock,
    includeMissingLocationId: criteria.includeMissingLocationId,
  };
};

export function getInventoryItemViewFromSearch(
  i: InventorySearchResultSetItem,
  inventoryTypesState: InventoryTypesState,
  storageState: StorageState,
  vendorState: VendorState,
): InventoryItemView {
  const item: InventoryItem = {
    id: i.id,
    name: i.name,
    quantityAvailable: i.quantityAvailable,
    attachments: [],
    history: [],
    safetySheetUrl: i.safetySheetUrl,
    catalogNumber: i.catalogNumber,
    price: i.price,
    dateReceived: i.dateReceived,
    grantNumber: i.grantNumber,
    lotNumber: i.lotNumber,
    poNumber: i.poNumber,
    vendorId: i.vendorId,
    description: i.description,
    storageLocationId: i.locationId,
    notes: i.notes,
    storageCells: i.storageCells,
    typeId: i.typeId,
    ownerId: i.ownerId,
    dateOrdered: null,
    expirationNotificationDays: null,
    reorderNotificationQuantity: null,
    reorderNotificationUnit: null,
    expiration: i.expiration,
    sendExpirationNotification: false,
    sendReorderNotification: false,
    storageLocationNotes: i.storageLocationNotes,
    unit: i.unit,
    customAttributes: i.customAttributes,
    links: [],
    itemId: i.itemId,
    childIds: [],
    parentIds: [],
  };

  return getInventoryItemView(item, inventoryTypesState, storageState, vendorState);
}

export function getItemAsText(item: InventoryItemView, intl: IntlShape, currency: string): string {
  const link = ApplicationPaths.Inventory.GetFullUrl(item.itemId);
  const attributes = item.attributes.map((i) => {
    if (i.values.length === 0) {
      return "";
    }
    let attribute = `${i.label}: `;
    attribute +=
      i.type === CustomAttributeType.Date && i.values.length === 1
        ? intl.formatDate(moment(i.values[0], clock.STANDARD_MOMENT_DATE_FORMAT).toDate())
        : i.values.join(", ");
    return `${attribute}<br/>`;
  });

  const getDate = (date: Date | null): string => {
    if (date) {
      return intl.formatDate(date);
    }
    return "";
  };

  const toCopy = (label: string, value: string): string => {
    if (value && value !== "") {
      return `${label}: ${value}<br/>`;
    }
    return "";
  };

  const name = toCopy("Name", `${item.name}`);
  const type = toCopy("Type", `${item.typeName}`);
  const quantity = toCopy("Quantity", `${item.quantityAvailable} ${item.unitOfMeasure}`);

  const storageCells = getFormattedFreezerBoxCells(
    item.storageCells,
    item.storageLocationFreezerBoxFormat || FreezerBoxDisplayFormat.Default,
    item.storageLocationRows || 0,
    item.storageLocationCols || 0,
  );
  const location = toCopy("Location", `${item.storageLocationDescription} ${storageCells} ${item.storageLocationNotes}`);
  const description = toCopy("Description", `${item.description}`);
  const vendor = toCopy("Vendor", `${item.vendorName}`);
  const catalog = toCopy("Catalog", `${item.catalogNumber}`);
  const lotNumber = toCopy("Lot #", `${item.lotNumber}`);
  const price = toCopy("Price", `${item.price ? intl.formatNumber(item.price, { style: "currency", currency }) : ""}`);
  const dateReceived = toCopy("Date Received", `${getDate(item.dateReceived)}`);
  const poNumber = toCopy("PO #", `${item.poNumber}`);
  const expirationDate = toCopy("Expiration", `${getDate(item.expirationDate)}`);
  const safetySheet = toCopy("Safety Sheet", `${item.safetySheetUrl}`);
  const notes = toCopy("Notes", `${item.notes}`);
  const attributeList = attributes.join("\n");
  const itemId = toCopy("Id", item.itemId);

  const itemAsText = `<div><a href="${link}">${link}</a><br/>
${name}
${itemId}
${type}
${quantity}
${location}
${description}
${vendor}
${catalog}
${lotNumber}
${price}
${dateReceived}
${poNumber}
${expirationDate}
${safetySheet}
${notes}
${attributeList}
</div>`;

  return itemAsText.replace(/^\s*[\n\r]/gm, "");
}

export function isStandardFieldEnabled(field: InventoryStandardField, config: InventoryStandardFieldConfiguration[]): boolean {
  if ([InventoryStandardField.Name, InventoryStandardField.Quantity, InventoryStandardField.Location].includes(field)) {
    return true;
  }

  const configField = config.find((c) => c.field === field);
  return configField === undefined || configField.displayed;
}

export function isStandardFieldRequired(field: InventoryStandardField, config: InventoryStandardFieldConfiguration[]): boolean {
  if ([InventoryStandardField.Name, InventoryStandardField.Quantity].includes(field)) {
    return true;
  }

  const configField = config.find((c) => c.field === field);
  return configField !== undefined && configField.required;
}

export function isStandardFieldHidden(field: InventoryStandardField, item: InventoryItemView, hideEmptyFields = false): boolean {
  const isEnabled = isStandardFieldEnabled(field, item.inventoryStandardFieldConfiguration);

  if (!isEnabled) {
    return true;
  }
  // if (field === InventoryStandardField.Quantity) {
  //   return false;
  // }
  // const configField = config.find((c) => c.field === field);
  // if (configField && !configField.displayed) {
  //   return true;
  // }

  if (hideEmptyFields) {
    switch (field) {
      case InventoryStandardField.Location: {
        return item.storageLocationId === 0 || item.storageLocationId === null;
      }
      case InventoryStandardField.Description: {
        return item.description.length === 0;
      }
      case InventoryStandardField.Vendor: {
        return item.vendorId === 0 || item.vendorId === null || item.vendorName.length === 0;
      }
      case InventoryStandardField.CatalogNumber: {
        return item.catalogNumber.length === 0;
      }
      case InventoryStandardField.LotNumber: {
        return item.lotNumber.length === 0;
      }
      case InventoryStandardField.Price: {
        return item.price === 0 || item.price === null;
      }
      case InventoryStandardField.DateReceived: {
        return item.dateReceived === null;
      }
      case InventoryStandardField.GrantNumber: {
        return item.grantNumber.length === 0;
      }
      case InventoryStandardField.PONumber: {
        return item.poNumber.length === 0;
      }
      case InventoryStandardField.Expiration: {
        return item.expirationDate === null;
      }
      case InventoryStandardField.SafetySheet: {
        return item.safetySheetUrl.length === 0;
      }
      case InventoryStandardField.Notes: {
        return item.notes.length === 0;
      }
    }
  }

  return false;
}

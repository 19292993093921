import { Button } from "@labarchives/ui-design";
import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { AdvancedSearchCriteria, InventorySavedSearch } from "@labarchives/inventory-shared/build/inventory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { Form } from "reactstrap";
import { InventoryTypeView } from "../../types/views";
import useVisible from "../../../utils/useVisible";
import { InventoryApi } from "../../../api/InventoryApi";
import { InventoryAdvancedSearchCriteriaRow } from "./InventoryAdvancedSearchCriteriaRow";
import { useInventoryAdvancedSearchHooks } from "./InventoryAdvancedSearchHooks";
import { SaveAdvancedSearchModal } from "./SaveAdvancedSearchModal";
import { DeleteSavedAdvancedSearchModal } from "./DeleteSavedAdvancedSearchModal";
import "./InventoryAdvancedSearch.scss";

export interface InventoryAdvancedSearchComponentProps {
  inventoryTypeViews: InventoryTypeView[];
  selectedInventoryTypes: InventoryTypeView[];
  criteria: AdvancedSearchCriteria[];
  isShowingSaveAdvancedSearch: boolean;
  isOpen: boolean;
  api: InventoryApi;

  onSearch(criteria: AdvancedSearchCriteria[]): void;

  onClosed(): void;

  onSaveSearchToggle(): void;
}

export function InventoryAdvancedSearch(props: InventoryAdvancedSearchComponentProps): ReactElement {
  const { inventoryTypeViews, selectedInventoryTypes, isOpen, isShowingSaveAdvancedSearch, onSaveSearchToggle, api } = props;

  const [searchToDelete, setSearchToDelete] = useState<InventorySavedSearch | undefined>();
  const { ref, isVisible, setIsVisible } = useVisible(false);
  const intl = useIntl();

  const {
    criteria,
    allAttributes,
    onCriteriaChanged,
    onCriteriaRemoved,
    onSearch,
    onSearchSaved,
    onSavedSearchDeleted,
    onSavedSearchLoaded,
    onClosed,
    isExcluded,
    savedSearches,
    savedSearchName,
    hasFocus,
  } = useInventoryAdvancedSearchHooks({
    onSearch: props.onSearch,
    inventoryTypes: inventoryTypeViews,
    selectedCriteria: props.criteria,
    selectedInventoryTypes,
    onSearchSaved: () => props.onSaveSearchToggle(),
    onClosed: props.onClosed,
    api: api,
  });

  function onSavedSearchDeletePrompt(savedSearch: InventorySavedSearch): void {
    setSearchToDelete(savedSearch);
  }

  useEffect(() => {
    function handleEscapeKey(event: KeyboardEvent): void {
      if (event.code === "Escape" && isOpen) {
        onClosed();
      }
    }

    document.addEventListener("keydown", handleEscapeKey);
    return () => document.removeEventListener("keydown", handleEscapeKey);
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <Form
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            e.stopPropagation();
            onSearch();
          }}
          role={"search"}
        >
          {/* <FocusTrap>*/}
          <div
            id="inventory-search-advanced-search"
            role={"dialog"}
            aria-modal={true}
            aria-label={intl.formatMessage({ id: "advanced.inventory.search" })}
          >
            {criteria
              .sort((c1, c2) => c1.index - c2.index)
              .map((c: AdvancedSearchCriteria) => (
                <InventoryAdvancedSearchCriteriaRow
                  key={c.fieldName + c.index}
                  criteria={c}
                  inventoryTypeViews={inventoryTypeViews}
                  allAttributes={allAttributes}
                  onCriteriaChanged={onCriteriaChanged}
                  onCriteriaRemoved={onCriteriaRemoved}
                  isDuplicated={criteria.some((dupe: AdvancedSearchCriteria) => dupe.fieldName === c.fieldName && dupe.index !== c.index)}
                  isExcluded={isExcluded(c)}
                  hasFocus={hasFocus(c)}
                  api={api}
                />
              ))}
            <div className={"advanced-search-footer"}>
              <div className={"advanced-search-footer-left"}>
                <Button id={"advanced-search-save-toggle"} color="primary" outline onClick={() => props.onSaveSearchToggle()}>
                  <span>
                    <FormattedMessage id={"save.this.search"} />
                  </span>
                </Button>
              </div>
              <div className={"advanced-search-footer-center"}>
                <Button id={"advanced-search-show-saved-button"} className={"saved-search-name"} color={"link"} onClick={() => setIsVisible(true)}>
                  <FormattedMessage id={"saved.searches"} /> <FontAwesomeIcon icon={faCaretDown} />
                </Button>
                {isVisible && (
                  <div className={"advanced-search-saved-search-list"} ref={ref}>
                    <ul>
                      {savedSearches.length === 0 && (
                        <li>
                          <FormattedMessage id={"no.saved.searches"} />
                        </li>
                      )}
                      {savedSearches.map((search) => (
                        <li key={search.id}>
                          <Button
                            color={"link"}
                            className={"saved-search-name"}
                            onClick={() => {
                              onSavedSearchLoaded(search);
                              setIsVisible(false);
                            }}
                          >
                            {search.name}
                          </Button>
                          <Button color={"link"} className="ms-2" onClick={() => onSavedSearchDeletePrompt(search)}>
                            <FontAwesomeIcon icon={faTimes} />
                          </Button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              <div className={"advanced-search-footer-right"}>
                <div>
                  <Button id={"advanced-search-run-search"} type="submit" color="primary">
                    <FormattedMessage id="search" />
                  </Button>
                </div>
                <div>
                  <Button color="none" className={"ms-1"} outline onClick={() => onClosed()}>
                    <FormattedMessage id="cancel" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {/* </FocusTrap>*/}
          <div className="advanced-search-modal-backdrop"></div>
        </Form>
      )}
      <SaveAdvancedSearchModal isOpen={isShowingSaveAdvancedSearch} onCancel={onSaveSearchToggle} name={savedSearchName} onSaved={onSearchSaved} />
      <DeleteSavedAdvancedSearchModal
        isOpen={searchToDelete !== undefined}
        onCancel={() => setSearchToDelete(undefined)}
        name={searchToDelete ? searchToDelete!.name : ""}
        onDeleted={() => {
          if (searchToDelete) {
            onSavedSearchDeleted(searchToDelete);
          }
          setSearchToDelete(undefined);
        }}
      />
    </>
  );
}

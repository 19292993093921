import React, { ReactElement, useState } from "react";
import { useIntl } from "react-intl";
import { Badge } from "reactstrap";
import { InventoryItemIdentity } from "@labarchives/inventory-shared/build/inventory";
import { PaidFeatureButton } from "../components/PaidFeatureButton/PaidFeatureButton";
import { RelationshipType } from "./types/RelationshipType";
import { InventoryItemRelationshipModal } from "./relationships/InventoryItemRelationshipModal";
import { InventoryRelationshipItemList } from "./relationships/InventoryRelationshipItemList";

interface AddInventoryItemRelationshipsComponentProps {
  childItems: InventoryItemIdentity[];
  parentItems: InventoryItemIdentity[];
  onParentsChanged(items: InventoryItemIdentity[]): void;
  onChildrenChanged(items: InventoryItemIdentity[]): void;
}

export function AddInventoryItemRelationships(props: AddInventoryItemRelationshipsComponentProps): ReactElement {
  const intl = useIntl();
  const { childItems, parentItems, onParentsChanged, onChildrenChanged } = props;

  function onAddParentTrigger(): void {
    onParentModalToggled();
  }

  function onAddChildTrigger(): void {
    onChildModalToggled();
  }

  const [isParentModalOpen, setIsParentModalOpen] = useState(false);
  const [isChildModalOpen, setIsChildModalOpen] = useState(false);

  function onParentModalToggled(): void {
    setIsParentModalOpen(!isParentModalOpen);
  }

  function onChildModalToggled(): void {
    setIsChildModalOpen(!isChildModalOpen);
  }

  function handleRelationshipSubmit(items: InventoryItemIdentity[], relationshipType: RelationshipType): void {
    if (relationshipType === RelationshipType.parent) {
      onParentsChanged([...parentItems, ...items]);
    } else if (relationshipType === RelationshipType.child) {
      onChildrenChanged([...childItems, ...items]);
    }
  }

  function handleParentsSubmit(items: InventoryItemIdentity[]): void {
    handleRelationshipSubmit(items, RelationshipType.parent);
  }

  function handleChildrenSubmit(items: InventoryItemIdentity[]): void {
    handleRelationshipSubmit(items, RelationshipType.child);
  }

  function removeParent(item: InventoryItemIdentity): void {
    onParentsChanged(parentItems.filter((parent) => parent.itemId !== item.itemId));
  }

  function removeChild(item: InventoryItemIdentity): void {
    onChildrenChanged(childItems.filter((child) => child.itemId !== item.itemId));
  }

  return (
    <div className={"mb-3"}>
      <div className={"d-flex align-items-center"}>
        <div className={"add-item-relationship-heading-with-count"}>
          <span className={"add-item-relationship-heading-with-count-label"}>{intl.formatMessage({ id: "parents" })}</span>
          <Badge role={"status"} aria-label={intl.formatMessage({ id: "parent.count.badge" }, { count: parentItems.length })}>
            {parentItems.length}
          </Badge>
        </div>
        <PaidFeatureButton id={"add-parent-trigger"} size={"sm"} type={"button"} onAllowedClick={() => onAddParentTrigger()}>
          {intl.formatMessage({ id: "add.items" })}
        </PaidFeatureButton>
      </div>

      <InventoryRelationshipItemList items={parentItems} removeItem={removeParent} isEnabled={true} />

      <div className={"d-flex align-items-center mt-3"}>
        <div className={"add-item-relationship-heading-with-count"}>
          <span className={"add-item-relationship-heading-with-count-label"}>{intl.formatMessage({ id: "children" })}</span>
          <Badge role={"status"} aria-label={intl.formatMessage({ id: "child.count.badge" }, { count: childItems.length })}>
            {childItems.length}
          </Badge>
        </div>
        <PaidFeatureButton id={"add-child-trigger"} size={"sm"} type={"button"} onAllowedClick={() => onAddChildTrigger()}>
          {intl.formatMessage({ id: "add.items" })}
        </PaidFeatureButton>
      </div>

      <InventoryRelationshipItemList items={childItems} removeItem={removeChild} isEnabled={true} />

      <InventoryItemRelationshipModal
        isOpen={isParentModalOpen}
        onToggle={onParentModalToggled}
        headerTextId={"add.parent.item"}
        saveTextId={"add.as.parents"}
        submitRelationship={handleParentsSubmit}
        existingRelationships={[...parentItems, ...childItems]}
        itemId={undefined}
      />
      <InventoryItemRelationshipModal
        isOpen={isChildModalOpen}
        onToggle={onChildModalToggled}
        headerTextId={"add.child.item"}
        saveTextId={"add.as.children"}
        submitRelationship={handleChildrenSubmit}
        existingRelationships={[...parentItems, ...childItems]}
        itemId={undefined}
      />
    </div>
  );
}

import React, { ReactElement } from "react";
import { useIntl } from "react-intl";
import { Badge } from "reactstrap";
import { InventoryItemIdentity } from "@labarchives/inventory-shared/build/inventory";
import { RelationshipItemIds } from "@labarchives/inventory-shared/build/inventory";
import { PaidFeatureButton } from "../../components/PaidFeatureButton/PaidFeatureButton";
import { InventoryApi } from "../../api/InventoryApi";
import { InventoryItemView } from "../types/views";
import { InventoryRelationshipItemList } from "./InventoryRelationshipItemList";
import { InventoryItemRelationshipModal } from "./InventoryItemRelationshipModal";
import { useInventoryItemRelationshipEditDisplay } from "./InventoryItemRelationshipEditDisplayHooks";
import "../InventoryItemAdd.css";
import { RelationshipRemovalWarningModal } from "./RelationshipRemovalWarningModal";

interface InventoryItemRelationshipEditDisplayProps {
  currentItemView: InventoryItemView;
  api: InventoryApi;

  handleRelationshipsAdded?(id: number | string, { parentIds, childIds }: RelationshipItemIds): void;

  handleChildRelationshipDeleted?(id: number, name: string, childItem: InventoryItemIdentity): void;

  handleParentRelationshipDeleted?(id: number, name: string, parentItem: InventoryItemIdentity): void;
}

export function InventoryItemRelationshipEditDisplay(props: InventoryItemRelationshipEditDisplayProps): ReactElement {
  const { api, currentItemView, handleRelationshipsAdded, handleChildRelationshipDeleted, handleParentRelationshipDeleted } = props;
  const noop = (): void => {
    // default "do nothing" method when there are no relationship actions provided (inventory admin, eln integration workflows)
  };

  const {
    childItems,
    parentItems,
    isChildModalOpen,
    isParentModalOpen,
    isRemovalWarningModalOpen,
    onRemovalWarningModalToggled,
    handleChildrenSubmit,
    handleParentsSubmit,
    onChildModalToggled,
    onParentModalToggled,
    handleRemoveChild,
    handleRemoveParent,
    onConfirmRemove,
  } = useInventoryItemRelationshipEditDisplay(
    api,
    currentItemView,
    handleRelationshipsAdded || noop,
    handleChildRelationshipDeleted || noop,
    handleParentRelationshipDeleted || noop,
  );

  const intl = useIntl();

  function onAddParentTrigger(): void {
    onParentModalToggled();
  }

  function onAddChildTrigger(): void {
    onChildModalToggled();
  }

  return (
    <div className={"mb-3"}>
      <div id={"inventory-relationship-container-parents"}>
        <div className={"item-relationship-display d-flex align-items-center justify-content-between"}>
          <div className={"add-item-relationship-heading-with-count"}>
            <span className={"add-item-relationship-heading-with-count-label"}>{intl.formatMessage({ id: "parents" })}</span>

            <Badge role={"status"} aria-label={intl.formatMessage({ id: "parent.count.badge" }, { count: parentItems.length })}>
              {parentItems.length}
            </Badge>
          </div>
          {handleRelationshipsAdded && (
            <PaidFeatureButton id={"add-parent-trigger"} size={"sm"} type={"button"} onAllowedClick={() => onAddParentTrigger()}>
              {intl.formatMessage({ id: "add.items" })}
            </PaidFeatureButton>
          )}
        </div>

        {parentItems.length > 0 && (
          <InventoryRelationshipItemList
            items={parentItems}
            removeItem={handleRemoveParent}
            isEnabled={handleChildRelationshipDeleted !== undefined}
          />
        )}
      </div>

      <hr />

      <div id={"inventory-relationship-container-children"}>
        <div className={"item-relationship-display d-flex align-items-center justify-content-between"}>
          <div className={"add-item-relationship-heading-with-count"}>
            <span className={"add-item-relationship-heading-with-count-label"}>{intl.formatMessage({ id: "children" })}</span>
            <Badge role={"status"} aria-label={intl.formatMessage({ id: "child.count.badge" }, { count: childItems.length })}>
              {childItems.length}
            </Badge>
          </div>
          {handleRelationshipsAdded && (
            <PaidFeatureButton id={"add-parent-trigger"} size={"sm"} type={"button"} onAllowedClick={() => onAddChildTrigger()}>
              {intl.formatMessage({ id: "add.items" })}
            </PaidFeatureButton>
          )}
        </div>
        {childItems.length > 0 && (
          <InventoryRelationshipItemList items={childItems} removeItem={handleRemoveChild} isEnabled={handleChildRelationshipDeleted !== undefined} />
        )}
      </div>

      <InventoryItemRelationshipModal
        isOpen={isParentModalOpen}
        onToggle={onParentModalToggled}
        headerTextId={"add.parent.item"}
        saveTextId={"add.as.parents"}
        submitRelationship={handleParentsSubmit}
        existingRelationships={[...parentItems, ...childItems]}
        itemId={currentItemView.id}
      />
      <InventoryItemRelationshipModal
        isOpen={isChildModalOpen}
        onToggle={onChildModalToggled}
        headerTextId={"add.child.item"}
        saveTextId={"add.as.children"}
        submitRelationship={handleChildrenSubmit}
        existingRelationships={[...parentItems, ...childItems]}
        itemId={currentItemView.id}
      />
      <RelationshipRemovalWarningModal
        isOpen={isRemovalWarningModalOpen}
        onToggle={onRemovalWarningModalToggled}
        onRemove={onConfirmRemove}
        bodyTextId={"sure.you.want.to.remove"}
        buttonTextId={"remove"}
        headerTextId={"remove.item"}
      />
    </div>
  );
}

/* eslint-disable no-underscore-dangle,@typescript-eslint/no-explicit-any */
import { AxiosResponse, AxiosInstance } from "axios";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const axios = require("axios").default;
import {
  Account,
  AccountSubscriptionSummary,
  AccountSwitch,
  AdvancedSearchCriteria,
  ApplicationPricing,
  CasSearchResults,
  CustomAttribute,
  DismissedFeatureAlerts,
  FeatureId,
  FreezerBox,
  FreezerBoxExtended,
  InventoryAdvancedSearchCriteria,
  InventoryBulkUpdateRequest,
  InventoryBulkUpdateResponse,
  InventoryClientError,
  InventoryHistory,
  InventoryImportRequest,
  InventoryItem,
  InventoryItemIdentity,
  InventoryItemRelationshipResponse,
  InventoryLink,
  InventorySavedSearch,
  InventorySearchAutoSuggestItem,
  InventorySearchCriteria,
  InventorySearchResultSet,
  InventorySearchResultSetItem,
  InventoryType,
  LocationsImportRequest,
  LoginContext,
  MaintenanceMessage,
  Notification,
  Order,
  OrderApprovedRequest,
  OrderBulkApprovedRequest,
  OrderBulkApprovedResponse,
  OrderBulkPlacedRequest,
  OrderBulkPlacedResponse,
  OrderCancelledRequest,
  OrderPlacedRequest,
  OrderReceivedRequest,
  OrderSearchCriteria,
  OrderSearchResultSet,
  OrderSearchResultSetItem,
  OrderUpdateRequest,
  OrganizationBillingType,
  RelationshipItemIds,
  RequestedOrder,
  RestartTrialResponse,
  Role,
  RoleChange,
  SessionPingResponseDto,
  SiteAdminInventoryItem,
  SiteAdminInventorySearchCriteria,
  SiteLocations,
  SortDirection,
  StorageLocation,
  SubscriptionAddition,
  SubscriptionCart,
  SubscriptionCartItem,
  SubscriptionTotal,
  UpdateInventoryQuantityRequest,
  UpdateInventoryStorageLocationRequest,
  UpdateInventoryUsageRequest,
  User,
  UserAutoSuggestItem,
  UserSession,
  UserSpecificMessage,
  Vendor,
  VendorAssignments,
} from "@labarchives/inventory-shared/build/inventory";
import {
  SuperUserAccountDetails,
  SuperUserAddUserRequest,
  SuperUserChangeUserStatusRequest,
  SuperUserSearchResultUser,
  SuperUserSummary,
  SuperUserUpdateOwnerRequest,
  SuperUserUpdateSubscriptionRequest,
  SuperUserUserDetails,
} from "@labarchives/inventory-shared/build/inventory/superuser/types";
import { serializeError } from "serialize-error";
import { SuperUserChangeUserSiteAdminRequest } from "@labarchives/inventory-shared/build/inventory/superuser/SuperUserChangeUserSiteAdminRequest";
import { SuperUserUpdateNotesRequest } from "@labarchives/inventory-shared/build/inventory/superuser/SuperUserUpdateNotesRequest";
import {
  SubscriptionAdditionalUserDetails,
  SubscriptionDetails,
  SubscriptionProducts,
  SubscriptionRenewalDetails,
  SubscriptionSuperUserAddSubscriptionDetailsRequestDto,
  SubscriptionSuperUserAddSubscriptionDetailsResponseDto,
  SubscriptionSuperUserAddSubscriptionRequestDto,
  SubscriptionSuperUserAddSubscriptionResponseDto,
  SubscriptionUpgradeDetails,
} from "@labarchives/inventory-shared/build/subscription";

import { getAuthenticationService } from "../components/Authentication/getAuthenticationService";
import { Settings } from "../utils/Settings";
import { AccessDeniedError } from "../components/Authentication/AccessDeniedError";
import { AuthenticationError } from "../components/Authentication/AuthenticationError";
import { InventoryItemRelationshipItem } from "../inventory/relationships/InventoryItemRelationshipModalHooks";
import { dateParser } from "./inventoryLocalStorage";
import { InventoryApi } from "./InventoryApi";

const baseAPIUrl = Settings.getBaseApiUrl();
const accountServiceBaseAPIUrl = Settings.getBaseAccountServiceApiUrl();
const baseInventoryAPIUrl = `${baseAPIUrl}/inventory/`;
const baseInventoryTypeAPIUrl = `${baseAPIUrl}/types/`;
const baseStorageLocationsAPIUrl = `${baseAPIUrl}/storageLocations/`;
const baseOrderAPIUrl = `${baseAPIUrl}/orders/`;
const baseVendorsAPIUrl = `${baseAPIUrl}/vendors/`;
const baseUsersAPIUrl = `${baseAPIUrl}/users/`;
const baseRolesAPIUrl = `${baseAPIUrl}/roles/`;
const baseAccountAPIUrl = `${baseAPIUrl}/account/`;
const baseNotificationsAPIUrl = `${baseAPIUrl}/notifications/`;
const baseHealthAPIUrl = `${baseAPIUrl}/health/`;
const baseSuperUserAPIUrl = `${baseAPIUrl}/superuser/`;
const baseSiteAdminAPIUrl = `${baseAPIUrl}/siteAdmin/`;
const baseSiteLocationsAPIUrl = `${baseAPIUrl}/siteLocations/`;
const baseReportsUrl = `${baseAPIUrl}/reports/`;

export interface Attachment {
  filename: string;
  data: Blob;
}

const authenticationService = getAuthenticationService();

const dateTransformer = function (response: any): any {
  if (typeof response !== "string" || response === "") {
    return response;
  }
  return JSON.parse(response, dateParser);
};

class AxiosSingleton {
  private static instance: AxiosInstance;
  public static getInstance(): AxiosInstance {
    if (!AxiosSingleton.instance) {
      AxiosSingleton.instance = axios.create();
      AxiosSingleton.instance.defaults.transformResponse = [dateTransformer];

      AxiosSingleton.instance.interceptors.request.use((config) => {
        const headers = authenticationService.getHeaders();
        if (config.method !== "OPTIONS") {
          // eslint-disable-next-line no-param-reassign
          config.withCredentials = true;
        }
        return { ...config, headers };
      });

      AxiosSingleton.instance.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          if (error.response && [401, 403, 419].includes(error.response.status)) {
            console.log("Auth error, logging out", error.response.status);
            authenticationService.logout();
            return Promise.reject(new AuthenticationError());
          }
          return Promise.reject(error);
        },
      );
    }

    return AxiosSingleton.instance;
  }
}

export class InventoryApiClient implements InventoryApi {
  private readonly axios: AxiosInstance = AxiosSingleton.getInstance();

  private getFullName(user: User | undefined): string {
    return user ? user.fullName : "unknown";
  }

  private getUserId(user: User | undefined): number | null {
    return user ? user.id : null;
  }

  private async getAttachmentResponse(url: string): Promise<Attachment> {
    const fileResponse = await this.axios.get(url, { transformResponse: (data: any) => data, responseType: "blob" });
    const filenameRegex = /filename[^\n;=]*=((["']).*?\2|[^\n;]*)/;
    const matches = filenameRegex.exec(fileResponse.headers["content-disposition"]);
    let filename = "";
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/["']/g, "");
    }

    return { filename, data: fileResponse.data };
  }

  public async getInventoryItem(id: number | string): Promise<InventoryItem> {
    const result = await this.axios.get<InventoryItem>(`${baseInventoryAPIUrl}${id}`);

    return result.data;
  }

  public async addInventoryHistory(id: number | string, historyItem: InventoryHistory): Promise<InventoryHistory> {
    const result = await this.axios.post<InventoryHistory>(`${baseInventoryAPIUrl}${id}/history`, historyItem);

    return result.data;
  }

  public async updateInventoryUsage(
    id: number | string,
    quantityUsed: number,
    unitUsed: string,
    storageCells: string[],
    links?: InventoryLink[],
  ): Promise<InventoryItem> {
    const request: UpdateInventoryUsageRequest = {
      storageCells,
      unit: unitUsed,
      quantity: quantityUsed,
      links,
    };
    const postResult: AxiosResponse<InventoryItem> = await this.axios.post<InventoryItem>(`${baseInventoryAPIUrl}${id}/usage`, request);
    return postResult.data;
  }

  public async updateInventoryQuantity(id: number | string, quantity: number, unitOfMeasure: string): Promise<InventoryItem> {
    const request: UpdateInventoryQuantityRequest = {
      quantity,
      unit: unitOfMeasure,
    };

    const postResult: AxiosResponse<InventoryItem> = await this.axios.post<InventoryItem>(`${baseInventoryAPIUrl}${id}/quantity`, request);
    return postResult.data;
  }

  public async updateInventoryStorageLocation(
    id: number | string,
    locationId: number | undefined | null,
    storageCells: string[] | undefined | null,
    notes: string | undefined | null,
  ): Promise<InventoryItem> {
    const request: UpdateInventoryStorageLocationRequest = {
      locationId: locationId || undefined,
      storageCells: storageCells || undefined,
      notes: notes || undefined,
    };

    const postResult: AxiosResponse<InventoryItem> = await this.axios.post<InventoryItem>(`${baseInventoryAPIUrl}${id}/storage`, request);
    return postResult.data;
  }

  private async addAttachments(item: InventoryItem, attachments: File[]): Promise<InventoryItem> {
    const formData = new FormData();
    attachments.forEach((attachment, index) => formData.append(`attachment${index}`, attachment));
    const updatedItem: AxiosResponse<InventoryItem> = await this.axios.post(`${baseInventoryAPIUrl}${item.id}/attachments`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return updatedItem.data;
  }

  public async addInventoryItem(item: InventoryItem, attachments: File[]): Promise<InventoryItem> {
    const createResult: AxiosResponse<InventoryItem> = await this.axios.post<InventoryItem>(`${baseInventoryAPIUrl}`, item);
    if (attachments.length > 0) {
      return this.addAttachments(createResult.data, attachments);
    }
    return createResult.data;
  }

  public async updateInventoryItem(item: InventoryItem, attachments: File[]): Promise<InventoryItem> {
    const updateResult: AxiosResponse<InventoryItem> = await this.axios.post<InventoryItem>(`${baseInventoryAPIUrl}${item.itemId}`, item);
    if (attachments.length > 0) {
      return this.addAttachments(updateResult.data, attachments);
    }
    return updateResult.data;
  }

  public async deleteInventory(id: number | string): Promise<void> {
    const result: AxiosResponse<void> = await this.axios.delete(`${baseInventoryAPIUrl}${id}`, { transformResponse: (data: any) => data });
    return result.data;
  }

  public async getAttachment(inventoryId: number | string, attachmentId: number): Promise<Attachment> {
    const url = `${baseInventoryAPIUrl}${inventoryId}/attachments/${attachmentId}`;
    return this.getAttachmentResponse(url);
  }

  public async importInventoryItems(importRequest: InventoryImportRequest): Promise<void> {
    const result: AxiosResponse<void> = await this.axios.post(`${baseInventoryAPIUrl}import`, importRequest, { transformResponse: (data) => data });
    return result.data;
  }

  public async updateInventoryItems(updateRequest: InventoryBulkUpdateRequest): Promise<InventoryBulkUpdateResponse> {
    const result: AxiosResponse<InventoryBulkUpdateResponse> = await this.axios.post(`${baseInventoryAPIUrl}bulkUpdate`, updateRequest);
    return result.data;
  }

  public async getInventoryCASInformation(casNumber: string): Promise<CasSearchResults | null> {
    const result: AxiosResponse<CasSearchResults | null> = await this.axios.get(`${baseInventoryAPIUrl}cas/${casNumber}`);
    return result.data;
  }

  public async getInventoryTypes(): Promise<InventoryType[]> {
    const result: AxiosResponse<InventoryType[]> = await this.axios.get(`${baseInventoryTypeAPIUrl}`);
    return result.data;
  }

  public async addInventoryType(inventoryType: InventoryType): Promise<InventoryType> {
    const result: AxiosResponse<InventoryType> = await this.axios.post(`${baseInventoryTypeAPIUrl}`, inventoryType);
    return result.data;
  }

  public async updateInventoryType(inventoryType: InventoryType): Promise<InventoryType> {
    const result: AxiosResponse<InventoryType> = await this.axios.post(`${baseInventoryTypeAPIUrl}${inventoryType.id}`, inventoryType);
    return result.data;
  }

  public async addInventoryTypeAttribute(typeId: number, attribute: CustomAttribute): Promise<InventoryType> {
    const getResult: AxiosResponse<InventoryType> = await this.axios.get(`${baseInventoryTypeAPIUrl}${typeId}`);
    const existingType = getResult.data;
    existingType.customAttributes.push(attribute);

    const postResult: AxiosResponse<InventoryType> = await this.axios.post(`${baseInventoryTypeAPIUrl}${typeId}`, existingType);
    return postResult.data;
  }

  public async deleteInventoryType(inventoryType: InventoryType): Promise<void> {
    const result: AxiosResponse<void> = await this.axios.delete(`${baseInventoryTypeAPIUrl}${inventoryType.id}`, {
      transformResponse: (data: any) => data,
    });
    return result.data;
  }

  public async searchInventory(criteria: InventorySearchCriteria): Promise<InventorySearchResultSet> {
    const searchResult: AxiosResponse<InventorySearchResultSet> = await this.axios.post<InventorySearchResultSet>(
      `${baseInventoryAPIUrl}search`,
      criteria,
    );
    return searchResult.data;
  }

  public async exportInventory(criteria: InventorySearchCriteria): Promise<InventorySearchResultSetItem[]> {
    const results = await this.searchInventory({
      ...criteria,
      resultSetOptions: { pageNumber: 1, pageSize: 10_000_000, sortField: "", sortDirection: SortDirection.DEFAULT },
    });

    return results.items;
  }

  public async getBulkUpdateConflicts(referenceNumber: string): Promise<InventorySearchResultSetItem[]> {
    const searchResult: AxiosResponse<InventorySearchResultSetItem[]> = await this.axios.get<InventorySearchResultSetItem[]>(
      `${baseInventoryAPIUrl}bulkUpdate/conflicts/${referenceNumber}`,
    );
    return searchResult.data;
  }

  public async getLastBulkUpdateDetails(): Promise<InventoryBulkUpdateResponse> {
    const result: AxiosResponse<InventoryBulkUpdateResponse> = await this.axios.get<InventoryBulkUpdateResponse>(
      `${baseInventoryAPIUrl}bulkUpdate/last`,
    );
    return result.data;
  }

  public async searchInventoryAdvanced(criteria: InventoryAdvancedSearchCriteria): Promise<InventorySearchResultSet> {
    const searchResult: AxiosResponse<InventorySearchResultSet> = await this.axios.post<InventorySearchResultSet>(
      `${baseInventoryAPIUrl}advancedsearch`,
      criteria,
    );
    return searchResult.data;
  }

  public async exportInventoryAdvanced(criteria: InventoryAdvancedSearchCriteria): Promise<InventorySearchResultSetItem[]> {
    const results = await this.searchInventoryAdvanced({
      ...criteria,
      resultSetOptions: { pageNumber: 1, pageSize: 10_000_000, sortField: "", sortDirection: SortDirection.DEFAULT },
    });

    return results.items;
  }

  public async getInventorySuggestions(searchTerm: string, compareFields: string[] = ["name"]): Promise<InventorySearchAutoSuggestItem[]> {
    const result: AxiosResponse<InventorySearchAutoSuggestItem[]> = await this.axios.get(
      `${baseInventoryAPIUrl}suggest?term=${searchTerm}${compareFields.map((c) => `&compareFields=${c}`).join("")}`,
    );
    return result.data;
  }

  public async searchOrders(criteria: OrderSearchCriteria): Promise<OrderSearchResultSet> {
    const result: AxiosResponse<OrderSearchResultSet> = await this.axios.post(`${baseOrderAPIUrl}search`, criteria);
    return result.data;
  }

  public async exportOrders(criteria: OrderSearchCriteria): Promise<OrderSearchResultSetItem[]> {
    const results = await this.searchOrders({
      ...criteria,
      resultSetOptions: { pageNumber: 1, pageSize: 10_000_000, sortField: "", sortDirection: SortDirection.DEFAULT },
    });

    return results.items;
  }

  public async addOrderRequest(requestedOrder: RequestedOrder): Promise<Order> {
    const result: AxiosResponse<Order> = await this.axios.post(`${baseOrderAPIUrl}request`, requestedOrder);
    return result.data;
  }

  public async approveOrder(request: OrderApprovedRequest): Promise<Order> {
    const result: AxiosResponse<Order> = await this.axios.post(`${baseOrderAPIUrl}${request.id}/approval`, request);
    return result.data;
  }

  public async placeOrder(request: OrderPlacedRequest): Promise<Order> {
    const result: AxiosResponse<Order> = await this.axios.post(`${baseOrderAPIUrl}${request.id}/placement`, request);
    return result.data;
  }

  public async receiveOrder(request: OrderReceivedRequest): Promise<Order> {
    const result: AxiosResponse<Order> = await this.axios.post(`${baseOrderAPIUrl}${request.id}/reception`, request);
    return result.data;
  }

  public async cancelOrder(request: OrderCancelledRequest): Promise<Order> {
    const result: AxiosResponse<Order> = await this.axios.post(`${baseOrderAPIUrl}${request.id}/cancellation`, request);
    return result.data;
  }

  public async getOrder(id: number): Promise<Order> {
    const result: AxiosResponse<Order> = await this.axios.get(`${baseOrderAPIUrl}${id}`);
    return result.data;
  }

  public async addOrderNote(id: number, note: string, user: User | undefined): Promise<Order> {
    const order = await this.getOrder(id);
    order.notes.push({ note, noteDate: new Date(), userName: this.getFullName(user), id: 0 });

    const result: AxiosResponse<Order> = await this.axios.post(`${baseOrderAPIUrl}${id}`, order);
    return result.data;
  }

  public async updateOrder(request: OrderUpdateRequest, user: User | undefined): Promise<Order> {
    const order = await this.getOrder(request.id);
    order.vendorId = request.vendorId;
    order.price = request.price;
    order.quantity = request.quantity;
    order.catalogNumber = request.catalogNumber;
    order.poNumber = request.poNumber;
    order.grantNumber = request.grantNumber;
    order.inventoryName = request.inventoryName;
    order.inventoryTypeId = request.inventoryTypeId;
    order.dateRequired = request.requiredDate;
    order.history.push({
      activityDate: new Date(),
      userName: this.getFullName(user),
      userId: this.getUserId(user),
      description: "Updated order",
      id: 0,
    });

    const result: AxiosResponse<Order> = await this.axios.post(`${baseOrderAPIUrl}${request.id}`, order);
    return result.data;
  }

  public async approveOrderInBulk(request: OrderBulkApprovedRequest): Promise<OrderBulkApprovedResponse> {
    const result = await this.axios.post(`${baseOrderAPIUrl}bulk/approval`, request);
    return result.data;
  }

  public async placeOrderInBulk(request: OrderBulkPlacedRequest): Promise<OrderBulkPlacedResponse> {
    const result = await this.axios.post(`${baseOrderAPIUrl}bulk/placement`, request);
    return result.data;
  }

  public async getStorageLocations(): Promise<StorageLocation[]> {
    const result: AxiosResponse<StorageLocation[]> = await this.axios.get(`${baseStorageLocationsAPIUrl}`);
    return result.data;
  }

  public async addStorageLocation(storageLocation: StorageLocation): Promise<StorageLocation> {
    const result: AxiosResponse<StorageLocation> = await this.axios.post(`${baseStorageLocationsAPIUrl}`, storageLocation);
    return result.data;
  }

  public async updateStorageLocation(storageLocation: StorageLocation): Promise<StorageLocation> {
    const result: AxiosResponse<StorageLocation> = await this.axios.post(`${baseStorageLocationsAPIUrl}${storageLocation.id}`, storageLocation);
    return result.data;
  }

  public async deleteStorageLocation(storageLocation: StorageLocation): Promise<void> {
    const result: AxiosResponse<void> = await this.axios.delete(`${baseStorageLocationsAPIUrl}${storageLocation.id}`, {
      transformResponse: (data: any) => data,
    });
    return result.data;
  }

  public async getFreezerBox(storageLocationId: number): Promise<FreezerBox> {
    const result: AxiosResponse<FreezerBox> = await this.axios.get(`${baseStorageLocationsAPIUrl}${storageLocationId}/freezerBox`);
    return result.data;
  }

  public async getVendors(): Promise<Vendor[]> {
    const result: AxiosResponse<Vendor[]> = await this.axios.get(`${baseVendorsAPIUrl}`);
    return result.data;
  }

  public async addVendor(vendor: Vendor): Promise<Vendor> {
    const result: AxiosResponse<Vendor> = await this.axios.post(`${baseVendorsAPIUrl}`, vendor);
    return result.data;
  }

  public async updateVendor(vendor: Vendor): Promise<Vendor> {
    const result: AxiosResponse<Vendor> = await this.axios.post(`${baseVendorsAPIUrl}${vendor.id}`, vendor);
    return result.data;
  }

  public async deleteVendor(vendorId: number): Promise<void> {
    const result: AxiosResponse<void> = await this.axios.delete(`${baseVendorsAPIUrl}${vendorId}`, { transformResponse: (data: any) => data });
    return result.data;
  }

  public async getVendorAssignments(vendorId: number): Promise<VendorAssignments> {
    const result: AxiosResponse<VendorAssignments> = await this.axios.get(`${baseVendorsAPIUrl}${vendorId}/assignments`);
    return result.data;
  }

  public async getUsers(): Promise<User[]> {
    const result: AxiosResponse<User[]> = await this.axios.get(`${baseUsersAPIUrl}`);
    return result.data;
  }

  public async sendUserInvites(emailAddresses: string[]): Promise<User[]> {
    const result: AxiosResponse<User[]> = await this.axios.post(`${baseUsersAPIUrl}invite`, emailAddresses);
    return result.data;
  }

  public async getDismissedFeatureAlerts(): Promise<string[]> {
    const result: AxiosResponse<DismissedFeatureAlerts> = await this.axios.get(`${baseNotificationsAPIUrl}featureAlerts/dismissed`);
    return result.data.dismissedFeatureIds;
  }

  public async dismissFeatureAlert(featureId: string | FeatureId): Promise<string[]> {
    const result: AxiosResponse<DismissedFeatureAlerts> = await this.axios.post(`${baseNotificationsAPIUrl}featureAlerts/dismissed`, featureId);
    return result.data.dismissedFeatureIds;
  }

  public async deleteUser(userId: number): Promise<User> {
    const result: AxiosResponse<User> = await this.axios.delete(`${baseUsersAPIUrl}${userId}`);
    return result.data;
  }

  public async updateUserRole(userId: number, roleId: number): Promise<User> {
    const data: RoleChange = { userId, roleId };
    const result: AxiosResponse<User> = await this.axios.post(`${baseUsersAPIUrl}${userId}/role`, data);
    return result.data;
  }

  public async addRole(role: Role): Promise<Role> {
    const result: AxiosResponse<Role> = await this.axios.post(`${baseRolesAPIUrl}`, role);
    return result.data;
  }

  public async updateRole(role: Role): Promise<Role> {
    const result: AxiosResponse<Role> = await this.axios.post(`${baseRolesAPIUrl}${role.id}`, role);
    return result.data;
  }

  public async deleteRole(roleId: number): Promise<void> {
    const result: AxiosResponse<void> = await this.axios.delete(`${baseRolesAPIUrl}${roleId}`, {
      transformResponse: (data: any) => data,
    });
    return result.data;
  }

  public async signUserIn(sso: LoginContext): Promise<User | undefined> {
    const ssoAxios = axios.create({
      headers: authenticationService.getHeaders(),
      transformResponse: [dateTransformer],
      withCredentials: true,
    });

    try {
      const result: AxiosResponse<UserSession> = await ssoAxios.post(`${baseUsersAPIUrl}sso`, sso);
      authenticationService.setCsrfToken(result.data.csrfToken);
      return result.data.user;
    } catch (error: any) {
      console.error("SSO Error", error);
      if (error.response && error.response.status === 403) {
        if (error.response.data && error.response.data.type === "CsrfTokenError") {
          return undefined;
        }
        throw new AccessDeniedError();
      }

      return undefined;
    }
  }

  public async switchUserAccount(accountSwitch: AccountSwitch): Promise<User | undefined> {
    try {
      const result: AxiosResponse<User> = await this.axios.post(`${baseUsersAPIUrl}switchAccount`, accountSwitch);
      return result.data;
    } catch (error: any) {
      console.error(error);
      return undefined;
    }
  }

  public async addAccount(accountName: string): Promise<User | undefined> {
    try {
      const result: AxiosResponse<User> = await this.axios.post(`${baseAccountAPIUrl}`, { accountName });
      return result.data;
    } catch (error: any) {
      console.error(error);
      return undefined;
    }
  }

  public async getInviteSuggestions(): Promise<UserAutoSuggestItem[]> {
    const result: AxiosResponse<UserAutoSuggestItem[]> = await this.axios.get(`${baseUsersAPIUrl}invite`);
    return result.data;
  }

  public async getUser(): Promise<User> {
    const result: AxiosResponse<User> = await this.axios.get(`${baseUsersAPIUrl}current`);
    return result.data;
  }

  public async getRoles(): Promise<Role[]> {
    const result: AxiosResponse<Role[]> = await this.axios.get(`${baseRolesAPIUrl}`);
    return result.data;
  }

  public async getAccountSettings(): Promise<Account> {
    const result: AxiosResponse<Account> = await this.axios.get(`${baseAccountAPIUrl}settings`);
    return result.data;
  }

  public async updateAccountSettings(account: Account): Promise<Account> {
    const result: AxiosResponse<Account> = await this.axios.post(`${baseAccountAPIUrl}settings`, account);
    return result.data;
  }

  public async getUnreadNotifications(): Promise<Notification[]> {
    const result: AxiosResponse<Notification[]> = await this.axios.get(`${baseNotificationsAPIUrl}unread`);
    return result.data;
  }

  public async getUnreadNotificationCount(userId: number, accountId: number): Promise<number> {
    const result: AxiosResponse<{ count: number }> = await this.axios.get(
      `${baseNotificationsAPIUrl}unread/count?userId=${userId}&accountId=${accountId}`,
      {},
    );
    return result.data.count;
  }

  public async markNotificationsAsRead(notificationIds: number[]): Promise<Notification[]> {
    const result: AxiosResponse<Notification[]> = await this.axios.post(`${baseNotificationsAPIUrl}unread`, notificationIds);
    return result.data;
  }

  public async getAccountSubscriptionSummary(): Promise<AccountSubscriptionSummary> {
    const result: AxiosResponse<AccountSubscriptionSummary> = await this.axios.get(`${baseAccountAPIUrl}subscription`);
    return result.data;
  }

  public async getApplicationPricing(): Promise<ApplicationPricing> {
    const result: AxiosResponse<ApplicationPricing> = await this.axios.get(`${baseAccountAPIUrl}subscription/pricing`);
    return result.data;
  }

  public async calculateSubscriptionTotal(
    numberOfUsers: number,
    numberOfYears: number,
    billingType: OrganizationBillingType,
  ): Promise<SubscriptionTotal> {
    const payload: SubscriptionCartItem = { users: numberOfUsers, years: numberOfYears, organizationBillingType: billingType };
    const result: AxiosResponse<SubscriptionTotal> = await this.axios.post(`${baseAccountAPIUrl}subscription/pricing`, payload);
    return result.data;
  }

  public async calculateSubscriptionAdditionalUsersTotal(numberOfUsers: number): Promise<SubscriptionTotal> {
    const payload: SubscriptionAddition = { users: numberOfUsers };
    const result: AxiosResponse<SubscriptionTotal> = await this.axios.post(`${baseAccountAPIUrl}subscription/pricing/additional`, payload);
    return result.data;
  }

  public async purchaseSubscription(numberOfUsers: number, numberOfYears: number, billingType: OrganizationBillingType): Promise<SubscriptionCart> {
    const payload: SubscriptionCartItem = { users: numberOfUsers, years: numberOfYears, organizationBillingType: billingType };
    const result: AxiosResponse<SubscriptionCart> = await this.axios.post(`${baseAccountAPIUrl}subscription/purchase`, payload);
    return result.data;
  }

  public async renewSubscription(numberOfUsers: number, numberOfYears: number, billingType: OrganizationBillingType): Promise<SubscriptionCart> {
    const payload: SubscriptionCartItem = { users: numberOfUsers, years: numberOfYears, organizationBillingType: billingType };
    const result: AxiosResponse<SubscriptionCart> = await this.axios.post(`${baseAccountAPIUrl}subscription/renew`, payload);
    return result.data;
  }

  public async addUsersToSubscription(numberOfUsers: number): Promise<SubscriptionCart> {
    const payload: SubscriptionAddition = { users: numberOfUsers };
    const result: AxiosResponse<SubscriptionCart> = await this.axios.post(`${baseAccountAPIUrl}subscription/additional`, payload);
    return result.data;
  }

  // eslint-disable-next-line consistent-return
  public async logError(error: unknown | Error, componentStack = "", errorReportId = ""): Promise<void> {
    const isError = (err: unknown): err is Error => err instanceof Error;

    if (!isError(error)) {
      return;
    }
    try {
      const inventoryError: InventoryClientError = {
        message: error.message,
        name: error.name,
        stack: error.stack || "",
        componentStack,
        errorReportId,
        details: serializeError(error),
      };
      await this.axios.post(`${baseHealthAPIUrl}errors/client`, inventoryError, { transformResponse: (data) => data });
      return;
    } catch (_error) {
      console.error("Error logging client side error", _error);
    }
  }

  public async superUserGetSummary(): Promise<SuperUserSummary> {
    const result: AxiosResponse<SuperUserSummary> = await this.axios.get(`${baseSuperUserAPIUrl}summary`);
    return result.data;
  }

  public async superUserSearchUser(term: string, inventoryOnly = true): Promise<SuperUserSearchResultUser[]> {
    const result: AxiosResponse<SuperUserSearchResultUser[]> = await this.axios.get(`${baseSuperUserAPIUrl}users/search`, {
      params: { term, inventoryOnly },
    });
    return result.data;
  }

  public async superUserGetUserDetails(userId: number): Promise<SuperUserUserDetails> {
    const result: AxiosResponse<SuperUserUserDetails> = await this.axios.get(`${baseSuperUserAPIUrl}users/${userId}`);
    return result.data;
  }

  public async superUserChangeUserStatus(request: SuperUserChangeUserStatusRequest): Promise<SuperUserUserDetails> {
    const result: AxiosResponse<SuperUserUserDetails> = await this.axios.post(`${baseSuperUserAPIUrl}users/${request.userId}/status`, request);
    return result.data;
  }

  public async superUserChangeUserSiteAdmin(request: SuperUserChangeUserSiteAdminRequest): Promise<SuperUserUserDetails> {
    const result: AxiosResponse<SuperUserUserDetails> = await this.axios.post(`${baseSuperUserAPIUrl}users/${request.userId}/siteAdmin`, request);
    return result.data;
  }

  public async superUserGetAccountDetails(accountId: number): Promise<SuperUserAccountDetails> {
    const result: AxiosResponse<SuperUserAccountDetails> = await this.axios.get(`${baseSuperUserAPIUrl}accounts/${accountId}`);
    return result.data;
  }

  public async superUserUpdateSubscriptionDetails(request: SuperUserUpdateSubscriptionRequest): Promise<SuperUserAccountDetails> {
    const result: AxiosResponse<SuperUserAccountDetails> = await this.axios.post(`${baseSuperUserAPIUrl}accounts/subscription`, request);
    return result.data;
  }

  public async superUserUpdateOwner(request: SuperUserUpdateOwnerRequest): Promise<SuperUserAccountDetails> {
    const result: AxiosResponse<SuperUserAccountDetails> = await this.axios.post(`${baseSuperUserAPIUrl}accounts/owner`, request);
    return result.data;
  }

  public async superUserAddUser(request: SuperUserAddUserRequest): Promise<SuperUserAccountDetails> {
    const result: AxiosResponse<SuperUserAccountDetails> = await this.axios.post(`${baseSuperUserAPIUrl}accounts/users`, request);
    return result.data;
  }

  public async superUserUpdateNotes(request: SuperUserUpdateNotesRequest): Promise<SuperUserAccountDetails> {
    const result: AxiosResponse<SuperUserAccountDetails> = await this.axios.post(`${baseSuperUserAPIUrl}accounts/notes`, request);
    return result.data;
  }

  public async healthCheck(): Promise<{ status: string }> {
    const result: AxiosResponse<{ status: string }> = await this.axios.get(`${baseAPIUrl}/health`);
    return result.data;
  }

  public async getSavedSearches(): Promise<InventorySavedSearch[]> {
    const result: AxiosResponse<InventorySavedSearch[]> = await this.axios.get(`${baseInventoryAPIUrl}advancedsearch/saved`);
    return result.data;
  }

  public async saveSearch(name: string, criteria: AdvancedSearchCriteria[]): Promise<InventorySavedSearch> {
    const search: InventorySavedSearch = { name, criteria, id: 0 };
    const result: AxiosResponse<InventorySavedSearch> = await this.axios.post(`${baseInventoryAPIUrl}advancedsearch/saved`, search);
    return result.data;
  }

  public async updateSavedSearch(search: InventorySavedSearch): Promise<InventorySavedSearch> {
    const result: AxiosResponse<InventorySavedSearch> = await this.axios.post(`${baseInventoryAPIUrl}advancedsearch/saved/${search.id}`, search);
    return result.data;
  }

  public async deleteSavedSearch(search: InventorySavedSearch): Promise<void> {
    const result: AxiosResponse<void> = await this.axios.delete(`${baseInventoryAPIUrl}advancedsearch/saved/${search.id}`, {
      transformResponse: (data: any) => data,
    });
    return result.data;
  }

  public async getMaintenanceMessage(): Promise<MaintenanceMessage> {
    const result: AxiosResponse<MaintenanceMessage> = await this.axios.get(`${baseNotificationsAPIUrl}maintenance`);
    return result.data;
  }

  public async getUserSpecificMessage(): Promise<UserSpecificMessage> {
    const result: AxiosResponse<UserSpecificMessage> = await this.axios.get(`${baseNotificationsAPIUrl}userSpecific`);
    return result.data;
  }

  public async adminSearchInventory(criteria: SiteAdminInventorySearchCriteria): Promise<InventorySearchResultSet> {
    const result: AxiosResponse<InventorySearchResultSet> = await this.axios.post(`${baseSiteAdminAPIUrl}inventory/search`, criteria);
    return result.data;
  }

  public async adminExportInventory(criteria: SiteAdminInventorySearchCriteria): Promise<InventorySearchResultSetItem[]> {
    const results = await this.adminSearchInventory({
      ...criteria,
      resultSetOptions: { pageNumber: 1, pageSize: 10_000_000, sortField: "", sortDirection: SortDirection.DEFAULT },
    });

    return results.items;
  }

  public async adminGetInventoryItem(inventoryId: number | string, accountId: number): Promise<SiteAdminInventoryItem> {
    const result: AxiosResponse<SiteAdminInventoryItem> = await this.axios.get(`${baseSiteAdminAPIUrl}${accountId}/inventory/${inventoryId}`);
    return result.data;
  }

  public async adminGetAttachment(inventoryId: number | string, attachmentId: number, accountId: number): Promise<Attachment> {
    const url = `${baseSiteAdminAPIUrl}${accountId}/inventory/${inventoryId}/attachments/${attachmentId}`;
    return this.getAttachmentResponse(url);
  }

  public async getSiteLocations(): Promise<SiteLocations> {
    const result: AxiosResponse<SiteLocations> = await this.axios.get(`${baseSiteLocationsAPIUrl}`);
    return result.data;
  }

  public async adminImportSiteLocations(request: LocationsImportRequest): Promise<SiteLocations> {
    const result: AxiosResponse<SiteLocations> = await this.axios.post(`${baseSiteAdminAPIUrl}siteLocations/import`, request);
    return result.data;
  }

  public async updateSiteLocations(locations: SiteLocations): Promise<SiteLocations> {
    const result: AxiosResponse<SiteLocations> = await this.axios.post(`${baseSiteAdminAPIUrl}siteLocations`, locations);
    return result.data;
  }

  public async deleteSiteLocation(id: number): Promise<SiteLocations> {
    const result: AxiosResponse<SiteLocations> = await this.axios.delete(`${baseSiteAdminAPIUrl}siteLocations/${id}`);
    return result.data;
  }

  public async publishSiteLocations(): Promise<SiteLocations> {
    const result: AxiosResponse<SiteLocations> = await this.axios.post(`${baseSiteAdminAPIUrl}siteLocations/publish`, { published: true });
    return result.data;
  }

  public async restartTrial(): Promise<RestartTrialResponse> {
    const result: AxiosResponse<RestartTrialResponse> = await this.axios.post(`${baseAccountAPIUrl}subscription/restartTrial`);
    return result.data;
  }

  public async getAccountServiceSubscriptionDetails(seatCount: number, billingType: number, productCode: string): Promise<SubscriptionDetails> {
    const result: AxiosResponse = await this.axios.post(`${accountServiceBaseAPIUrl}/subscriptions/new/details`, {
      billingType,
      productCode,
      seatCount,
      returnUrl: `${Settings.getBaseInventoryWebUrl()}/subscription`,
    });
    return result.data;
  }

  public async getAccountServiceSubscriptionAdditionalUsersDetails(
    seatCount: number,
    subscriptionId: string,
  ): Promise<SubscriptionAdditionalUserDetails> {
    const result: AxiosResponse = await this.axios.post(`${accountServiceBaseAPIUrl}/subscriptions/additional/details`, {
      subscriptionId,
      seatCount,
      returnUrl: `${Settings.getBaseInventoryWebUrl()}/subscription`,
    });
    return result.data;
  }

  public async getAccountServiceSubscriptionRenewalDetails(subscriptionId: string, seatCount: number): Promise<SubscriptionRenewalDetails> {
    const result: AxiosResponse = await this.axios.post(`${accountServiceBaseAPIUrl}/subscriptions/renewal/details`, {
      subscriptionId,
      returnUrl: `${Settings.getBaseInventoryWebUrl()}/subscription`,
      seatCount,
    });
    return result.data;
  }

  public async getAccountServiceSubscriptionUpgradeDetails(seatCount: number, productCode: string): Promise<SubscriptionUpgradeDetails> {
    const result: AxiosResponse = await this.axios.post(`${accountServiceBaseAPIUrl}/subscriptions/upgrade/details`, {
      productCode,
      returnUrl: `${Settings.getBaseInventoryWebUrl()}/subscription`,
      seatCount,
    });
    return result.data;
  }

  public async getAccountServiceSubscriptionProducts(): Promise<SubscriptionProducts> {
    const result: AxiosResponse = await this.axios.get(`${accountServiceBaseAPIUrl}/subscriptions/products`);
    return result.data;
  }

  public async accountServiceSuperUserAddSubscription(
    request: SubscriptionSuperUserAddSubscriptionRequestDto,
  ): Promise<SubscriptionSuperUserAddSubscriptionResponseDto> {
    const result: AxiosResponse = await this.axios.post(`${accountServiceBaseAPIUrl}/subscriptions/superuser/new`, request);
    return result.data;
  }

  public async getAccountServiceSuperUserAddSubscriptionDetails(
    request: SubscriptionSuperUserAddSubscriptionDetailsRequestDto,
  ): Promise<SubscriptionSuperUserAddSubscriptionDetailsResponseDto> {
    const result: AxiosResponse = await this.axios.post(`${accountServiceBaseAPIUrl}/subscriptions/superuser/new/details`, request);
    return result.data;
  }

  public async pingSession(): Promise<SessionPingResponseDto> {
    const result: AxiosResponse = await this.axios.post(`${accountServiceBaseAPIUrl}/sessions/current/ping`, {});
    return result.data;
  }

  public async extendSession(): Promise<void> {
    await this.axios.post(`${accountServiceBaseAPIUrl}/sessions/current/touch`, {});
  }

  public async reportItemExpiration(expirationDays: number): Promise<InventorySearchResultSetItem[]> {
    const result: AxiosResponse = await this.axios.get(`${baseReportsUrl}/expiration?days=${expirationDays}`);
    return result.data;
  }

  public async reportLowQuantity(): Promise<InventorySearchResultSetItem[]> {
    const result: AxiosResponse = await this.axios.get(`${baseReportsUrl}/lowquantity`);
    return result.data;
  }

  public async reportFreezerCapacity(): Promise<FreezerBoxExtended[]> {
    const result: AxiosResponse<FreezerBoxExtended[]> = await this.axios.get(`${baseReportsUrl}/freezercapacity`);
    return result.data;
  }

  public async reportOrders(): Promise<OrderSearchResultSetItem[]> {
    const result: AxiosResponse<OrderSearchResultSet> = await this.axios.get(`${baseReportsUrl}/orders`);
    return result.data.items;
  }

  public async getInventoryRelationshipItems(
    id: number | string,
    { childIds, parentIds }: RelationshipItemIds,
  ): Promise<InventoryItemRelationshipResponse> {
    const result: AxiosResponse<InventoryItemRelationshipResponse> = await this.axios.get<InventoryItemRelationshipResponse>(
      `${baseInventoryAPIUrl}${id}/relationships`,
      { params: { childIds: childIds, parentIds: parentIds } },
    );

    return result.data;
  }

  public async addInventoryRelationshipItems(id: number | string, { parentIds, childIds }: RelationshipItemIds): Promise<InventoryItem> {
    const { data } = await this.axios.post(`${baseInventoryAPIUrl}${id}/relationships`, { id, parentIds, childIds });
    return data;
  }

  public async deleteChildRelationship(inventoryId: number, inventoryName: string, childItem: InventoryItemIdentity): Promise<InventoryItem> {
    const { data } = await this.axios.delete(`${baseInventoryAPIUrl}${inventoryId}/relationships/children/${childItem.itemId}`);
    return data;
  }

  public async deleteParentRelationship(inventoryId: number, inventoryName: string, parentItem: InventoryItemIdentity): Promise<InventoryItem> {
    const { data } = await this.axios.delete(`${baseInventoryAPIUrl}${inventoryId}/relationships/parents/${parentItem.itemId}`);
    return data;
  }
}

import { Button } from "@labarchives/ui-design";
import { ButtonGroup, Form } from "reactstrap";
import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { AdvancedSearchCriteria } from "@labarchives/inventory-shared/build/inventory";
import { InventoryApi } from "../../api/InventoryApi";
import { InventoryFilterView, InventoryTypeView } from "../types/views";
import { InventorySearchAutoSuggest } from "./autosuggest/InventorySearchAutoSuggest";
import { InventoryAdvancedSearch } from "./advanced/InventoryAdvancedSearch";

interface InventorySearchBoxProps {
  accountName: string | undefined;
  isAdvancedSearchBoxShown: boolean;
  advancedSearchCriteria: AdvancedSearchCriteria[];
  inventoryTypeViews: InventoryTypeView[];
  api: InventoryApi;
  filters: InventoryFilterView | undefined;
  searchTerm: string;
  onAdvancedSearchToggle(): void;
  onAdvancedSearch(criteria: AdvancedSearchCriteria[]): void;
  onSearchOnNewTerm(): void;
  onSearchTermChange(term: string): void;
  onClearSearchTerm(): void;
  id?: string | undefined;
}

export function InventorySearchBox(props: InventorySearchBoxProps): ReactElement {
  const intl = useIntl();

  const [isShowingSaveAdvancedSearch, setIsShowingSaveAdvancedSearch] = useState(false);

  function onSaveSearchToggle(): void {
    setIsShowingSaveAdvancedSearch((prev) => !prev);
  }

  const {
    id,
    accountName,
    isAdvancedSearchBoxShown,
    onAdvancedSearch,
    inventoryTypeViews,
    filters,
    onAdvancedSearchToggle,
    advancedSearchCriteria,
    api,
    onSearchOnNewTerm,
    searchTerm,
    onSearchTermChange,
    onClearSearchTerm,
  } = props;
  return (
    <div style={{ position: "relative" }}>
      <div>
        <div id="inventory-search-text-container" className={`${accountName ? "with-account-name" : ""}`}>
          <InventoryAdvancedSearch
            isOpen={isAdvancedSearchBoxShown}
            onSearch={onAdvancedSearch}
            inventoryTypeViews={inventoryTypeViews}
            selectedInventoryTypes={filters ? inventoryTypeViews.filter((v) => filters.typeIds.includes(v.id)) : []}
            onClosed={onAdvancedSearchToggle}
            criteria={advancedSearchCriteria}
            onSaveSearchToggle={onSaveSearchToggle}
            isShowingSaveAdvancedSearch={isShowingSaveAdvancedSearch}
            api={api}
          />
          {advancedSearchCriteria.length > 0 && (
            <div style={{ textAlign: "right", flexGrow: 1 }}>
              <div>
                <span>
                  <strong>{advancedSearchCriteria.length}</strong>&nbsp;advanced search filters applied -
                </span>
                <Button
                  className={"ms-1"}
                  color={"link"}
                  onClick={onAdvancedSearchToggle}
                  aria-label={intl.formatMessage({ id: "change.advanced.search.options" })}
                >
                  Change
                </Button>
                &nbsp;|
                <Button className={"ms-1"} color={"link"} onClick={() => onAdvancedSearch([])}>
                  Clear
                </Button>
              </div>
              <div>
                <Button className={"ms-1"} color={"link"} onClick={() => onSaveSearchToggle()}>
                  Save this search
                </Button>
              </div>
            </div>
          )}
          {advancedSearchCriteria.length <= 0 && (
            <Form
              onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault();
                e.stopPropagation();
                onSearchOnNewTerm();
              }}
            >
              <InventorySearchAutoSuggest
                searchTerm={searchTerm}
                onSearchTermChange={onSearchTermChange}
                onClear={onClearSearchTerm}
                api={api}
                id={id}
              />
              <ButtonGroup>
                <Button type="submit" color="primary" id="inventory-search-submit">
                  <FormattedMessage id="search" />
                </Button>
                <Button
                  color="primary"
                  onClick={onAdvancedSearchToggle}
                  id={"inventory-advanced-search-toggle"}
                  aria-label={intl.formatMessage({ id: "open.advanced.search.options.dialog" })}
                >
                  <FontAwesomeIcon icon={faChevronDown} />
                </Button>
              </ButtonGroup>
            </Form>
          )}
        </div>
      </div>
    </div>
  );
}
